import { Component, ViewChild, OnInit, inject, ChangeDetectorRef, OnDestroy, ElementRef, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DocumentService } from '../service/documents/document.service';
import { IDocument, Document, documentTabbedViewEnum } from '../models/documents/document';
import { FormControl, FormGroup } from '@angular/forms';
import { CommunicationService } from '../service/communication/communication.service';
import { FilterData } from '../models/list-model';
import { ColumnSetup } from '../models/column-setup'
import { FilterEnum } from '../models/filter-enum';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { DataFilter, FilterTransferModel } from '../models/filter-models';
import { Observable, Subject, of } from 'rxjs';
import { takeUntil, catchError, skip, map, startWith } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponent } from '../app.component';
import { AlertSubscriptionModel } from '../models/alerts/alert-subscription-model';
import { MatDialog } from '@angular/material/dialog';
import { DocumentRejectComponent } from '../dialog/document-reject/document-reject.component';
import { ShipmentTranferGRRefContainerNo } from '../models/shipment/shipment-data-model';
import { LookupService } from '../service/lookup/lookup.service';
import { LookupModel } from '../models/lookup-model';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { TableUtil } from '../service/utils/excel-export-service.service';
import { Table } from 'primeng/table';
import { TableConfigModel } from '../models/table-config';
import { TableConfigEnum } from '../models/table-config-enum';
import { MessageService } from 'primeng/api';
import { CommonService } from '../service/common/common.service';
import FileSaver from 'file-saver';
import { GlobalPanelService } from '../service/panel/global-panel-service.service';

interface Column {
  name: string;
  field: string;
  header: string;
  type: string;
  visible: boolean;
  toolTip?: string;
  customExportHeader?: string;
}
interface ExportColumn {
  title: string;
  dataKey: string;
}

@Component({
  selector: 'app-approved-documents',
  templateUrl: './document-approved-documents.component.html',
  styleUrls: ['./document-approved-documents.component.css']
})
export class DocumentApprovedDocumentsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator = {} as MatPaginator;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.currentDataSource.sort = sort;
  }
  @ViewChild('customContent') customContent!: TemplateRef<any>;
  @ViewChild('dt', { static: true }) table!: ElementRef;
  @ViewChild('dt') dt!: Table;

  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  private _snackBar = inject(MatSnackBar);
  protected documentTabbedViewEnum = documentTabbedViewEnum;
  protected selectedTab = documentTabbedViewEnum.pending;
  protected filterList = new FormControl();
  protected filterListNotApproved = new FormControl();
  loading: boolean = true;
  mobileTableConfig: boolean = false;
  searchValue: string | undefined;
  selectedBookings: Column[] = [];
  bookingColumns: Column[] = [];
  panelCollapsed: boolean = true;
  bookings!: any[];
  cols: Column[] = [];
  exportColumns!: ExportColumn[];
  formGroup: FormGroup | undefined;
  currentRowIndex = 0;
  isSingleCustUser: boolean = false;
  isSingleConsigneeUser: boolean = false;
  isConsigneeUser: boolean = false;
  approvedDataSource = new MatTableDataSource<Document>();
  dateFrom = new FormControl();
  dateTo = new FormControl();
  savedFilters: DataFilter[] = [];
  filterData: FilterData = {};
  filterId: number = 0;
  startDate?: Date = new Date;
  endDate?: Date = new Date;
  origNotApprovedDatasource = new MatTableDataSource<Document>();
  notApprovedDataSource = new MatTableDataSource<Document>();
  dateFromNotApproved = new FormControl();
  dateToNotApproved = new FormControl();
  savedFiltersNotApproved: DataFilter[] = [];
  filterDataNotApproved: FilterData = {};
  currentDataSource = this.notApprovedDataSource;
  filter!: FilterData;
  FilterEnum = FilterEnum;
  selectedButton = "";
  detailView = detailView;
  detailTabbedViewEnum = detailTabbedViewEnum;
  displayedColumns: ColumnSetup[] = [];
  extractedColumns: string[] = [];
  documentList: IDocument[] = [];
  templates: AlertSubscriptionModel[] = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  isLoading = true;
  isLoadingNotApproved = true;
  loadingSearch: boolean = false;
  loadingSearchNotApproved: boolean = false;
  datafilter: DataFilter = new DataFilter();
  datafilterNotApproved: DataFilter = new DataFilter();
  customers = new FormControl<LookupModel[]>([]);
  customersNotApproved = new FormControl<LookupModel[]>([]);
  grRefNo = new FormControl('');
  grRefNoNotApproved = new FormControl('');
  clientRefNo = new FormControl('');
  clientRefNoNotApproved = new FormControl('');
  bookingRefNo = new FormControl('');
  documentTypeNotApproved = new FormControl<LookupModel[]>([]);
  consignees = new FormControl<LookupModel[]>([]);
  consigneesNotApproved = new FormControl<LookupModel[]>([]);
  customersData: LookupModel[] = [];
  customersDataNotApproved: LookupModel[] = [];
  documentTypesData: LookupModel[] = [];
  documentDataConsignee: LookupModel[] = [];
  documentDataConsigneeNotApproved: LookupModel[] = [];
  customersMultiFilter = new FormControl<string>('');
  customersMultiFilterNotApproved = new FormControl<string>('');
  documentTypesMultiFilter = new FormControl<string>('');
  consigneesMultiFilter = new FormControl<string>('');
  consigneesMultiFilterNotApproved = new FormControl<string>('');
  filteredCustomers!: Observable<LookupModel[]>;
  filteredCustomersNotApproved!: Observable<LookupModel[]>;
  filteredDocumentTypes!: Observable<LookupModel[]>;
  filteredConsignees!: Observable<LookupModel[]>;
  filteredConsigneesNotApproved!: Observable<LookupModel[]>;
  savedFilterName = new FormControl('');
  currentFilterStatus: string | null = null;


  constructor(
    private documentService: DocumentService,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appComponent: AppComponent,
    private cdr: ChangeDetectorRef,
    private lookupService: LookupService,
    private commonService: CommonService,
    private messageService: MessageService,
    private panelService: GlobalPanelService
  ) {
    this.matIconRegistry.addSvgIcon(
      'Subscribe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Subscribe.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewDocuments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewDocuments.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewAlerts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewAlerts.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'backspace',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'export',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Export-Icon.svg')
    )

  }

   ngOnInit(): void {
    this.loading = true;
    this.getTableConfig()
    this.formGroup = new FormGroup({
      text: new FormControl<string | null>(null)
    });

    this.selectedBookings = this.cols;

    this.bookingColumns = this.cols.filter(
      (col) => col.field !== 'actions'
    );

    this.exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isSingleCustUser = this.apiUserService.IsSingleCustUser;
            this.isSingleConsigneeUser = this.apiUserService.IsSingleConsignee;
            this.isConsigneeUser = this.apiUserService.IsConsigneeUser;
          }
      });

    this.isSingleCustUser = this.apiUserService.IsSingleCustUser;
    this.isSingleConsigneeUser = this.apiUserService.IsSingleConsignee;
    this.isConsigneeUser = this.apiUserService.IsConsigneeUser;

    this.documentService.sendFilterTransfer$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (t) => {

          this.filter = t.filter;
          this.filterId = t.filterId;
          this.startDate = t.startDate;
          this.endDate = t.endDate;
          this.updateDataApproved(this.filter);

        },
        error: (error) => {
          console.error('Send FilterModel Error', error);
          window.alert('Send FilterModel Error. Please try again later.');
        }
      }); 
  }

  ngAfterViewInit() {
    const myElements = document.getElementsByClassName('p-datatable-wrapper');
    if (myElements.length > 0) {
      for (let i = 0; i < myElements.length; i++) {
        const element = myElements[i] as HTMLElement;
        element.addEventListener('scroll', (event: Event) => {
          const rows = element.querySelectorAll('.snap-row');
          if (rows.length > 0) {
            const observerOptions: IntersectionObserverInit = {
              root: element, 
              threshold: 0.1,
            };
            const observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  const index = Array.from(rows).findIndex(
                    (row) => row === entry.target
                  );
                  if (index !== -1) {
                    this.currentRowIndex = index
                  }
                }
              });
            }, observerOptions);
            rows.forEach((row) => observer.observe(row));
          }
        });
      }
    } else {
      console.error('No elements with class "p-datatable-wrapper" found!');
    }
    this.scrollToTop();
  }

  configureColumns(): void {
      this.displayedColumns = [
        { name: 'documentStatusColor', header: '', type: 'string', visible: true },
        { name: 'customer', header: 'Customer', type: 'string', visible: !this.isSingleCustUser, toolTip: 'The name of the customer.' },
        { name: 'grReference', header: 'GR Ref No', type: 'string', visible: true, toolTip: 'GoReefers reference number assigned to the booking.' },
        { name: 'clientReference', header: 'Client Reference', type: 'string', visible: true, toolTip: 'The client’s reference number.' },
        { name: 'destinationDescription', header: 'Discharge Port', type: 'string', visible: true, toolTip: 'Port where the vessel discharges some or all of its cargo.' },
        { name: 'vesselName', header: 'Vessel Name', type: 'string', visible: true, toolTip: 'Name of the vessel.' },
        { name: 'eta', header: 'ETA', type: 'date', visible: true, toolTip: 'Estimated time of arrival.' },
        { name: 'consignee', header: 'Consignee', type: 'string', visible: true, toolTip: 'Name of the shipment receiver or importer.' },
        { name: 'bookingReference', header: 'Booking Reference', type: 'string', visible: true, toolTip: 'Shipping line booking reference number.' },
        { name: 'actions', header: 'Action', type: 'string', visible: true }
    ];
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  protected updateDataApproved(filterData?: FilterData): void {
    this.loading = true;
    this.documentService.getApprovedDocumentList(this.datafilter.id, filterData, this.startDate, this.endDate).subscribe(data => {
        this.configureColumns();
        this.approvedDataSource.data = data;
        this.approvedDataSource.paginator = this.paginator;
        this.loading = false;
    });
    this.scrollToTop();
  }


  protected viewDetails(row: any, view: detailView, tab: detailTabbedViewEnum): void {
    this.communicationService.toggleDetailView(view, tab);
    let info: ShipmentTranferGRRefContainerNo = new ShipmentTranferGRRefContainerNo;
    info.grReferenceNo = row.grReference;
    info.containerNo = null;
    this.communicationService.getDetailViewGRRefcontainerNo(info)
  }

  protected showLoading(show: boolean): void {
    this.appComponent.showLoading(show);
  }

  public openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  protected isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  protected getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  protected getBackgroundColor(cellValue: string): string {
    switch (cellValue) {
      case 'Approved':
        return '#36DE6F';
      case 'Awaiting Approval':
        return '#FFD34F';
      default:
        return '#DC6868';
    }
  }

  protected showInformation(arg0: string, error: any) {
    throw new Error('Method not implemented.');
  }

  protected rejectDocument(row: any): void {
    const dialogRef = this.dialog.open(DocumentRejectComponent, {
      width: '300px',
      height: '350px',
      data: { documentApprovalStatus: 'Rejected', reasonCode: '', reasonDetail: '' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const rejectionData = {
          documentApprovalStatus: 'Rejected',
          documentReasonCode: result.reasonCode,
          documentReasonDetail: result.reasonDetail,
        };
        this.documentService.updateDocumentStatus(row['id'], JSON.stringify(rejectionData))
        .pipe(
          catchError(error => {
            console.error('Error updating document status:', error);
            this.showInformation('Document rejection Failed', error);
            return of(null);
          })
        )
        .subscribe(response => {
          if (response) {
            this.openSnackBar('Document Status updated to Rejected')
            this.updateDataApproved();
            this.cdr.detectChanges();
          }
        });
      }
    });
    this.documentService.updateDocumentList(this.documentList);
  }

  public viewDocument(row: any): void {
    this.documentService.downloadDocumentWithSystemId(row['documentAttachmentSystemId'])
    .pipe(
      catchError(error => {
        console.error('Error updating document status:', error);
        this.showInformation('Download Failed', error);
        return of(null);
      })
    )
    .subscribe(response => {
      if (response) {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const fileName = `${row['attachmentFilename']}.pdf`;
        this.openSnackBar('Openning ' + fileName);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(url);
      }
    });
  }

  protected downloadDocument(row: any): void {
    this.documentService.downloadDocumentWithSystemId(row['documentAttachmentSystemId'])
    .pipe(
      catchError(error => {
        console.error('Error updating document status:', error);
        this.showInformation('Download Failed', error);
        return of(null);
      })
    )
    .subscribe(response => {
      if (response) {
        const fileName = `${row['attachmentFilename']}.pdf`;
        this.openSnackBar('Downloading ' + fileName);
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank';
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(url);
      }
    });
  }

  filterByApprovalStatus(status: string): void {
    if (this.currentFilterStatus === status) {
      this.currentDataSource.filter = this.notApprovedDataSource.filter = '';
      this.currentFilterStatus = null;
    } else {
      this.currentDataSource.filter = this.notApprovedDataSource.filter = status;
      this.currentFilterStatus = status;
    }
    this.cdr.detectChanges();
  }

  resetTextControl(control: FormControl) {
    control.reset();
  }

  exportTable() {
    TableUtil.exportToExcel(this.currentDataSource.data, this.displayedColumns, this.selectedTab == documentTabbedViewEnum.approved ? 'Approved Documents' : this.selectedTab == documentTabbedViewEnum.pending ? 'Pending Documents' : 'Documents');
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
      const visibleColumns = this.selectedBookings
        .filter(col => col.visible)
        .map(col => ({ title: this.toPascalCase(col.header), dataKey: col.field }));
      const visibleRows = this.approvedDataSource.filteredData.map(row => {
        const filteredRow: Record<string, any> = {};
        visibleColumns.forEach(col => {
          filteredRow[col.title] = row[col.dataKey as keyof Document];
        });
        return filteredRow;
      });

      const worksheet = xlsx.utils.json_to_sheet(visibleRows);
      const range = xlsx.utils.decode_range(worksheet['!ref']!);
      const headerRow = range.s.r;
      for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
        const cellAddress = xlsx.utils.encode_cell({ r: headerRow, c: colIndex });
        const cell = worksheet[cellAddress];
        if (cell) {
          cell.s = {
            font: { bold: true, color: { rgb: 'FFFFFF' } },
            fill: { fgColor: { rgb: '4F81BD' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          };
        }
      }

      worksheet['!cols'] = visibleColumns.map(col => ({
        wch: Math.max(
          col.title.length,
          ...visibleRows.map(row => (row[col.title] ? row[col.title].toString().length : 0))
        ),
      }));

      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array', cellStyles: true });
      this.saveAsExcelFile(excelBuffer, "approved_documents");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  toPascalCase(text: string): string {
    return text
    .replace(/(\w)(\w*)/g, (_, firstChar, rest) =>
      firstChar.toUpperCase() + rest.toLowerCase()
    )
    .replace(/\s+/g, '');
  }

  saveTableConfig(event: any): void {
    const tableConfig: TableConfigModel = {
      table: TableConfigEnum.DocumentsApprovedTable,
      columnOrder: JSON.stringify(this.selectedBookings),
      columns: JSON.stringify(this.selectedBookings)
    };

    this.commonService.saveTableConfigFilter(tableConfig).subscribe({
      next: (response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Table configuration saved successfully!',
          life: 3000,
        });
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to save table configuration. Please try again.',
          life: 3000,
        });
      }
    });
    this.handleButtonClick()
    this.closePanel()
  }

  getTableConfig() {
    const tableConfig: TableConfigModel = {
      table: TableConfigEnum.DocumentsApprovedTable,
      columnOrder: JSON.stringify(this.selectedBookings),
      columns: JSON.stringify(this.selectedBookings),
    };

    this.commonService.getUserTableConfig(tableConfig).subscribe({
      next: (response) => {
        if (response?.columns && response?.columnOrder) {
          try {
            const parsedColumns = JSON.parse(response.columns) as Column[];
            const parsedColumnOrder = JSON.parse(response.columnOrder) as Array<{ field: string }>;
            if (parsedColumns.length && parsedColumnOrder.length) {
              const orderedFields = parsedColumnOrder.map((order) => order.field);
              const orderedColumns = orderedFields
                .map((field) => {
                  const column = parsedColumns.find((col) => col.field === field);
                  if (!column) {
                    console.warn(`Field "${field}" not found in parsedColumns.`);
                  }
                  return column;
                })
                .filter((col): col is Column => col !== undefined);
              if (orderedColumns.length) {
                this.cols = [...orderedColumns];
                this.selectedBookings = [...this.cols];
                this.bookingColumns = [
                  { name: 'customer', field: 'customer', header: this.isConsigneeUser ? 'Shipper' : 'Customer', type: 'string', visible: !this.isSingleCustUser, toolTip: 'The name of the customer.' },
                  { name: 'grReference', field: 'grReference', header: 'GR Ref No', type: 'string', visible: true, toolTip: 'GoReefers reference number assigned to the booking.' },
                  { name: 'clientReference', field: 'clientReference', header: this.isConsigneeUser ? 'Shipper Reference' : 'Client Reference', type: 'string', visible: true, toolTip: 'The client’s reference number.' },
                  { name: 'destinationDescription', field: 'destinationDescription', header: 'Discharge Port', type: 'string', visible: true, toolTip: 'Port where the vessel discharges some or all of its cargo.' },
                  { name: 'vesselName', field: 'vesselName', header: 'Vessel Name', type: 'string', visible: true, toolTip: 'Name of the vessel.' },
                  { name: 'eta', field: 'eta', header: 'ETA', type: 'date', visible: true, toolTip: 'Estimated time of arrival.' },
                  { name: 'consignee', field: 'consignee', header: 'Consignee', type: 'string', visible: !this.isSingleConsigneeUser, toolTip: 'Name of the shipment receiver or importer.' },
                  { name: 'bookingReference', field: 'bookingReference', header: 'Booking Reference', type: 'string', visible: true, toolTip: 'Shipping line booking reference number.' },
                ];
                this.exportColumns = this.cols.map((col) => ({
                  title: col.header,
                  dataKey: col.field,
                }));
                return;
              }
            }
          } catch (error) {
            console.error('Error parsing columns or columnOrder:', error);
          }
        }
        this.useDefaultColumns('Invalid or empty configuration. Using default columns.');
      },
      error: (error) => {
        this.useDefaultColumns('Failed to load table configuration. Using default columns.');
      },
    });
  }

  useDefaultColumns(message: string) {
    console.warn(message);
    const defaultColumns = [
      { name: 'customer', field: 'customer', header: this.isConsigneeUser ? 'Shipper' : 'Customer', type: 'string', visible: !this.isSingleCustUser, toolTip: 'The name of the customer.' },
      { name: 'grReference', field: 'grReference', header: 'GR Ref No', type: 'string', visible: true, toolTip: 'GoReefers reference number assigned to the booking.' },
      { name: 'clientReference', field: 'clientReference', header: this.isConsigneeUser ? 'Shipper Reference' : 'Client Reference', type: 'string', visible: true, toolTip: 'The client’s reference number.' },
      { name: 'destinationDescription', field: 'destinationDescription', header: 'Discharge Port', type: 'string', visible: true, toolTip: 'Port where the vessel discharges some or all of its cargo.' },
      { name: 'vesselName', field: 'vesselName', header: 'Vessel Name', type: 'string', visible: true, toolTip: 'Name of the vessel.' },
      { name: 'eta', field: 'eta', header: 'ETA', type: 'date', visible: true, toolTip: 'Estimated time of arrival.' },
      { name: 'consignee', field: 'consignee', header: 'Consignee', type: 'string', visible: !this.isSingleConsigneeUser, toolTip: 'Name of the shipment receiver or importer.' },
      { name: 'bookingReference', field: 'bookingReference', header: 'Booking Reference', type: 'string', visible: true, toolTip: 'Shipping line booking reference number.' },
    ];
    this.cols = [...defaultColumns];
    this.selectedBookings = [...this.cols];
    this.bookingColumns = [...defaultColumns];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  rowClass(booking: any) {
    return { 'bground-primary': booking === 'Undefined' };
  }

  rowStyle(booking: any) {
    if (booking.quantity === 0) {
      return { fontWeight: 'bold', fontStyle: 'italic' };
    } else {
      return {};
    }
  }

  getSeverity(status: string): string {
    switch (status) {
      case 'Approved':
        return '#36DE6F';
      case 'Awaiting Approval':
        return '#FFD34F';
      default:
        return '#DC6868';
    }
  }

  updateDisplayedColumns() {
    this.cols = [...this.selectedBookings];
  }

  private scrollToTop() {
    if (this.dt) {
      this.dt.scrollTo({ top: 0 });
    }
  }

  handleButtonClick(): void {
    if (this.isMobile()) {
      this.openPanel()
    } else {
      this.togglePanel();
    }
  }

  togglePanel() {
    this.panelCollapsed = !this.panelCollapsed;
  }

  isMobile(): boolean {
    return window.innerWidth <= 480;
  }

  isTablet(): boolean {
    return window.innerWidth <= 768;
  }

  clear(table: Table) {
    table.clear();
    this.searchValue = ''
  }

  openPanel(): void {
    this.panelService.openPanel('Select Table Columns', this.customContent);
  }

  closePanel(): void {
    this.panelService.closePanel()
  }

  updateCurrentRow(index: number): void {
    this.currentRowIndex = index + 1;
  }

  onPageChange(event: any): void {
    this.currentRowIndex = event.first;
  }

  updateCurrentIndex(container: Element, rows: NodeListOf<Element>): void {
    let closestIndex = 0;
    let closestDistance = Number.MAX_VALUE;
    rows.forEach((row, index) => {
      const rect = row.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      const distance = Math.abs(rect.top - containerRect.top);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestIndex = index;
      }
    });
    this.currentRowIndex = closestIndex;
  }
  onScroll(event: Event): void {
    const tableWrapper = event.target as HTMLElement;
    const rows = Array.from(tableWrapper.querySelectorAll('.snap-row')) as HTMLElement[];
    let currentRowIndex = 0;
    let closestDistance = Number.MAX_VALUE;
    rows.forEach((row, index) => {
      const rect = row.getBoundingClientRect();
      const distance = Math.abs(rect.top - tableWrapper.getBoundingClientRect().top);
      if (distance < closestDistance) {
        closestDistance = distance;
        currentRowIndex = index;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

