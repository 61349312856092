import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { CompactType, DisplayGrid, GridType, GridsterConfig, GridsterItem } from 'angular-gridster2';
import { UserActivityService } from '../service/user-activity/user-activity.service';
import { IUserInfoModel, UserInfoModel } from '../models/user-info-model';
import { Subscription } from 'rxjs';
import { ApiUserService } from '../service/user/api-user.service';
import { AnalyticsService } from '../service/analytics/analytics.service'

interface City {
  name: string,
  code: string
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {

  cities!: City[];

  selectedCities!: City[];

  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit() {
    this.cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' }
    ];
    this.analyticsService.trackEvent('dashboard', 'visited dashboard','visits');
  }
}
