import { OnInit, Component, ViewChild, Output, EventEmitter, OnDestroy, inject, Input, OnChanges, SimpleChanges, TemplateRef, ElementRef } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { BookingService } from '../service/booking/booking.service';
import { FilterData } from '../models/list-model';
import { CommunicationService } from '../service/communication/communication.service';
import { ColumnSetup } from '../models/column-setup';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponent } from '../app.component';
import { BookingListModel } from '../models/bookings/booking-list-model';
import { RollingPlan } from '../models/bookings/rolling-plan-model';
import { ShipmentService } from '../service/shipment/shipment.service';
import { FormControl, FormGroup } from '@angular/forms';
import { TableUtil } from '../service/utils/excel-export-service.service';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonService } from '../service/common/common.service';
import { TableConfigModel } from '../models/table-config';
import { TableConfigEnum } from '../models/table-config-enum';
import FileSaver from 'file-saver';
import { GlobalPanelService } from '../service/panel/global-panel-service.service';

interface Column {
  name: string;
  field: string;
  header: string;
  type: string;
  visible: boolean;
  toolTip?: string;
  customExportHeader?: string;
}
interface ExportColumn {
  title: string;
  dataKey: string;
}

@Component({
  selector: 'app-booking-rolling-plan',
  templateUrl: './booking-rolling-plan.component.html',
  styleUrls: ['./booking-rolling-plan.component.css']
})
export class BookingRollingPlanComponent implements OnInit, OnDestroy {
  @ViewChild('customContent') customContent!: TemplateRef<any>;
  @ViewChild('dt', { static: true }) table!: ElementRef;
  @ViewChild('dt') dt!: Table;
  @Input() selectedCustomer: string = '';
  @Output() openDetailDrawer = new EventEmitter<string>();
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.rollingPlanDataSource.sort = sort;
  }

  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  protected filterList = new FormControl();
  loading: boolean = true;
  mobileTableConfig: boolean = false;
  searchValue: string | undefined;
  selectedBookings: Column[] = [];
  bookingColumns: Column[] = [];
  panelCollapsed: boolean = true;
  bookings!: any[];
  cols: Column[] = [];
  exportColumns!: ExportColumn[];
  filterData!: FilterData;
  formGroup: FormGroup | undefined;
  currentRowIndex = 0;
  isSingleCustUser: boolean = false;
  bookingData: BookingListModel[] = [];
  filter!: FilterData;
  detailTabbedViewEnum = detailTabbedViewEnum;
  detailView = detailView;
  filterId: number = 0;
  startDate?: Date = new Date;
  endDate?: Date = new Date;
  displayedColumns: ColumnSetup[] = [];
  extractedColumns: string[] = [];
  rollingPlanDataSource = new MatTableDataSource<RollingPlan>();
  _snackBar: any;
  horizontalPosition: any;
  verticalPosition: any;
  isLoading = false;
  loadingSearch: boolean = false;

  constructor(
    private bookingDataService: BookingService,
    private shipingDataService: ShipmentService,
    private communicationService: CommunicationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appComponent: AppComponent,
    private commonService: CommonService,
    private messageService: MessageService,
    private panelService: GlobalPanelService
  ) {
    this.matIconRegistry.addSvgIcon(
      'Subscribe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Subscribe.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewDocuments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewDocuments.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewAlerts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewAlerts.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'backspace',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
  }

  ngOnInit(): void {
    this.loading = true;
    this.getTableConfig()
    this.formGroup = new FormGroup({
      text: new FormControl<string | null>(null)
    });

    this.selectedBookings = this.cols;
    this.bookingColumns = this.cols.filter(
      (col) => col.field !== 'actions'
    );

    this.exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));

    setTimeout(() => {
      this.bookingDataService.getBookings(this.filterId, this.filterData, this.startDate, this.endDate).subscribe((data) => {
        this.bookings = data;
        this.loading = false;
      });
    }, 1000);

    this.configureColumns();
    this.apiUserService.userInfo
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next:
        (_) => {
          this.isSingleCustUser = this.apiUserService.IsSingleCustUser;
        }
    });

    this.isSingleCustUser = this.apiUserService.IsSingleCustUser;

    this.shipingDataService.sendFilterRoadTransfer$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (t) => {
        this.loading = true;
        this.filter = t.filter;
        this.filterId = t.filterId;
        this.startDate = t.startDate;
        this.endDate = t.endDate;
        this.updateData(this.filterId, this.filter, this.startDate, this.endDate);
      },
      error: (error) => {
        console.error('Send FilterModel Error:', error);
        window.alert('Send FilterModel Error. Please try again later.');
      }
    });
  }

  ngAfterViewInit() {
    this.scrollToTop();
    const myElements = document.getElementsByClassName('p-datatable-wrapper');
    if (myElements.length > 0) {
      for (let i = 0; i < myElements.length; i++) {
        const element = myElements[i] as HTMLElement;
        element.addEventListener('scroll', (event: Event) => {
          const rows = element.querySelectorAll('.snap-row');
          if (rows.length > 0) {
            const observerOptions: IntersectionObserverInit = {
              root: element,
              threshold: 0.1,
            };
            const observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  const index = Array.from(rows).findIndex(
                    (row) => row === entry.target
                  );
                  if (index !== -1) {
                    this.currentRowIndex = index
                  }
                }
              });
            }, observerOptions);
            rows.forEach((row) => observer.observe(row));
          }
        });
      }
    } else {
      console.error('No elements with class "p-datatable-wrapper" found!');
    }
  }

  configureColumns(): void {
    this.displayedColumns = [
      { name: 'shipperNo', header: 'Customer', type: 'string', visible: !this.isSingleCustUser, toolTip: 'Name of the customer.' },
      { name: 'grReferenceNo', header: 'GR Reference No', type: 'string', visible: true, toolTip: 'GoReefers reference number for the booking.' },
      { name: 'clientReference', header: 'Client Reference', type: 'string', visible: true, toolTip: 'Client’s reference number for the booking.' },
      { name: 'confirmedPackDate', header: 'Confirmed Pack Date', type: 'date', visible: true, toolTip: 'Date the packing was confirmed.' },
      { name: 'loadingPointDescription', header: 'Loading Point', type: 'string', visible: true, toolTip: 'Description of the loading location.' },
      { name: 'containerStatus', header: 'Container Status', type: 'string', visible: true, toolTip: 'Description of the container status.' },
      { name: 'loadingPortName', header: 'Loading Port', type: 'string', visible: true, toolTip: 'Port where container will be loaded onto vessel.' },
      { name: 'finalDestinationName', header: 'Discharge Port', type: 'string', visible: true, toolTip: 'Port where the vessel discharges some or all of its cargo.' },
      { name: 'bookingReference', header: 'Booking Reference', type: 'string', visible: true, toolTip: 'Booking reference number.' },
      { name: 'vesselName', header: 'Vessel Name', type: 'string', visible: true, toolTip: 'Name of the vessel.' },
      { name: 'voyageNumber', header: 'Voyage Number', type: 'string', visible: true, toolTip: 'Number assigned to voyage.' },
      { name: 'startStackDate', header: 'Start Stack Date', type: 'date', visible: true, toolTip: 'Date and time that stacks open.' },
      { name: 'endStackDate', header: 'End Stack Date', type: 'date', visible: true, toolTip: 'Date and time that stacks close.' },
      { name: 'actions', header: 'Actions', type: 'string', visible: true, toolTip: 'Show more details and load tracking status.' }
      //{ name: 'quantity', header: 'Quantity', type: 'number', visible: true },
    ];
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  showLoading(show: boolean): void {
    this.appComponent.showLoading(show);
  }

  getBackgroundColor(cellValue: string): string {
    switch (cellValue) {
      case 'Cancelled':
        return '#DC6868';
      case 'Confirmed':
        return '#36DE6F';
      case 'Awaiting Approval':
        return '#FFD34F';
      default:
        return '#C2C2C2';
    }
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  updateData(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): void {
    this.scrollToTop();
    this.loading = true;
    this.bookingDataService.getRollingPlans(filterId, filterData, startDate, endDate)
    .subscribe(data => {
      this.configureColumns();
      this.rollingPlanDataSource.data = data;
      this.loading = false;
    });
  }

  viewDetails(row: any, detailViewToOpen: detailView, tab: detailTabbedViewEnum): void {
    var bookingParameter = row['bookingHeaderCode'];
    this.communicationService.toggleDetailView(detailViewToOpen, tab);
    this.communicationService.getDetailViewParameter(bookingParameter);
    this.communicationService.getRollingPlanLineNo(row['lineNumber']);
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  applyFilter(filterValue: string) {
    if (!filterValue) {
      this.rollingPlanDataSource.filter = '';
    } else {
      this.rollingPlanDataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearSearchField(control: FormControl): void {
    control.reset();
    this.rollingPlanDataSource.filter = '';
  }

  applySearch(filterValue: string | undefined) {
    const normalizedFilter = (filterValue || '').trim().toLowerCase();
    this.loadingSearch = true;
    setTimeout(() => {
      this.rollingPlanDataSource.filter = normalizedFilter;
      this.loadingSearch = false;
    }, 200);
  }

  exportTable() {
    TableUtil.exportToExcel(this.rollingPlanDataSource.data, this.displayedColumns, 'Shipments');
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
      const visibleColumns = this.selectedBookings
        .filter(col => col.visible)
        .map(col => ({ title: this.toPascalCase(col.header), dataKey: col.field }));

      const visibleRows = this.rollingPlanDataSource.filteredData.map(row => {
        const filteredRow: Record<string, any> = {};
        visibleColumns.forEach(col => {
          const key = col.dataKey as keyof RollingPlan;
          filteredRow[col.title] = row[key];
        });
        return filteredRow;
      });
      const worksheet = xlsx.utils.json_to_sheet(visibleRows);
      const range = xlsx.utils.decode_range(worksheet['!ref']!);
      const headerRow = range.s.r;
      for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
        const cellAddress = xlsx.utils.encode_cell({ r: headerRow, c: colIndex });
        const cell = worksheet[cellAddress];
        if (cell) {
          cell.s = {
            font: { bold: true, color: { rgb: 'FFFFFF' } },
            fill: { fgColor: { rgb: '4F81BD' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          };
        }
      }

      worksheet['!cols'] = visibleColumns.map(col => ({
        wch: Math.max(
          col.title.length,
          ...visibleRows.map(row => (row[col.title] ? row[col.title].toString().length : 0))
        ),
      }));
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array', cellStyles: true });
      this.saveAsExcelFile(excelBuffer, "rolling_plan");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  toPascalCase(text: string): string {
    return text
    .replace(/(\w)(\w*)/g, (_, firstChar, rest) =>
      firstChar.toUpperCase() + rest.toLowerCase()
    )
    .replace(/\s+/g, '');
  }

  saveTableConfig(event: any): void {
    const tableConfig: TableConfigModel = {
      table: TableConfigEnum.ShipmentsRollingPlanTable,
      columnOrder: JSON.stringify(this.selectedBookings),
      columns: JSON.stringify(this.selectedBookings)
    };

    this.commonService.saveTableConfigFilter(tableConfig).subscribe({
      next: (response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Table configuration saved successfully!',
          life: 3000,
        });
        this.handleButtonClick()
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to save table configuration. Please try again.',
          life: 3000,
        });
      }
    });
    this.closePanel()
  }

  getTableConfig() {
    const tableConfig: TableConfigModel = {
      table: TableConfigEnum.ShipmentsRollingPlanTable,
      columnOrder: JSON.stringify(this.selectedBookings),
      columns: JSON.stringify(this.selectedBookings),
    };

    this.commonService.getUserTableConfig(tableConfig).subscribe({
      next: (response) => {
        if (response?.columns && response?.columnOrder) {
          try {
            const parsedColumns = JSON.parse(response.columns) as Column[];
            const parsedColumnOrder = JSON.parse(response.columnOrder) as Array<{ field: string }>;
            if (parsedColumns.length && parsedColumnOrder.length) {
              const orderedFields = parsedColumnOrder.map((order) => order.field);
              const orderedColumns = orderedFields
                .map((field) => {
                  const column = parsedColumns.find((col) => col.field === field);
                  if (!column) {
                    console.warn(`Field "${field}" not found in parsedColumns.`);
                  }
                  return column;
                })
                .filter((col): col is Column => col !== undefined);

              if (orderedColumns.length) {
                this.cols = [...orderedColumns];
                this.selectedBookings = [...this.cols];
                this.bookingColumns = [
                  { name: 'shipperNo', field: 'shipperNo', header: 'Customer', type: 'string', visible: !this.isSingleCustUser, toolTip: 'Name of the customer.' },
                  { name: 'grReferenceNo', field: 'grReferenceNo', header: 'GR Reference No', type: 'string', visible: true, toolTip: 'GoReefers reference number for the booking.' },
                  { name: 'clientReference', field: 'clientReference', header: 'Client Reference', type: 'string', visible: true, toolTip: 'Client’s reference number for the booking.' },
                  { name: 'confirmedPackDate', field: 'confirmedPackDate', header: 'Confirmed Pack Date', type: 'date', visible: true, toolTip: 'Date the packing was confirmed.' },
                  { name: 'loadingPointDescription', field: 'loadingPointDescription', header: 'Loading Point', type: 'string', visible: true, toolTip: 'Description of the loading location.' },
                  { name: 'containerStatus', field: 'containerStatus', header: 'Container Status', type: 'string', visible: true, toolTip: 'Description of the container status.' },
                  { name: 'loadingPortName', field: 'loadingPortName', header: 'Loading Port', type: 'string', visible: true, toolTip: 'Port where container will be loaded onto vessel.' },
                  { name: 'finalDestinationName', field: 'finalDestinationName', header: 'Discharge Port', type: 'string', visible: true, toolTip: 'Port where the vessel discharges some or all of its cargo.' },
                  { name: 'bookingReference', field: 'bookingReference', header: 'Booking Reference', type: 'string', visible: true, toolTip: 'Booking reference number.' },
                  { name: 'vesselName', field: 'vesselName', header: 'Vessel Name', type: 'string', visible: true, toolTip: 'Name of the vessel.' },
                  { name: 'voyageNumber', field: 'voyageNumber', header: 'Voyage Number', type: 'string', visible: true, toolTip: 'Number assigned to voyage.' },
                  { name: 'startStackDate', field: 'startStackDate', header: 'Start Stack Date', type: 'date', visible: true, toolTip: 'Date and time that stacks open.' },
                  { name: 'endStackDate', field: 'endStackDate', header: 'End Stack Date', type: 'date', visible: true, toolTip: 'Date and time that stacks close.' }
                ];
                this.exportColumns = this.cols.map((col) => ({
                  title: col.header,
                  dataKey: col.field,
                }));
                return;
              }
            }
          } catch (error) {
            console.error('Error parsing columns or columnOrder:', error);
          }
        }
        this.useDefaultColumns('Invalid or empty configuration. Using default columns.');
      },
      error: (error) => {
        this.useDefaultColumns('Failed to load table configuration. Using default columns.');
      },
    });
  }

  useDefaultColumns(message: string) {
    console.warn(message);
    const defaultColumns = [
      { name: 'grReferenceNo', field: 'grReferenceNo', header: 'GR Reference No', type: 'string', visible: true, toolTip: 'GoReefers reference number for the booking.' },
      { name: 'clientReference', field: 'clientReference', header: 'Client Reference', type: 'string', visible: true, toolTip: 'Client’s reference number for the booking.' },
      { name: 'confirmedPackDate', field: 'confirmedPackDate', header: 'Confirmed Pack Date', type: 'date', visible: true, toolTip: 'Date the packing was confirmed.' },
      { name: 'loadingPointDescription', field: 'loadingPointDescription', header: 'Loading Point', type: 'string', visible: true, toolTip: 'Description of the loading location.' },
      { name: 'containerStatus', field: 'containerStatus', header: 'Container Status', type: 'string', visible: true, toolTip: 'Description of the container status.' },
      { name: 'loadingPortName', field: 'loadingPortName', header: 'Loading Port', type: 'string', visible: true, toolTip: 'Port where container will be loaded onto vessel.' },
      { name: 'finalDestinationName', field: 'finalDestinationName', header: 'Discharge Port', type: 'string', visible: true, toolTip: 'Port where the vessel discharges some or all of its cargo.' },
      { name: 'bookingReference', field: 'bookingReference', header: 'Booking Reference', type: 'string', visible: true, toolTip: 'Booking reference number.' },
      { name: 'vesselName', field: 'vesselName', header: 'Vessel Name', type: 'string', visible: true, toolTip: 'Name of the vessel.' },
      { name: 'voyageNumber', field: 'voyageNumber', header: 'Voyage Number', type: 'string', visible: true, toolTip: 'Number assigned to voyage.' },
      { name: 'startStackDate', field: 'startStackDate', header: 'Start Stack Date', type: 'date', visible: true, toolTip: 'Date and time that stacks open.' },
      { name: 'endStackDate', field: 'endStackDate', header: 'End Stack Date', type: 'date', visible: true, toolTip: 'Date and time that stacks close.' }
    ];

    this.cols = [...defaultColumns];
    this.selectedBookings = [...this.cols];
    this.bookingColumns = [...defaultColumns];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  rowClass(booking: any) {
    return { 'bground-primary': booking === 'Undefined' };
  }

  rowStyle(booking: any) {
    if (booking.quantity === 0) {
      return { fontWeight: 'bold', fontStyle: 'italic' };
    } else {
      return {};
    }
  }

  getSeverity(status: string): string {
    switch (status) {
      case 'Cancelled':
        return 'var(--danger-color)';
      case 'Confirmed':
        return 'var(--success-color)';
      case 'Awaiting Approval':
        return 'var(--warn-color)';
      case 'Processing':
        return 'var(--neutral-color)';
      case 'renewal':
        return 'var(--theme-neutral-bg)';
      default:
        return 'var(--theme-unknown-bg)';
    }
  }

  updateDisplayedColumns() {
    this.cols = [...this.selectedBookings];
  }

  private scrollToTop() {
    if (this.dt) {
      this.dt.scrollTo({ top: 0 });
    }
  }

  handleButtonClick(): void {
    if (this.isMobile()) {
      this.openPanel()
    } else {
      this.togglePanel();
    }
  }

  togglePanel() {
    this.panelCollapsed = !this.panelCollapsed;
  }

  isMobile(): boolean {
    return window.innerWidth <= 480;
  }

  isTablet(): boolean {
    return window.innerWidth <= 768;
  }

  clear(table: Table) {
    table.clear();
    this.searchValue = ''
  }

  openPanel(): void {
    this.panelService.openPanel('Select Table Columns', this.customContent);
  }

  closePanel(): void {
    this.panelService.closePanel()
  }

  updateCurrentRow(index: number): void {
    this.currentRowIndex = index + 1;
  }

  onPageChange(event: any): void {
    this.currentRowIndex = event.first;
  }

  updateCurrentIndex(container: Element, rows: NodeListOf<Element>): void {
    let closestIndex = 0;
    let closestDistance = Number.MAX_VALUE;

    rows.forEach((row, index) => {
      const rect = row.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      const distance = Math.abs(rect.top - containerRect.top);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestIndex = index;
      }
    });
    this.currentRowIndex = closestIndex;
  }

  onScroll(event: Event): void {
    const tableWrapper = event.target as HTMLElement;
    const rows = Array.from(tableWrapper.querySelectorAll('.snap-row')) as HTMLElement[];
    let currentRowIndex = 0;
    let closestDistance = Number.MAX_VALUE;
    rows.forEach((row, index) => {
      const rect = row.getBoundingClientRect();
      const distance = Math.abs(rect.top - tableWrapper.getBoundingClientRect().top);
      if (distance < closestDistance) {
        closestDistance = distance;
        currentRowIndex = index;
      }
    });
  }
}
