<p-toast key="key1"></p-toast>
<p-table #dt
         loadingIcon="pi pi-spinner-dotted pi-spin"
         [loading]="loading"
         styleClass="p-datatable-sm"
         [columns]="selectedBookings"
         [value]="approvedDataSource.data"
         [paginator]="!isMobile() && !isTablet()"
         [rows]="10"
         [rowHover]="true"
         [rowsPerPageOptions]="[5, 10, 20]"
         [globalFilterFields]="['customer', 'grReference', 'clientReference', 'destinationDescription', 'vesselName', 'eta', 'consignee', 'bookingReference']"
         [exportHeader]="'customExportHeader'"
         [reorderableColumns]="true"
         scrollHeight="600px"
         dataKey="id"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [showCurrentPageReport]="true"
         responsiveLayout="stack"
         (scroll)="onScroll($event)">
  <ng-template pTemplate="caption">
    <p-panel [toggleable]="false" [collapsed]="panelCollapsed">
      <ng-template pTemplate="header">
        <div style="display:flex; justify-content:space-between">
          <p-iconField iconPosition="left" class="">
            <p-inputIcon styleClass="pi pi-search" />
            <input pInputText
                   type="text"
                   [(ngModel)]="searchValue"
                   (input)="dt.filterGlobal(searchValue, 'contains')"
                   class="p-inputtext p-component-sm"
                   placeholder="Search keyword" />
            <span style="margin-left:-20px;">
              <a (click)="clear(dt)">
                <i class="fa fa-times" style="color: rgb(149, 149, 153); cursor: pointer;"></i>
              </a>
            </span>
          </p-iconField>
        </div>
      </ng-template>

      <ng-template pTemplate="icons">
        <div style="display:flex; gap:8px; flex-direction:row">
          <button pButton icon="pi pi-external-link"
                  pTooltip="Export approved documents to excel"
                  tooltipPosition="top"
                  (click)="exportExcel()"
                  class="table-actions small-button"></button>
          <button pButton
                  pTooltip="Configure table"
                  tooltipPosition="top"
                  class="table-actions mb-2 small-button"
                  [icon]="panelCollapsed ? 'pi pi-cog' : 'pi pi-spin pi-cog'"
                  (click)="handleButtonClick()">
          </button>
        </div>
      </ng-template>

      <div *ngIf="!panelCollapsed" style=" display: flex; justify-content: space-between;" class="flex justify-between justify-content-between flex-column sm:flex-row">
        <p-multiSelect class="w-full md:w-80"
                       styleClass="w-full"
                       display="chip"
                       [options]="bookingColumns"
                       [(ngModel)]="selectedBookings"
                       (onChange)="updateDisplayedColumns()"
                       optionLabel="header"
                       selectedItemsLabel="{0} columns selected"
                       placeholder="Choose Columns" />
        <button pButton pTooltip="Save table" tooltipPosition="bottom" icon="pi pi-save" class="table-save mb-2" (click)="saveTableConfig($event)"></button>
      </div>
    </p-panel>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr class="counter">
      <th>
        {{ currentRowIndex }} of {{approvedDataSource.data.length || 0}}
      </th>
    </tr>
    <tr class="headings">
      <th class="status-indicator">
      </th>
      <th *ngFor="let col of columns" pReorderableColumn pSortableColumn="{{ col.field }}" pTooltip="{{col.toolTip}}" tooltipPosition="top" tooltipClass="custom-mat-tooltip" [hidden]="!col.visible">
        {{ col.header }}
        <p-sortIcon field="{{ col.field }}" *ngIf="col.name !== 'documentStatusColor'"></p-sortIcon>
      </th>
      <th>
        <div *ngIf="columns && columns.length > 0">
          Actions
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-container class="scroll-container">
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData" [ngClass]="rowClass(rowData['documentStatusColor'])" [ngStyle]="rowStyle(rowData)" class="snap-row">
        <td class="status-indicator"
            [ngStyle]="{'background-color': getSeverity(rowData['documentApprovalStatus'])}">
        </td>
        <td *ngFor="let col of columns" [attr.data-label]="(col.field !== 'actions' && col.field !== 'indicators') ? col.header : null"
            class='has_label_on_mobile' [hidden]="!col.visible">
          <ng-container *ngIf="col.type === 'date'">
            {{ rowData[col.name] | date: 'dd MMM yyyy' }}
          </ng-container>
          <ng-container *ngIf="col.type !== 'date'&& col.name !== 'documentStatusColor'">
            {{ rowData[col.name] | truncateText}}
          </ng-container>
        </td>
        <td>
          <ng-container>
            <button class="detailed-view-btn"
                    (click)="viewDetails(rowData, detailView.documentDetail, detailTabbedViewEnum.documentTab)"
                    matTooltip="Show all documents available for download and tracking events." matTooltipPosition="above"
                    matTooltipClass="custom-mat-tooltip">
              View Details<mat-icon>arrow_forward</mat-icon>
            </button>
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </ng-container>
  <ng-template pTemplate="loadingbody" let-columns>
    <tr *ngFor="let _ of [0, 1, 2, 3, 4]">
      <td class="status-indicator">
        <p-skeleton shape="circle" size="1.5rem"></p-skeleton>
      </td>
      <td *ngFor="let col of columns">
        <p-skeleton></p-skeleton>
      </td>
      <td class="status-indicator">
        <p-skeleton></p-skeleton>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length + 2" class="centered-text">No approved documents found.</td>
    </tr>
  </ng-template>
</p-table>

<ng-template #customContent>
  <div class="btm-panel-container">
    <div *ngFor="let category of bookingColumns" class="flex items-center">
      <p-checkbox [inputId]="category.name" name="group" [value]="category" [(ngModel)]="selectedBookings" />
      <label [for]="category.name" class="ml-2"> {{ category.header }} </label>
    </div>
  </div>
  <div class="flex gap-3 mt-1">
    <button pButton label="Save" class="w-full table-save" (click)="saveTableConfig($event)"></button>
  </div>
</ng-template>
