import { Injectable } from '@angular/core';
import { ClaimAction } from '../../enums/keycloak-claim-action';
import { TableConfigEnum } from '../../models/table-config-enum';
import { Observable, catchError, of } from 'rxjs';
import { FilterData } from '../../models/list-model';
import { TableConfigModel } from '../../models/table-config';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  [x: string]: any;
  //No Permission Tooltip messages
  private apiUrl = environment.apiUrl;
  noAddBookingPermissionTooltipMessage: string = 'You do not have permission to add a booking';
  noEditDocumentPermissionTooltipMessage: string = 'You do not have permission to approve or reject a document';
  toolTipTimeOut: number = 0;

  constructor(private http: HttpClient) { }

  getNoPermissionTooltipMessage(permission: ClaimAction): { toolTipMessage: string; timeOut: number } {
    switch (permission) {
      case ClaimAction.Root2Go_AddBookings:
        return { toolTipMessage: this.noAddBookingPermissionTooltipMessage, timeOut: this.toolTipTimeOut};
      case ClaimAction.Root2Go_EditDocuments:
        return { toolTipMessage: this.noEditDocumentPermissionTooltipMessage, timeOut: this.toolTipTimeOut };
      default:
        return { toolTipMessage: 'You do not have permission to view this page', timeOut: this.toolTipTimeOut };
    }
  }


  getUserTableConfig(tableConfig: TableConfigModel): Observable<TableConfigModel> {
    // Extract the `table` property to use as a query parameter
    const url = `${this.apiUrl}/TableConfig/GetTableConfig?table=${tableConfig.table}`;

    // Remove the `table` property from the object (optional if not needed further)
    const { table, ...rest } = tableConfig;

    console.log('URL:', url);
    console.log('Payload (excluding table):', rest);

    return this.http.get<TableConfigModel>(url).pipe(
      catchError((error) => {
        console.error('Error occurred while fetching table config:', error);

        // Provide a fallback response
        return of({
          columnOrder: '',
          columns: '',
          table: table
        } as TableConfigModel);
      })
    );
  }


  saveTableConfigFilter(table: TableConfigModel): Observable<TableConfigModel> {
    // Use `table.table` only as a query parameter
    const url = `${this.apiUrl}/TableConfig/SaveTableConfig?table=${table.table}`;
    let body = {};

    if (table) {
      // Prepare the table data object, excluding the `table` property
      const tableData = Object.entries(table).reduce((acc, [key, value]) => {
        if (key !== 'table') { // Exclude the `table` property
          // If the value is an array and not empty, include it
          if (Array.isArray(value) && value.length > 0) {
            acc[key] = value;
          }
          // If the value is a JSON string, parse it and include it
          else if (typeof value === 'string' && value.trim().startsWith('[')) {
            try {
              acc[key] = JSON.parse(value);
            } catch (error) {
              console.error(`Failed to parse JSON for key "${key}":`, error);
            }
          }
          // Include other non-empty values
          else if (value) {
            acc[key] = value;
          }
        }
        return acc;
      }, {} as Record<string, any>);

      // Only assign the body if there's valid data
      if (Object.keys(tableData).length > 0) {
        body = JSON.stringify(tableData);
      }
    }

    console.log(`Sending request to URL: ${url}`);
    console.log(`Payload: ${body}`);

    return this.http.post<TableConfigModel>(url, body, {
      headers: { 'Content-Type': 'application/json' },
    }).pipe(
      catchError(error => {
        console.error('Error occurred while saving table config:', error);

        // Display an error message
        window.alert('An error occurred: ' + error.message);

        // Create a fallback response
        const fallbackResponse: TableConfigModel = {
          table: table.table, // Include table in fallback, but not in the body
          columnOrder: table.columnOrder,
          columns: table.columns
        };

        return of(fallbackResponse);
      })
    );
  }

  toIsoStringWithLocalOffset(date: Date): string {
    const localOffset = new Date().getTimezoneOffset() * 60 * 1000;

    return new Date(date.getTime() - localOffset).toISOString();
  }

  toFormatDateOnly(date: Date): string {
    const pad = (num: number) => num.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const day = pad(date.getDate());

    return `${year}-${month}-${day}`;
  }
}


