<div class="dv-timeline">
  <div *ngIf="hasTrackingEvents" style="width: 100%;">
    <table>
      <thead>
        <tr>
          <th *ngFor="let column of displayedColumns">
            {{ column[1] }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of events; let i = index" class="stepper-row">
          <td *ngFor="let column of displayedColumns" [attr.data-label]="column[1]"
            [ngClass]="{'date-column': column[0] === 'date', 'timeline-column': column[0] === 'state','estimated': !row.actual}">
            <ng-container [ngSwitch]="column[0]">
              <ng-container *ngSwitchCase="'state'">
                <div class="timeline-circle" [ngClass]="{'actual': row.actual, 'estimated': !row.actual}"></div>
              </ng-container>
              <ng-container *ngSwitchCase="'location'">
                {{ row.location }}
              </ng-container>
              <ng-container *ngSwitchCase="'description'">
                {{ row.description }}
              </ng-container>
              <ng-container *ngSwitchCase="'date'">
                {{ row.date && row.date.toString() !== '0001-01-01T00:00:00'
                ? (row.date | date: 'dd MMM yyyy, HH:mm') : ' ' }}
                <span class="highlight" *ngIf="row.actual !== null && row.actual !== undefined" [ngClass]="{
                    'highlight_black': row.actual,
                    'highlight_green': !row.actual
                  }">
                  {{ row.actual ? 'Actual' : 'Estimated' }}
                </span>
              </ng-container>
              <ng-container *ngSwitchDefault>
              </ng-container>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="!hasTrackingEvents">No Tracking Data</div>
</div>