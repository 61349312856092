import { Component, ElementRef, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { AppUtilityService } from '../../app/app-utility.service';
import { EmailAttachmentsModel } from '../models/emails/email-model';


interface EmailAttachment {
  name: string;
  base64: string; 
}

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrl: './file-uploader.component.css'
})
export class FileUploaderComponent implements OnInit, OnDestroy, OnChanges {

  @Input() config!: {
    MIME_types_accepted: string,
    is_multiple_selection_allowed: boolean,
    data: any
  };

  @Input() emailAttachments: EmailAttachmentsModel[] = [];

  selected_files: {
    file: any,
    is_upload_in_progress: boolean,
    upload_result: any
  }[] = [];




  
  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild("fileSelector", { static: false }) file_selector!: ElementRef;

  file_selection_form: FormGroup;

  private file_selection_sub!: Subscription;
  private file_upload_sub!: Subscription;

  email = {
    attachments: [] as EmailAttachment[] 
  };

  constructor(
    private global_utilities: AppUtilityService
  ) {
    this.file_selection_form = new FormGroup({
      file_selection: new FormControl()
    });
  }

  @Output() fileSelected = new EventEmitter<EmailAttachmentsModel[]>();
  @Output() fileDropped = new EventEmitter<any[]>();
  @Output() attachmentsUpdated = new EventEmitter<string>();
  @Output() filesCancelled = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.trackFileSelection();
    this.updateSelectedFiles();
  }

  openFileSelector() {
    if (this.file_selector) {
      const file_selection = this.file_selector.nativeElement;
      file_selection.click();
    } else {
      console.error('file_selector is not defined');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['emailAttachments']) {
      this.updateSelectedFiles();
    }
  }

  private updateSelectedFiles(): void {
    this.selected_files = this.emailAttachments.map(attachment => ({
      file: { name: attachment.name, base64: attachment.base64 }, 
      is_upload_in_progress: false,
      upload_result: 'success',
    }));
  }

  trackFileSelection() {
    this.file_selection_sub = this.file_selection_form.get('file_selection')?.valueChanges.subscribe(
      () => {
        const file_selection = this.file_selector.nativeElement;
        this.selectFiles(file_selection.files);
        this.file_selector.nativeElement.value = '';
      }
    ) as Subscription;
  }

  selectFiles(incoming_files: any[]) {
    let incoming_file_count = incoming_files.length;
    let incorrect_MIME_type = false;
    const base64Files: { name: string; base64: string }[] = []; 
    const readerPromises = [];

    for (let i = 0; i < incoming_file_count; i++) {
        let incoming_file = incoming_files[i];

        if (!!!this.config.MIME_types_accepted || this.config.MIME_types_accepted.indexOf(incoming_file.type) >= 0) {
            const MAX_FILE_SIZE = 25 * 1024 * 1024; 
            if (incoming_file.size > MAX_FILE_SIZE) {
              this.openSnackBarError(`File is too large: ${incoming_file.name}`);
                continue;
            }

            const reader = new FileReader();
            const readPromise = new Promise<void>((resolve, reject) => {
                reader.onload = () => {
                    const base64 = reader.result as string;
                    base64Files.push({ name: incoming_file.name, base64: base64 });

                    resolve();
                };

                reader.onerror = (error) => {
                    this.openSnackBarError('Error converting file to Base64: ' + error);
                    reject(error);
                };

                reader.readAsDataURL(incoming_file);
            });

            readerPromises.push(readPromise); 
        } else {
            incorrect_MIME_type = true;
            this.openSnackBarError(`Incorrect MIME type for file:'${incoming_file.name}`);
        }
    }

    // Wait for all FileReader operations to complete
    Promise.all(readerPromises).then(() => {
        this.fileSelected.emit(base64Files); 
        this.fileDropped.emit(base64Files);
    }).catch((error) => {
        console.error('Error reading files:', error);
    });

    
    if (incorrect_MIME_type) {
        let message = "Only files of the following MIME types are allowed: " + this.config.MIME_types_accepted;
        this.openSnackBarError(message);
    }
  }

  inititateFileCancel(fileName: string) {
    const file_for_upload = this.selected_files.find(f => f.file.name === fileName);
    if (file_for_upload && file_for_upload.is_upload_in_progress) {
      this.displayFileUploadAbortConfirmation(
        () => {
          this.cancelFile(fileName);
        }
      );
    } else {
      this.cancelFile(fileName);
    }
  }

  displayFileUploadAbortConfirmation(cancel_method: any) {
    this.global_utilities.displayAlertDialog({
      data: {
        title: "Abort File Upload?",
        message: "Upload is already in progress. Aborting now might lead to data inconsistencies.",
        dismiss_text: 'Dismiss',
        action_text: 'Abort',
        action: () => {
          cancel_method();
        }
      }
    });
  }

  cancelFile(fileName: string) {
    const fileIndex = this.selected_files.findIndex(f => f.file.name === fileName);
    if (fileIndex !== -1) {
      this.selected_files.splice(fileIndex, 1);
      this.attachmentsUpdated.emit(fileName);
    }
  }

  initiateCancelAll() {
    let selected_file_count = this.selected_files.length;
    let is_any_file_being_uploaded = false;
    for (let i = 0; i < selected_file_count; i++) {
      let selected_file = this.selected_files[i];
      if (selected_file.is_upload_in_progress) {
        is_any_file_being_uploaded = true;
        break;
      }
    }
    if (is_any_file_being_uploaded) {
      this.displayFileUploadAbortConfirmation(
        () => {
          this.cancelAll();
        }
      );
    } else {
      this.cancelAll();
    }
    this.filesCancelled.emit(true);
  }

  cancelAll() {
    this.global_utilities.scrollToElement('.modhyobitto-file-uploader', 100);
    let selected_file_count = this.selected_files.length;
    for (let i = 0; i < selected_file_count; i++) {
      this.selected_files.splice(0, 1);
    }
  }


  public openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  public openSnackBarError(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-fail']
    });
  }

  ngOnDestroy(): void {
    this.global_utilities.unsubscribeAll([
      this.file_selection_sub,
      this.file_upload_sub
    ]);
  }
}
