<mat-sidenav-container class="sidenav-container"  [ngClass]="getWrapperClass()">
  <mat-sidenav #drawer class="sidenav custom-sidenav" [class.expanded]="isExpanded" fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isDesktop$ | async) ? 'side' : 'over'"
    [opened]="!(isHandset$ | async)">
    <div class="mobile-extra-content">
      <div class="sidenav-logo"></div>
      <div class="welcome-banner">
        <div class="welcome-message">
          Welcome back, {{ userInfoModel.userFullName }} | {{ userInfoModel.userOrganisation }}
        </div>
      </div>
    </div>
    <mat-nav-list class="nav-list">
      <a
         *ngIf="!userInfoModel?.isConsigneeUser"
         [class.disabled]="!authService.hasClaim(ClaimAction.Root2Go_ViewDashboard)"
         mat-list-item routerLink="/dashboard"
         disableRipple>
        <div class="menu-item">
          <div class="menu-icon-wrapper">
            <div class="dashboard"></div>
          </div>
          <span class="item-label">Dashboard</span>
        </div>
      </a>
      <!-- <a *ngIf="!userInfoModel?.isConsigneeUser" mat-list-item
          [routerLink]="authService.hasClaim(ClaimAction.Root2Go_ViewAlerts) ? ['/alerts'] : null"
          [class.disabled]="!authService.hasClaim(ClaimAction.Root2Go_ViewAlerts)"
          [matTooltip]="!authService.hasClaim(ClaimAction.Root2Go_ViewAlerts) ? getPermissionTooltip(ClaimAction.Root2Go_ViewAlerts).toolTipMessage : null"
          matTooltipPosition="above"
          [matTooltipShowDelay]="!authService.hasClaim(ClaimAction.Root2Go_ViewAlerts) ? getPermissionTooltip(ClaimAction.Root2Go_ViewAlerts).timeOut : 1000"
          matTooltipClass="custom-mat-tooltip"
          disableRipple>
    <div class="menu-item">
      <div class="menu-icon-wrapper">
        <div class="alerts"></div>
      </div>
      <span class="item-label">Alerts</span>
    </div>
  </a>       -->

      <a mat-list-item
         [routerLink]="authService.hasClaim(ClaimAction.Root2Go_ViewSchedules) ? ['/schedules'] : null"
         [class.disabled]="!authService.hasClaim(ClaimAction.Root2Go_ViewSchedules)"
         [matTooltip]="!authService.hasClaim(ClaimAction.Root2Go_ViewSchedules) ? getPermissionTooltip(ClaimAction.Root2Go_ViewSchedules).toolTipMessage : null"
         matTooltipPosition="above"
         [matTooltipShowDelay]="!authService.hasClaim(ClaimAction.Root2Go_ViewSchedules) ? getPermissionTooltip(ClaimAction.Root2Go_ViewSchedules).timeOut : 1000"
         matTooltipClass="custom-mat-tooltip"
         disableRipple>
        <div class="menu-item">
          <div class="menu-icon-wrapper">
            <div class="schedules"></div>
          </div>
          <span class="item-label">Schedules</span>
        </div>
      </a>

      <a *ngIf="!userInfoModel?.isConsigneeUser" mat-list-item
         [routerLink]="authService.hasClaim(ClaimAction.Root2Go_ViewBookings) ? ['/booking management'] : null"
         [class.disabled]="!authService.hasClaim(ClaimAction.Root2Go_ViewBookings)"
         [matTooltip]="!authService.hasClaim(ClaimAction.Root2Go_ViewBookings) ? getPermissionTooltip(ClaimAction.Root2Go_ViewBookings).toolTipMessage : null"
         matTooltipPosition="above"
         [matTooltipShowDelay]="!authService.hasClaim(ClaimAction.Root2Go_ViewBookings) ? getPermissionTooltip(ClaimAction.Root2Go_ViewBookings).timeOut : 1000"
         matTooltipClass="custom-mat-tooltip"
         disableRipple>
        <div class="menu-item">
          <div class="menu-icon-wrapper">
            <div class="bookingManagement"></div>
          </div>
          <span class="item-label">Booking Management</span>
        </div>
      </a>

      <a mat-list-item
         [routerLink]="authService.hasClaim(ClaimAction.Root2Go_ViewDocuments) ? ['/document management'] : null"
         [class.disabled]="!authService.hasClaim(ClaimAction.Root2Go_ViewDocuments)"
         [matTooltip]="!authService.hasClaim(ClaimAction.Root2Go_ViewDocuments) ? getPermissionTooltip(ClaimAction.Root2Go_ViewDocuments).toolTipMessage : null"
         matTooltipPosition="above"
         [matTooltipShowDelay]="!authService.hasClaim(ClaimAction.Root2Go_ViewDocuments) ? getPermissionTooltip(ClaimAction.Root2Go_ViewDocuments).timeOut : 1000"
         matTooltipClass="custom-mat-tooltip"
         disableRipple>
        <div class="menu-item">
          <div class="menu-icon-wrapper">
            <div class="documentManagement"></div>
          </div>
          <span class="item-label">Documents</span>
        </div>
      </a>
      <a mat-list-item
         [routerLink]="authService.hasClaim(ClaimAction.Root2Go_ViewShipments) ? ['/shipments'] : null"
         [class.disabled]="!authService.hasClaim(ClaimAction.Root2Go_ViewShipments)"
         [matTooltip]="!authService.hasClaim(ClaimAction.Root2Go_ViewShipments) ? getPermissionTooltip(ClaimAction.Root2Go_ViewShipments).toolTipMessage : null"
         matTooltipPosition="above"
         [matTooltipShowDelay]="!authService.hasClaim(ClaimAction.Root2Go_ViewShipments) ? getPermissionTooltip(ClaimAction.Root2Go_ViewShipments).timeOut : 1000"
         matTooltipClass="custom-mat-tooltip"
         disableRipple>
        <div class="menu-item">
          <div class="menu-icon-wrapper">
            <div class="shipments"></div>
          </div>
          <span class="item-label">Shipments</span>
        </div>
      </a>
      <!-- <a *ngIf="!userInfoModel?.isConsigneeUser" mat-list-item
          [routerLink]="authService.hasClaim(ClaimAction.Root2Go_ViewAnalytics) ? ['/analytics'] : null"
          [class.disabled]="!authService.hasClaim(ClaimAction.Root2Go_ViewAnalytics)"
          [matTooltip]="!authService.hasClaim(ClaimAction.Root2Go_ViewAnalytics) ? getPermissionTooltip(ClaimAction.Root2Go_ViewAnalytics).toolTipMessage : null"
          matTooltipPosition="above"
          [matTooltipShowDelay]="!authService.hasClaim(ClaimAction.Root2Go_ViewAnalytics) ? getPermissionTooltip(ClaimAction.Root2Go_ViewAnalytics).timeOut : 1000"
          matTooltipClass="custom-mat-tooltip"
          disableRipple>
    <div class="menu-item">
      <div class="menu-icon-wrapper">
        <div class="analytics"></div>
      </div>
      <span class="item-label">Analytics</span>
    </div>
  </a> -->
      <div class="divider-line"></div>
      <div class="sign-out-wrapper">
        <button class="menu-item sign-out" type="button" aria-label="logout" mat-icon-button (click)="logout()">
          <img class="menu-icon-wrapper" src="assets/SignOut.svg" alt="Sign Out">
          <span class="item-label">Sign Out</span>
        </button>
      </div>
    </mat-nav-list>
    <div class="bottom-section">

      <div class="help-section">
        <div class="help-section-header">
          <img src="assets/SealQuestion.svg" alt="">
          <h4>More Info</h4>
        </div>
        <div class="help-section-links" style="cursor: pointer;">
          <button class="help-section-button" (click)="navAbout()">About Us</button>
          <button class="help-section-button" routerLink="/contact">Contact Us</button>
        </div>

      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav #rightDrawer [@sidenavAnimation]="sidenavState" [ngClass]="currentClass" position="end" mode="over"
    (closedStart)="onRightDrawerClosed()">
    <!-- <ng-container *ngIf="activeRightDrawer === RightDrawer.alertSideNav">
      <div class="close-alertDrawer" (click)="closeRightDrawer()">
        <span>Close</span>
      </div>
      <div class="alert-sidenav-grid">
        <app-alert-sidenav></app-alert-sidenav>
        <div class="view-all-container">
          <a routerLink="/alerts" class="view-all-btn" (click)="closeRightDrawer()">View All</a>
        </div>
      </div>
    </ng-container> -->

    <ng-container *ngIf="activeRightDrawer === RightDrawer.FilterSideNav">
      <div class="close-alertDrawer" (click)="closeRightDrawer()">
        <span>Close</span>
      </div>
      <div class="">
        <app-filter-sidenav></app-filter-sidenav>
      </div>
    </ng-container>

    <ng-container *ngIf="activeRightDrawer === RightDrawer.DetailViewSideNav">
      <div class="close-shipment-details" (click)="closeRightDrawer()" matTooltip="Close details."
        matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
        <mat-icon svgIcon="CancelIcon"></mat-icon>
      </div>
      <div class="">
        <app-detailed-view-nav></app-detailed-view-nav>
      </div>
    </ng-container>

  </mat-sidenav>

  <mat-sidenav-content class="custom-sidenav-content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>
