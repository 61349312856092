import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserInfoModel } from '../../models/user-info-model';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiUserService {

  private apiUrl = environment.apiUrl;
  private _cachedUserInfo: Subject<UserInfoModel> = new Subject();
  public userInfo = this._cachedUserInfo.asObservable();
  public IsSingleCustUser: boolean = false;
  public IsConsigneeUser: boolean = false;
  public IsSingleConsignee: boolean = false;
  public UserBookingTemplateLimit: number = 0;

  constructor(private http: HttpClient) {
    const url = this.apiUrl + '/User/GetUserInfo';
    this.http.get<UserInfoModel>(url).subscribe(res => {
      this.IsSingleCustUser = res.isSingleCustomer;
      this.IsConsigneeUser = res.isConsigneeUser;
      this.IsSingleConsignee = res.isSingleConsignee;
      this.UserBookingTemplateLimit = res.userBookingTemplateLimit;
      this._cachedUserInfo.next(res);
    });
  }
}
