import { Component, OnInit } from '@angular/core';
import { CommunicationService } from '../service/communication/communication.service';
import { Subject, takeUntil } from 'rxjs';
import { ShipmentModel, ShipmentTranferGRRefContainerNo } from '../models/shipment/shipment-data-model';
import { ShipmentService } from '../service/shipment/shipment.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { DocumentService } from '../service/documents/document.service';
import { IDocument } from '../models/documents/document';
import { FormControl } from '@angular/forms';
import { EmaiLToModel } from '../models/emails/selected-documents';
import { EmailService } from '../service/emails/email.service';
import { MessageService } from 'primeng/api';
import { ApiUserService } from '../service/user/api-user.service';

@Component({
  selector: 'app-detailed-document',
  templateUrl: './detailed-document.component.html',
  styleUrl: './detailed-document.component.css',
  providers: [MessageService]
})
export class DetailedDocumentComponent implements OnInit {
  private destroy$ = new Subject<void>();
  grRefContainerData: ShipmentTranferGRRefContainerNo = new ShipmentTranferGRRefContainerNo;
  public shipmentData: ShipmentModel = new ShipmentModel;
  isConsigneeUser: boolean = false;
  isLoading: boolean = true;
  emptyShipment: boolean = false;
  isSelectDocumentsDialogvisible: boolean = false;
  visible: boolean = false;
  documentList: IDocument[] = [];
  emailTo = new FormControl('');
  selectAll: boolean = false;
  selectedDocuments: IDocument[] = [];

  constructor(
    private communicationService: CommunicationService,
    private shipmentDataService: ShipmentService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private documentService: DocumentService,
    private emailService: EmailService,
    private messageService: MessageService,
    private apiUserService: ApiUserService,
  ) {
    this.matIconRegistry.addSvgIcon('group-email', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/PaperPlaneTilt.svg')); 
  }

  ngOnInit(): void {
    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isConsigneeUser = this.apiUserService.IsConsigneeUser;
          }
      });
    this.isConsigneeUser = this.apiUserService.IsConsigneeUser;

    this.communicationService.grRefcontainerNoParameter$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async data => {
          this.grRefContainerData = data;
          try {
            await this.getShipemntsForGRRefContainerNo(this.grRefContainerData);
            this.isLoading = false;
          } catch (error) {
            console.error('Error fetching shipment data:', error);
          }
        }
      });

      if (this.grRefContainerData.grReferenceNo) {
        this.getDocuments(this.grRefContainerData.grReferenceNo);
      }

  }

  getDocuments(documentParam: string): void {
    this.documentService.getDocumentsForGRRef(documentParam, '').subscribe(data => {
      this.documentList = data;
      console.log(this.documentList);
    })
  }

  async getShipemntsForGRRefContainerNo(data: ShipmentTranferGRRefContainerNo): Promise<void> {
    return new Promise((resolve, reject) => {
      this.shipmentDataService.getShipmentsForGRRef(data.grReferenceNo)
        .subscribe({
          next: async (data) => {
            if (data.length > 0)
              this.shipmentData = data[0];
            else
              this.emptyShipment = true;
            resolve();
          },
          error: (error) => {
            reject(error);
          }
        });
    });
  }

  emailSelectedDocuments(){
    if (this.emailTo.value) {
      let emailSelectedDocumentsModel = new EmaiLToModel();
      emailSelectedDocumentsModel.emailTo = this.emailTo.value;
      emailSelectedDocumentsModel.shipperName = this.shipmentData.customer;
      emailSelectedDocumentsModel.containerNumber = this.shipmentData.containerNumber;
      emailSelectedDocumentsModel.shipmentNumber = this.grRefContainerData.grReferenceNo ?? '';
      emailSelectedDocumentsModel.attachments = this.selectedDocuments.map(doc => ({
        fileType: doc.documentTypeDocument,
        fileId: doc.documentAttachmentSystemId,
        fileExtension: doc.attachmentType,
      }));
      this.emailTo.reset();
      this.selectedDocuments = [];
      this.isSelectDocumentsDialogvisible = false;
      this.visible = true;

this.emailService.sendSelectedDocumentsEmail(emailSelectedDocumentsModel).subscribe({
  next: () => {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Your documents have been successfully sent' });
  },
  error: (error) => {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'There was an error sending your documents. Please try again later.' });
    console.error('Error sending email:', error);
  }
});
      console.log('emailSelectedDocumentsModel',emailSelectedDocumentsModel)
    }
  }

  showDialog() {
      this.isSelectDocumentsDialogvisible = true;
  }

  hideDialog() {
    this.visible = false;
}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
