export interface IBookingLineModel {
  bookingHeaderCode: string;
  lineNo: number;
  appBookingCode: string;
  appBookingLineNumber: number;
  bookingRef: string;
  clientRef: string;
  containerStatus: string;
  appBookingStatus: string;
  rejectionReason: string | null;
  dischargePort: string;
  finalDestination: string;
  cnfFinalDestinationCode: string | null;
  cnfFinalDestinationName: string | null;
  confirmedPackDate: Date | null;
  commodity: string;
  commodityCode: string;
  loadingPoint1: string;
  loadingPoint2: string;
  temperature: number;
  quantity: number;
  lateStackRequest: boolean;
  containerTypeCode: string;
  containerTypeDescription: string;
  solasMethod: string;
  shipSterilized: boolean;
  stepUp: string;
  tempTale: boolean;
  temptaleDescription: string;
  temptaleQty: number;
  preVerificationKey: string;
  dischargePortCode: string;
  eta: Date | null;
  lastModifiedDateTime: Date;

}

export class BookingLineModel implements IBookingLineModel {
  bookingHeaderCode: string = '';
  lineNo: number = 0;
  appBookingCode: string = '';
  appBookingLineNumber: number = 0;
  bookingRef: string = '';
  clientRef: string = '';
  containerStatus: string = '';
  appBookingStatus: string = '';
  rejectionReason: string | null = null;
  dischargePort: string = '';
  finalDestination: string = '';
  cnfFinalDestinationCode: string | null = null;
  cnfFinalDestinationName: string | null = null;
  confirmedPackDate: Date | null = null;
  commodity: string = '';
  commodityCode: string = '';
  loadingPoint1: string = '';
  loadingPoint2: string = '';
  temperature: number = 0;
  quantity: number = 0;
  lateStackRequest: boolean = false
  containerTypeCode: string = '';
  containerTypeDescription: string = '';
  solasMethod: string = '';
  shipSterilized: boolean = false;
  stepUp: string = '';
  tempTale: boolean = false;
  temptaleDescription: string = '';
  temptaleQty: number = 0;
  preVerificationKey: string = '';
  dischargePortCode: string = '';
  eta: Date | null = null;
  lastModifiedDateTime: Date = new Date;
}
