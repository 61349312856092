import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnDestroy, inject } from '@angular/core';
import { FilterData } from '../models/list-model';
import { FormControl } from '@angular/forms';
import { AlertService } from '../service/alerts/alert.service';
import { LookupModel } from '../models/lookup-model';
import { DataFilter } from '../models/filter-models';
import { MatDialog } from '@angular/material/dialog';
import { InformationComponent } from '../dialog/information/information.component';
import { LookupService } from '../service/lookup/lookup.service';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { CommunicationService } from '../service/communication/communication.service';

@Component({
  selector: 'app-alert-filter',
  templateUrl: './alert-filter.component.html',
  styleUrls: ['./alert-filter.component.css', '../filter-sidenav/filter-sidenav.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AlertFilterComponent implements OnInit, OnDestroy {

  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  isSingleCustUser: boolean = false;
  
  customers = new FormControl<LookupModel[]>([]);
  containerNos = new FormControl('');
  grRefNos = new FormControl('');
  savedFilterName = new FormControl('');
  savedFilters: DataFilter[] = [];

  customersMultiFilter = new FormControl<string>('');;

  filterData: FilterData = {};
  filterName: string = '';
  datafilter: DataFilter = new DataFilter();
 
  transportModesMultiFilter = new FormControl<string>('');
  templateGroupsMultiFilter = new FormControl<string>('');
  alertGroupsMultiFilter = new FormControl<string>('');

  carriersMultiFilter = new FormControl<string>('');
  filteredTransportModes!: Observable<LookupModel[]>;
  filteredTemplateGroups!: Observable<LookupModel[]>;
  filteredAlertGroups!: Observable<LookupModel[]>;
  alertTemplateGroups!: Observable<LookupModel[]>;
  filteredCustomers!: Observable<LookupModel[]>;

  alertGroups = new FormControl<LookupModel[]>([]);
  templateGroups = new FormControl<LookupModel[]>([]);
  transportModes = new FormControl<LookupModel[]>([]);
  alertCustomer: LookupModel[] = [];

  transportModeData: LookupModel[] = [];
  templateGroupData: LookupModel[] = [];
  alertGroupData: LookupModel[] = [];

  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private communicationService: CommunicationService,private domSanitizer: DomSanitizer, private alertDataService: AlertService, private lookupService: LookupService, private cdr: ChangeDetectorRef, private dialog: MatDialog, private matIconRegistry: MatIconRegistry) {
    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
  }

  ngOnInit(): void {

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isSingleCustUser = this.apiUserService.IsSingleCustUser;
          }
      });

    this.isSingleCustUser = this.apiUserService.IsSingleCustUser;

    this.loadAlertData();
  }

  loadAlertData(): void {

    this.alertDataService.sendActiveFilterToFilter$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.datafilter = data;
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Filter data', error)
      });

    this.lookupService.getCustomers(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.alertCustomer = data;
          this.customers.setValue(this.alertCustomer.filter(customer => customer.isSelected));
          this.filteredCustomers = this.customersMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterCustomers(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

    this.lookupService.getModesOfTransport(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.transportModeData = data;
          this.transportModes.setValue(this.transportModeData.filter(filter => filter.isSelected));
          this.filteredTransportModes = this.transportModesMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterTransportModes(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Load Port data', error)
      });

    this.lookupService.getAlertTemplateGroups(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.templateGroupData = data;
          this.templateGroups.setValue(this.templateGroupData.filter(filter => filter.isSelected));
          this.filteredTemplateGroups = this.templateGroupsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterTemplateGroups(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Alert Template data', error)
      });

    this.lookupService.getAlertGroups(this.datafilter.filter)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.alertGroupData = data;
          this.alertGroups.setValue(this.alertGroupData.filter(filter => filter.isSelected));
          this.filteredAlertGroups = this.alertGroupsMultiFilter.valueChanges.pipe(
            startWith(''),
            map(searchText => this.filterAlertGroups(searchText ?? ''))
          );

          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Alert Group data', error)
      });

    let grRefNosRestore = this.datafilter.filter['grReference']?.toString() ?? null;
    if (grRefNosRestore != null) {
      this.grRefNos.setValue(grRefNosRestore);
    }

    let containerNosRestore = this.datafilter.filter['containerNo']?.toString() ?? null;
    if (containerNosRestore != null) {
      this.containerNos.setValue(containerNosRestore);
    }

    if (this.datafilter.id !== 0) {
      this.filterName = this.datafilter.filterName;
      this.savedFilterName.setValue(this.filterName);
    }
  }

  clearLookupFilter(control: FormControl): void {
    control.reset();
    control.setValue([]);
    console.log('Filter cleared:', control);
  }

  save(): void {
    if (!this.savedFilterName.value) {
      console.error('Filter name cannot be empty');
      return;
    }

    this.createFilter();
    this.filterName = this.savedFilterName.value.toString();
    this.alertDataService.saveAlertFilter(this.filterName, this.filterData).subscribe(_ => {
      this.alertDataService.filterNameSavedSend(this.filterName);
      this.alertDataService.getFilters().subscribe(result => {
        this.savedFilters = result;
        const savedFil = this.savedFilters.find(f => f.filterName === this.filterName);
        this.datafilter.id = savedFil!.id;
        this.datafilter.filterName = savedFil!.filterName;
        this.datafilter.filter = savedFil!.filter;
      });
    });
    this.openSnackBar('Your filter ' + this.filterName + ' has been been created');
  }

  clearAllFilters(): void {
    this.clearFilter(this.grRefNos);
    this.clearFilter(this.containerNos);
    this.clearLookupFilter(this.customers);
    this.clearLookupFilter(this.templateGroups);
    this.clearLookupFilter(this.transportModes);
    this.applyFilters();
    this.openSnackBar('Your filters have been been cleared');
  }

  clearFilter(control: FormControl) {
    control.reset();
    control.setValue([]);
  }

  private filterCustomers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.alertCustomer;
    }
    return this.alertCustomer.filter(customer =>
      customer.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  get isSavedFilterNameEmpty(): boolean {
    return !this.savedFilterName.value;
  }

  deleteFilter(): void {
    let filterName = this.datafilter.filterName;

    this.alertDataService.deleteAlertFilter(this.datafilter.id).subscribe(result => {
      this.alertDataService.filterNameSavedSend('');
      this.savedFilterName.setValue('');
      this.datafilter.id = 0;
      this.datafilter.filterName = '';
      this.openSnackBar('Your filter, ' + filterName + ' has been deleted');
      console.log(result);
    });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  applyFilters(): void {
    this.createFilter();
    this.updateCurrentFilter(0, '', this.filterData);
    this.alertDataService.transferFilterData(this.filterData);
    this.alertDataService.sendingActiveFilterToList(this.datafilter);
    this.closeRightDrawer();
    this.openSnackBar('Your filter has been been applied');
  }

  createFilter(): void {
    if (this.customers.value != null) {
      const customerCodes = this.parseFilterSelections(this.customers.value);
      this.processFilterData('customerCode', customerCodes);
    }
    if (this.containerNos.value != null) {
      this.processFilterData('containerNo', this.containerNos.value?.toString());
    }
    if (this.grRefNos.value != null) {
      this.processFilterData('grReference', this.grRefNos.value?.toString());
    }

    if (this.alertGroups.value != null) {
      const alertGroupCode = this.parseFilterSelections(this.alertGroups.value);
      this.processFilterData('templateGroup', alertGroupCode);
    }

    if (this.templateGroups.value != null) {
      const templateGroupCode = this.parseFilterSelections(this.templateGroups.value);
      this.processFilterData('template', templateGroupCode);
    }

    

    if (this.transportModes.value != null) {
      const transportModeCode = this.parseFilterSelections(this.transportModes.value);
      this.processFilterData('transportMode', transportModeCode);
    }
  }

  parseFilterSelections(selections: LookupModel[]): string {
    const codes: string[] = selections.map(selection => selection.code);
    return codes.join(',');
  }

  processFilterData(key: string, value: string) {
    const dataArray = value
      .split(',')
      .filter(item => item.trim() !== '');

    this.filterData[key] = dataArray;
  }

  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.datafilter.id = id;
    this.datafilter.filterName = name;
    this.datafilter.filter = filter;
  }

  showInformation(result: any, message: string | null): void {
    const dialogRef = this.dialog.open(InformationComponent, {
      width: '300px',
      height: '250px',
      data: { resultStatus: result, showMessage: message },
    });
  }

  showError(message: string): void {
    console.error(message);
    this.showInformation('Document rejection Failed', message)
  }

  private filterTransportModes(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.transportModeData;
    }
    return this.transportModeData.filter(transportMode =>
      transportMode.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterTemplateGroups(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.templateGroupData;
    }
    return this.templateGroupData.filter(templateGroup =>
      templateGroup.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  private filterAlertGroups(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.alertGroupData;
    }
    return this.alertGroupData.filter(alertGroup =>
      alertGroup.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }  

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
    //TODO: Fine a way to get this window to close without forcing it closed using CDR
  //Application : When a user clicks on apply filter this filter side nav needs to close
  //Scale: P3 None Critical
  closeRightDrawer(){
    this.communicationService.closeRightNav();
  }
}
