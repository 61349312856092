<div>
  <div class="form-container" id="form-container">
    <form id="mainForm"  enctype="multipart/form-data">
      <div class="container">
        <h1>Contact Us</h1>
      </div>
      <p class="centered-text">
        Need assistance or want to provide us with feedback on Root2Go?
      </p>
      <p class="centered-text">
        We’re here for you every step of the way! Simply get in touch by filling out the form below:
      </p>
      <p class="centered-text" style="margin-bottom: 20px;">
        Your suggestions or feedback would be valued.
      </p>

      <mat-form-field appearance="fill">
        <mat-label>Subject</mat-label>
        <mat-select [(ngModel)]="email.subject" name="subject" required>
          <mat-option value="Enquiry">Enquiry</mat-option>
          <mat-option value="Support">Support</mat-option>
          <mat-option value="Feedback">Feedback</mat-option>
          <mat-option value="Complaint">Complaint</mat-option>
          <mat-option value="Feature Suggestion">Feature Suggestion</mat-option>
        </mat-select>
        <mat-error *ngIf="!email.subject && submitted">Subject is required.</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Message</mat-label>
        <textarea rows="3" matInput [(ngModel)]="email.message" name="message" required></textarea>
        <mat-error *ngIf="!email.message && submitted">Message is required.</mat-error>
      </mat-form-field>


      <app-file-uploader [config]="file_upload_config"
      (fileSelected)="onFileSelected($event)"
      (fileDropped)="onFilesDropped($event)"
      (attachmentsUpdated)="onAttachmentCanceled($event)"
      (filesCancelled)="cancelAllAttachments($event)"
      [emailAttachments]="email.attachments">
      </app-file-uploader>

      <button mat-button [disabled]="isDisabled" type="button" (click)="submitForm()" class="submit-button">
               Submit
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M22 2L11 13"></path>
          <path d="M22 2L15 22L11 13L2 9L22 2Z"></path>
        </svg>
      </button>

    </form>

  </div>
  <div *ngIf="isSpinnerVisible" class="spinner-overlay">
    <div class="spinner-container">
      <mat-spinner color="primary" diameter="200"></mat-spinner>
    </div>
  </div>

  <p-dialog
    [header]="dialogTitle"
    [modal]="true"
    [(visible)]="isDialogVisible"
    [style]="{ width: '25rem' }"
    headerStyleClass="custom-dialog-header">
        <p>
            {{dialogMessage}}
        </p>
        <div mat-dialog-actions class="dialog-button-wrap">
          <button class="dialog-okay-button" mat-button (click)="closeDialog()">Okay</button>
        </div>
</p-dialog>
</div>
