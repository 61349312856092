<div class="dv-documents-wrapper">
  <div class="dv-document-group">
    <h3 class="dv-table-title">Shipping Documents</h3>
    <table class="shipping-documents">
      <thead>
        <tr>
          <th *ngFor="let column of getvisibleColumns()">
            {{column.visible ? column.header : ''}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of dataSourceShipmentingDocs" class="document-data">
          <td *ngFor="let column of getvisibleColumns()"
            [attr.data-label]="column.visible ? column.header :''">
            <ng-container [ngSwitch]="column.name">
              <ng-container *ngSwitchCase="'documentTypeDocument'">
                {{ row.documentTypeDocument}}
              </ng-container>
              <ng-container *ngSwitchCase="'documentApprovalStatus'">
                {{ row.documentApprovalStatus}}
              </ng-container>
              <ng-container *ngSwitchCase="'documentReasonDetail'">
                {{ row.documentReasonDetail}}
              </ng-container>
              <ng-container *ngSwitchCase="'actions'">
                <div class="actions">
                  <button class="downloadButton" (click)="downloadDocument(row)" matTooltip="Download the document."
                    matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
                    <img class="action-icons" src="/assets/DownloadSimple.svg" alt="Download" width="20" height="20">
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>

  </div>

  <div class="dv-document-group">
    <h3 class="dv-table-title">PPECB Addendums and Certificates</h3>
    <table class="shipping-documents">
      <thead>
        <tr>
          <th *ngFor="let column of getvisiblePPECBColumns()">
            {{ column.header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of dataSourcePPECBDocs" class="document-data">
          <td *ngFor="let column of getvisiblePPECBColumns()" [attr.data-label]="column.header">
            <ng-container [ngSwitch]="column.name">
              <ng-container *ngSwitchCase="'documentTypeDocument'">
                {{ row.documentTypeDocument }}
              </ng-container>
              <ng-container *ngSwitchCase="'attachmentFilename'">
                {{ row.attachmentFilename }}
              </ng-container>
              <ng-container *ngSwitchCase="'documentApprovalStatus'">
                {{ row.documentApprovalStatus }}
              </ng-container>
              <ng-container *ngSwitchCase="'documentReasonDetail'">
                {{ row.documentReasonDetail }}
              </ng-container>
              <ng-container *ngSwitchCase="'actions'">
                <div class="actions">
                  <button class="downloadButton" (click)="downloadDocument(row)" matTooltip="Download the document."
                          matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
                    <img class="action-icons" src="/assets/DownloadSimple.svg" alt="Download" width="20" height="20">
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
