import { Component, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges, ViewChild, ElementRef, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { SchedulesService } from '../service/schedules/schedules.service';
import { ScheduleListModel } from '../models/schedules/schedule-list-model';
import { ScheduleVoyageCoordinates } from '../models/schedules/schedule-voyage-coordinates';
import { CommunicationService } from '../service/communication/communication.service';
import { detailTabbedViewEnum } from '../models/detail-view-enum';
import { Alert } from '../models/alerts/alerts';
import { AlertService } from '../service/alerts/alert.service';
import { AddBookingEnum, BookingDataTransferModel } from '../models/booking-data-model';
import { Router } from '@angular/router';
import * as L from 'leaflet';
import { ScheduleRouteLinesModel } from '../models/schedules/schedule-route-lines-model';
import { TransportMode } from '../enums/transport-mode.enum';
import { MatDialog } from '@angular/material/dialog';
import { LateStackWarningComponent } from '../dialog/late-stack-warning/late-stack-warning.component';
import { BookingEditModel } from '../models/bookings/booking-edit-model';
import { TooltipPosition, MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '../service/auth-service/auth-service.service';
import { ClaimAction } from '../enums/keycloak-claim-action';
import { CommonService } from '../service/common/common.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportScheduleDialogComponent } from '../dialog/report-schedule-dialog/report-schedule-dialog.component';
import { ScheduleIncorrectEmailModel } from '../models/schedules/schedule-incorrect-email-model';
import { catchError, of } from 'rxjs';
import { EmailService } from '../service/emails/email.service';
import { InformationComponent } from '../dialog/information/information.component';
import { ApiUserService } from '../service/user/api-user.service';

@Component({
  selector: 'app-schedules-detail-view',
  templateUrl: './schedules-detail-view.component.html',
  styleUrls: ['./schedules-detail-view.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SchedulesDetailViewComponent implements OnInit, AfterViewInit {

  detailTabbedViewEnum = detailTabbedViewEnum;
  detailTabbedViewEnumSelected = detailTabbedViewEnum.trackingTab;
  @Input() selectedTab: detailTabbedViewEnum = this.detailTabbedViewEnumSelected;
  public bookingData = new BookingEditModel;
  @Output() bookingDataOutput = new EventEmitter<BookingEditModel>();
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
  private map: any;
  private routePolyline: any;
  public alertData: Alert[] = [];
  public scheduleRouteLines: ScheduleRouteLinesModel[] = [];
  protected voyageCoordinates: ScheduleVoyageCoordinates[] = [];
  isConsigneeUser: boolean = false;
  isSpinnerVisible: boolean = false;
  isDisabled = false;

  private scheduleHeaderCode: string = '';
  private carrierCode: string = '';
  private scheduleLoadPortCode: string = '';
  private dischargePortCode: string = '';
  private scheduleLineNumber: number = 0;
  private isLateStack: boolean | null = null;
  private isSiCutOff: boolean | null = null;
  private siCutoffDate: Date = new Date();
  ClaimAction = ClaimAction;

  public schedule = new ScheduleListModel();
  public bookingTransfer = new BookingDataTransferModel();
  public scheduleIncorrect = new ScheduleIncorrectEmailModel();
  public transportMode: TransportMode = TransportMode.Ocean;

  public startLocation: L.LatLngExpression = [0, 0];
  public endLocation: L.LatLngExpression = [0, 0];

  constructor(
    private schedulesService: SchedulesService,
    private alertDataService: AlertService,
    private communicationService: CommunicationService,
    private router: Router,
    private commonService: CommonService,
    private dialog: MatDialog,
    public authService: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private emailService: EmailService,
    private apiUserService: ApiUserService,
  ) {
    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/FlagPennant.svg'));
   }

  ngOnInit(): void {
    this.communicationService.siCutOffDate$.subscribe(value => {
      this.siCutoffDate = value as unknown as Date;
    });

    this.communicationService.detailViewParam.subscribe(param => {
      var paramParts = param.split('|');
      this.scheduleHeaderCode = paramParts[0];
      this.carrierCode = paramParts[1];
      this.scheduleLoadPortCode = paramParts[2];
      this.dischargePortCode = paramParts[3]
      this.schedule.loadETD = new Date(paramParts[4]);
    });

    this.communicationService.detailedViewToggle$.subscribe(d => {
      this.selectedTab = d.detailTabbedViewEnum;
    });

    this.isConsigneeUser = this.apiUserService.IsConsigneeUser;
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.getScheduleListDetail(this.scheduleHeaderCode, this.carrierCode, this.scheduleLoadPortCode, this.dischargePortCode, this.schedule.loadETD);
    this.getScheduleLines(this.scheduleHeaderCode, this.scheduleLoadPortCode, this.dischargePortCode, this.carrierCode);
  }

  protected mapTabClicked(): void {
    this.loadData();
  }

  private getScheduleLines(scheduleHaderCode: string, loadPort: string, dischargePort: string, carrierCode: string): void {
    this.schedulesService.getScheduleRoute(scheduleHaderCode, loadPort, dischargePort, carrierCode).subscribe(data => {
      this.scheduleRouteLines = data;
    });
  }

  private getScheduleListDetail(scheduleHaderCode: string, carrierCode: string, scheduleLoadPortCode: string, dischargePortCode: string, loadETD: Date): void {
    this.schedulesService.getScheduleListDetail(scheduleHaderCode, carrierCode, scheduleLoadPortCode, dischargePortCode, loadETD).subscribe(data => {
      this.schedule = data;
      this.scheduleLineNumber = this.schedule.lineNumber;
    })
  }

  private getAlertsForGRRef(alertParameter: string): void {
    this.alertDataService.getAlertsForReference('Schedule', alertParameter).subscribe(data => { this.alertData = data })
  }

  async handleNewBooking(stackDate: Date, loadETD: Date) {
    // Ensure stackDate is a Date object
    const dateToCheckstackDate = new Date(stackDate);
    let dateToChecksiCutOff = new Date(this.siCutoffDate);
    const dateToCheckloadETD = new Date(loadETD);
    const now = new Date();
    const timeDifferenceLateStack = dateToCheckstackDate.getTime() - now.getTime();
    const timeDifferenceSiCutOff = dateToChecksiCutOff.getTime() - now.getTime();
    const SIX_HOURS = 6 * 60 * 60 * 1000; // Constant for 6 hours in milliseconds
    const EIGHTEEN_HOURS = 18 * 60 * 60 * 1000; // Constant for 18 hours in milliseconds
    const INVALID_DATE = -60000000000000;

    try {
      // Show SI Cut Off Warning if needed
      if (timeDifferenceSiCutOff <= EIGHTEEN_HOURS && timeDifferenceSiCutOff >= INVALID_DATE) {
        const siCutOffConfirmed = await this.openWarningDialog(
          'SI Cut Off Warning',
          'This request is close to the SI Cut Off closure. By proceeding you acknowledge acceptance of possible missing SI Cut Off. Do you wish to proceed?'
        );
        if (!siCutOffConfirmed) return; // Exit if SI Cut Off warning is declined
        this.isSiCutOff = true;
        this.communicationService.setSiCutOff(this.isSiCutOff);
      }

      // Show Late Stack Warning if needed
      if (timeDifferenceLateStack <= SIX_HOURS) {
        const lateStackConfirmed = await this.openWarningDialog(
          'Late Stack Warning',
          'This request is close to the Stack closure and can incur additional costs. By proceeding you acknowledge acceptance of possible late Stack and related charges should they be incurred by GoReefers. Do you wish to proceed?'
        );
        if (!lateStackConfirmed) return; // Exit if Late Stack warning is declined
        this.isLateStack = true;
        this.communicationService.setLateStack(this.isLateStack);
      }

      // Proceed to add booking if all required warnings are confirmed
      this.addBooking();
    } catch (error) {
      console.error('Error handling booking:', error);
    }
  }

  async openWarningDialog(title: string, message: string): Promise<boolean> {
    return this.dialog.open(LateStackWarningComponent, {
      data: { title, message }
    }).afterClosed().toPromise();
  }

  private addBooking() {
    this.bookingTransfer = {
      appBookingCode: null,
      appBookingLineNumber: null,
      scheduleCode: this.scheduleHeaderCode,
      loadPortCode: this.scheduleLoadPortCode,
      dischargePortCode: this.dischargePortCode,
      carrierCode: this.carrierCode,
      bookingType: AddBookingEnum.newBookingSailingSchedule
    };

    this.router.navigate(['/add-booking'], { state: { data: this.bookingTransfer } });
    this.closeRightDrawer();
  }

  isTodayGreaterThanETD(loadETD: string | Date): boolean {
    if (!loadETD) {
      return false;
    }

    const etdDate = new Date(loadETD);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to start of the day

    return etdDate < today;
  }

  public getPermissionTooltip(permission: ClaimAction): { toolTipMessage: string; timeOut: number } {
    return this.commonService.getNoPermissionTooltipMessage(permission);
  }

  closeRightDrawer() {
    this.communicationService.closeRightNav();
  }

  reportSchedule(): void {
    const dialogRef = this.dialog.open(ReportScheduleDialogComponent, {
      maxWidth: '370px',
      height: '300px',
      data: { comment: '' },
    });

    dialogRef.afterClosed().subscribe(
      result => {
      if (result) {
        this.startSpinner();
         this.scheduleIncorrect = {
          message:result.comment,
          carrier:this.schedule.carrier,
          carrierCode:this.schedule.carrierCode,
          dischargeETA:this.schedule.dischargeETA,
          dischargePort:this.schedule.dischargePort,
          dischargePortCode:this.schedule.dischargePortCode,
          lineNumber:this.schedule.lineNumber,
          loadETD:this.schedule.loadETD,
          loadPort:this.schedule.loadPort,
          loadPortCode:this.schedule.loadPortCode,
          marketCode:this.schedule.marketCode,
          portCountry:this.schedule.portCountry,
          portCountryCode:this.schedule.portCountryCode,
          sailingScheduleHeaderCode:this.schedule.sailingScheduleHeaderCode,
          serviceCode:this.schedule.serviceCode,
          serviceName:this.schedule.serviceName,
          siCutoffDateTime:this.schedule.siCutoffDateTime,
          stackEnd:this.schedule.stackEnd,
          stackStart:this.schedule.stackStart,
          stackStatus:this.schedule.stackStatus,
          vesselCode:this.schedule.vesselCode,
          vesselName:this.schedule.vesselName,
          voyageNo:this.schedule.voyageNo,
        };

        this.emailService.reportSailingSchedule(this.scheduleIncorrect).subscribe(
          res => {
            this.stopSpinner();
            const dialogRef = this.dialog.open(InformationComponent, {
              width: '350px',
              height: '350px',
              data: {
                title: 'Success',
                message: 'Your message has been successfully sent and our team will get back to you as soon as possible'
              }
            });
    
            dialogRef.afterClosed().subscribe(() => {
            });
          },
          error => {
            this.stopSpinner();
            const dialogRef = this.dialog.open(InformationComponent, {
              width: '350px',
              height: '350px',
              data: {
                title: 'Error',
                message: 'There was an error sending your message. Please try again later.' 
              }
            });
          }
        );


      }
    });

  }

  startSpinner(): void{
    this.isSpinnerVisible = true;
    this.isDisabled = true;
    const spinnerOverlay = document.querySelector('.spinner-overlay') as HTMLElement;

    if (spinnerOverlay) {
      spinnerOverlay.style.display = 'flex';
    }
  }

  stopSpinner(): void {
    this.isSpinnerVisible = false;
    this.isDisabled = false;

    
    const spinnerOverlay = document.querySelector('.spinner-overlay') as HTMLElement;

    if (spinnerOverlay) {
      spinnerOverlay.style.display = 'none'; 
    }
  }
}
