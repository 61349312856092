import { Component, ElementRef, inject, input, Input, ViewChild } from '@angular/core';
import { Alert } from '../models/alerts/alerts';
import { AddBookingEnum, AddBookingModel } from '../models/booking-data-model';
import { Router } from '@angular/router';
import { BookingDataTransferModel } from '../models/booking-data-model';
import { CommunicationService } from '../service/communication/communication.service';
import { BookingLineModel } from '../models/bookings/booking-line-model';
import { BookingService } from '../service/booking/booking.service';
import { LateStackWarningComponent } from '../dialog/late-stack-warning/late-stack-warning.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BookingEditModel } from '../models/bookings/booking-edit-model';
import { BookingHeaderModel } from '../models/bookings/booking-header-model';
import { AuthService } from '../service/auth-service/auth-service.service';
import { ClaimAction } from '../enums/keycloak-claim-action';
import { CommonService } from '../service/common/common.service';


@Component({
  selector: 'app-booking-line',
  templateUrl: './booking-line.component.html',
  styleUrl: './booking-line.component.css'
})
export class BookingLineComponent {
  @Input() bookingLineData: BookingLineModel[] = [];
  @Input() stackEnd: Date = new Date();
  @Input() bookingHeader: BookingHeaderModel = new BookingHeaderModel();
  bookingTransfer: BookingDataTransferModel = new BookingDataTransferModel();
  today = new Date();
  cancelBookingModel = new AddBookingModel();
  bookingEdit: BookingEditModel = new BookingEditModel();
  ClaimAction = ClaimAction; // Expose the enum to the template

  constructor(
    private router: Router,
    private communicationService: CommunicationService,
    private bookingService: BookingService,
    public authService: AuthService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) { }

  private _snackBar = inject(MatSnackBar);
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private openSnackBar(message: string, success: boolean = true) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: success ? ['snackbar-success'] : ['snackbar-fail']
    });
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getExtractedColumns(columns: string[][]) {
    return columns.map(col => col[0]);
  }

  public editBookingLine(lineNo: number): void {

    if (!this.authService.hasClaim(ClaimAction.Root2Go_AddBookings)) {
      return;
    }

    var bookingLine = this.getBookingLine(lineNo);

    this.bookingTransfer = {
      appBookingCode: bookingLine.appBookingCode,
      appBookingLineNumber: bookingLine.appBookingLineNumber == 0 ? bookingLine.lineNo : bookingLine.appBookingLineNumber,
      scheduleCode: null,
      loadPortCode: null,
      dischargePortCode: null,
      carrierCode: null,
      bookingType: AddBookingEnum.editBookingLine
    };

    this.communicationService.closeRightNav();
    this.router.navigate(['/add-booking'], { state: { data: this.bookingTransfer } });
  }

  public viewBookingLine(lineNo: number): void {

    var bookingLine = this.getBookingLine(lineNo);

    this.bookingTransfer = {
      appBookingCode: bookingLine.appBookingCode,
      appBookingLineNumber: bookingLine.appBookingLineNumber == 0 ? bookingLine.lineNo : bookingLine.appBookingLineNumber,
      scheduleCode: null,
      loadPortCode: null,
      dischargePortCode: null,
      carrierCode: null,
      bookingType: AddBookingEnum.viewBookingLine
    };

    this.communicationService.closeRightNav();
    this.router.navigate(['/add-booking'], { state: { data: this.bookingTransfer } });
  }

  public cancelBookingLine(lineNo: number, index: number): void {

    if (!this.authService.hasClaim(ClaimAction.Root2Go_AddBookings)) {
      return;
    }

    var bookingLine = this.getBookingLine(lineNo);
    
    this.bookingService.getBookingEditModel(
      bookingLine.appBookingCode,
      bookingLine.appBookingLineNumber,
      '',
      this.bookingHeader.loadPortCode,
      bookingLine.dischargePortCode,
      this.bookingHeader.carrierCode
    ).subscribe({
      next: (bookingEdit: BookingEditModel) => {
        this.bookingEdit = bookingEdit;

        this.cancelBookingModel = {
          appbookingCode: bookingLine.appBookingCode,
          lineNumber: bookingLine.appBookingLineNumber == 0 ? bookingLine.lineNo : bookingLine.appBookingLineNumber,
          confirmedPackDateTime: bookingLine.confirmedPackDate ? bookingLine.confirmedPackDate.toString() : "",
          comments: this.bookingEdit.comments,
          commodityCode: bookingLine.commodityCode,
          dischargePortCode: bookingLine.dischargePortCode,
          LoadPortCode: this.bookingEdit.loadPortCode,
          quantity: bookingLine.quantity,
          containerTypeCode: this.bookingEdit.containerTypeCode,
          customerCode: this.bookingEdit.customerCode,
          finalDestinationCode: this.bookingEdit.dischargePortCode,
          grReferenceNumber: '',
          loadingPoint1: this.bookingEdit.loadingPoint1,
          loadingPoint2: this.bookingEdit.loadingPoint2,
          loadingPoint3: '',
          prelimConsignee: '',
          preVerificationKey: this.bookingEdit.preVerificationKey,
          ServiceName: '',
          solasMethod: this.bookingEdit.solasMethod,
          stepUp: this.bookingEdit.stepUp,
          steri: this.bookingEdit.sterilized,
          temperatureCode: this.bookingEdit.temperatureCode,
          temptale: this.bookingEdit.temptale,
          temptaleDescription: this.bookingEdit.temptaleDescription,
          temptaleQuantity: this.bookingEdit.temptaleQty,
          vents: this.bookingEdit.ventilationCode,
          VesselName: '',
          VoyageNo: '',
          weight: this.bookingEdit.weight,
        };

        this.dialog.open(LateStackWarningComponent, {
          data: {
            title: 'Warning: Cancelling Booking',
            message: `You're about to cancel Booking Record ${index} for App Booking Code ${bookingLine.appBookingCode}. Do you wish to proceed?`
          }
        }).afterClosed().subscribe(result => {
          if (result) {
            this.bookingService.cancelBooking(this.cancelBookingModel).subscribe(data => {
              if (data.success) {
                // TODO: notify user success
                this.openSnackBar(`${data.appBookingCode}: Cancellation Pending`);
                this.communicationService.closeRightNav();

              }
            });
          }
        });
      },
      error: (error) => {
        console.error('Error fetching booking data:', error);
      }
    });
  }

  public isConfirmedPackDateInFuture(confirmedPackDate: Date): boolean {
    return this.today && confirmedPackDate && new Date(confirmedPackDate) > this.today;
  }

  isEndStackInFuture(stackEnd: Date): boolean {
    return this.today && stackEnd && new Date(stackEnd) > this.today;
  }

  public getPermissionTooltip(permission: ClaimAction): { toolTipMessage: string; timeOut: number } {
    return this.commonService.getNoPermissionTooltipMessage(permission);
  }

  getBookingLine(bookingLineNumber: number): BookingLineModel {
    const editLine: BookingLineModel = this.bookingLineData
      .find(line => line.lineNo === bookingLineNumber) ?? new BookingLineModel();
    return editLine;
  }
}
