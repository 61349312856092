export interface SelectedDocuments {
    fileType: string;
    fileId: string;
    fileExtension: string;
}

export class EmaiLToModel {
    emailTo: string = '';
    shipmentNumber: string ='';
    shipperName: string ='';
    containerNumber: string ='';
    attachments: SelectedDocuments[] = [];
}
