import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnDestroy, inject } from '@angular/core';
import { ShipmentService } from '../service/shipment/shipment.service';
import { FilterData } from '../models/list-model';
import { FormControl, Validators } from '@angular/forms';
import { LookupModel } from '../models/lookup-model';
import { DataFilter } from '../models/filter-models';
import { MatDialog } from '@angular/material/dialog';
import { InformationComponent } from '../dialog/information/information.component';
import { LookupService } from '../service/lookup/lookup.service';
import { Observable, Subject } from 'rxjs';
import { startWith, map, takeUntil } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { CommunicationService } from '../service/communication/communication.service';

@Component({
  selector: 'app-rolling-plan-filter',
  templateUrl: './rolling-plan-filter.component.html',
  styleUrls: ['./rolling-plan-filter.component.css', '../filter-sidenav/filter-sidenav.component.css']
})
export class RollingPlanFilterComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>(); private apiUserService = inject(ApiUserService)
  isSingleCustUser: boolean = false;
  private _snackBar = inject(MatSnackBar);
  latestacks = new FormControl();
  grRefNos = new FormControl('');
  savedFilterName = new FormControl('', [Validators.maxLength(20)]);
  filterData: FilterData = {};
  filterName: string = '';
  datafilter: DataFilter = new DataFilter();
  savedFilters: DataFilter[] = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private communicationService: CommunicationService, private domSanitizer: DomSanitizer, private shipmentDataService: ShipmentService, private lookupService: LookupService, private cdr: ChangeDetectorRef, private dialog: MatDialog, private matIconRegistry: MatIconRegistry) {
    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
  }

  ngOnInit(): void {

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isSingleCustUser = this.apiUserService.IsSingleCustUser;
          }
      });

    this.isSingleCustUser = this.apiUserService.IsSingleCustUser;
    this.loadBookingData();

    this.savedFilterName.statusChanges.subscribe(() => {
      if ((this.savedFilterName.value?.length ?? 0) > 20) {
        this.savedFilterName.markAsTouched();
      }
    });
  }

  loadBookingData(): void {

    this.shipmentDataService.sendActiveFilterToFilterRoad$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.datafilter = data;
          this.cdr.markForCheck();
        },
        error: (error) => console.error('Error fetching Filter data', error)
      });

    if (this.datafilter.id !== 0) {
      this.filterName = this.datafilter.filterName;
      this.savedFilterName.setValue(this.filterName);
    }
  }

  save(): void {
    if (!this.savedFilterName.value) {
      // Optionally, you can show an error message here
      console.error('Filter name cannot be empty');
      return;
    }

    // this.createFilter();
    this.filterName = this.savedFilterName.value.toString();
    this.shipmentDataService.saveShipmentRoadFilter(this.filterName, this.datafilter.filter).subscribe(_ => {
      this.shipmentDataService.filterNameSavedSendRoad(this.filterName);
      this.shipmentDataService.getRoadShipmentFilters().subscribe(result => {
        this.savedFilters = result;
        const savedFil = this.savedFilters.find(f => f.filterName === this.filterName);
        this.datafilter.id = savedFil!.id;
        this.datafilter.filterName = savedFil!.filterName;
        this.datafilter.filter = savedFil!.filter;
        this.closeRightDrawer();
      });
    });
    this.openSnackBar('Your filter ' + this.filterName + ' has been created');
  }

  get isSavedFilterNameEmpty(): boolean {
    return !this.savedFilterName.value;
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  createFilter(): void {
  }

  parseFilterSelections(selections: LookupModel[]): string {
    const codes: string[] = selections.map(selection => selection.code);
    return codes.join(',');
  }

  processFilterData(key: string, value: string | string[]) {
    if (Array.isArray(value)) {
      this.filterData[key] = value.filter(item => item.trim() !== '');
    } else {
      const dataArray = value
        .split(',')
        .filter(item => item.trim() !== '');
      this.filterData[key] = dataArray;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //TODO: Find a way to get this window to close without forcing it closed using CDR
  //Application : When a user clicks on apply filter this filter side nav needs to close
  //Scale: P3 None Critical
  closeRightDrawer() {
    this.communicationService.closeRightNav();
  }
}
