<div class="dv-content-wrap">
  <div class="dv-header-wrap">
    <div class="detail-header">
      <h3>Shipment - {{shipmentData.grReference}}</h3>
    </div>
  </div>

  <div class="dv-summary-list">
    <div class="dv-summary-column">
      <div class="info-item">
        <span class="info-label">Consignee</span>
        <span>{{shipmentData.consignee}}</span>
      </div>

      <div class="info-item">
        <span class="info-label">Load Port</span>
        <span>{{shipmentData.loadPortName}}</span>
      </div>
    </div>

    <div class="dv-summary-column">
      <div class="info-item">
        <span class="info-label">Carrier</span>
        <span>{{shipmentData.carrierName}}</span>
      </div>

      <div class="info-item">
        <span class="info-label">Vessel</span>
        <span>{{shipmentData.vesselName}}</span>
      </div>
    </div>
    <div class="dv-summary-column">
      <div class="info-item">
        <span class="info-label">Discharge Port</span>
        <span>{{shipmentData.destination}}</span>
      </div>
    </div>

  </div>

  <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color" [hideSingleSelectionIndicator]="true"
    class="tab-menu" [(ngModel)]="selectedTab">
    <mat-button-toggle class="tab-menu-item" [value]="detailTabbedViewEnum.trackingTab"
      matTooltip="View container tracking map." matTooltipPosition="above"
      matTooltipClass="custom-mat-tooltip">Container Tracking</mat-button-toggle>
    <mat-button-toggle class="tab-menu-item" [value]="detailTabbedViewEnum.detailsTab"
      matTooltip="View planned shipment events." matTooltipPosition="above"
      matTooltipClass="custom-mat-tooltip">Tracking
      Events</mat-button-toggle>
  </mat-button-toggle-group>

  <div class="tab-content" *ngIf="!isLoading">
    <ng-container *ngIf="selectedTab === detailTabbedViewEnum.trackingTab">
      <div class="status-box">
        <div class="status-item">
          <span class="status-label">Original ETA</span>
          <span>
            <ng-container
              *ngIf="shipmentData.originalETA.toString() === '0001-01-01T00:00:00.000Z' || shipmentData.originalETA.toString() === '0001-01-01T00:00:00.000' ; else showDate1"></ng-container>
            <ng-template #showDate1>
              {{ shipmentData.originalETA | date: 'dd MMM yyyy' }}
            </ng-template>
          </span>
        </div>
        <div class="status-item">
          <span class="status-label">Current ETA</span>
          <span>
            <ng-container
              *ngIf="shipmentData.eta.toString() === '0001-01-01T00:00:00.000Z' || shipmentData.eta.toString() === '0001-01-01T00:00:00.000' ; else showDate2"></ng-container>
            <ng-template #showDate2>
              {{shipmentData.eta | date: 'dd MMM yyyy'}}
            </ng-template>
          </span>
        </div>

        <div class="status-item">
          <span class="status-label">ETA changes (days)</span>
          <span>{{shipmentData.delay }}</span>
        </div>


        <div class="status-item">
          <span class="status-label">Original ETD</span>
          <span>
            <ng-container
              *ngIf="shipmentData.originalETD.toString() === '0001-01-01T00:00:00Z' || shipmentData.originalETD.toString() === '0001-01-01T00:00:00' ; else showDate3"></ng-container>
            <ng-template #showDate3>
              {{shipmentData.originalETD | date: 'dd MMM yyyy'}}
            </ng-template>
          </span>
        </div>

        <div class="status-item">
          <span class="status-label">ATD</span>
          <span>
            <ng-container
              *ngIf="shipmentData.etd.toString() === '0001-01-01T00:00:00Z' || shipmentData.etd.toString() === '0001-01-01T00:00:00'; else showDate4"></ng-container>
            <ng-template #showDate4>
              {{shipmentData.etd | date: 'dd MMM yyyy'}}
            </ng-template>
          </span>
        </div>

        <div class="status-item">
          <span class="status-label">Documentation Status</span>
          <span>{{ shipmentData.activeShipment ? 'Finalized' : 'In Progress' }}</span>
        </div>


      </div><app-container-tracking [routeHeaderData]="routeHeaderData" [actualPathCoordinates]="actualPathCoordinates"
        [vesselDataList]="vesselDataList" [shipmentDataList]="shipmentDataList">
      </app-container-tracking>

      <!-- <app-shipment-tracking-detail [alertData]="alertData"></app-shipment-tracking-detail> -->
    </ng-container>

    <ng-container *ngIf="selectedTab === detailTabbedViewEnum.detailsTab">
      <app-shipment-events *ngIf="canLoad" [canLoad]="canLoad" [events]="events"></app-shipment-events>
    </ng-container>
  </div>

  <ng-container *ngIf="isLoading" class="no-data-container">
    <div class="no-data-container">
      <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
  </ng-container>

</div>
