<div class="container">
  <div class="header-title-wrapper">
    <h1>Schedules</h1>
    <div class="save-wrapper">
      <div class="saved-views">
        <div *ngFor="let savedFilter of savedFilters">
          <button mat-button class="Saved-filter-btn" [ngClass]="{'selected': currentFilter.id === savedFilter.id}"
                  (click)="applyFilter(savedFilter.id)">
            {{ savedFilter.filterName }}
          </button>
        </div>
      </div>
      <div class="action-buttons-wrapper">
        <button mat-button class=" filter-btn" (click)="onOpenFilter(FilterEnum.scheduleFilter)"
                matTooltip="Save your current search." matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
          <div class="filter-icon-wrap">
            <div class="filter-icon"></div>
            Save Filters
          </div>
        </button>
        <button mat-button class="filter-btn" (click)="clearCurrentFilter()" matTooltip="Clear data in input fields."
                matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
          <div class="filter-icon-wrap">
            <div class="filter-clear-icon"></div>
            Reset Filters
          </div>
        </button>
        <button mat-button class="filter-btn-delete" (click)="deleteFilter()" *ngIf="currentFilter.id !== 0"
                matTooltip="Delete the current saved filter view." matTooltipPosition="above"
                matTooltipClass="custom-mat-tooltip">
          <div class="filter-icon-wrap">
            <div class="filter-delete-icon"></div>
            Delete Saved View
          </div>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2-schedules">
      <mat-form-field>
        <mat-label>Enter an ETD date range</mat-label>
        <mat-date-range-input [rangePicker]="picker" required>
          <input matStartDate placeholder="ETD from" [formControl]="dateFrom">
          <input matEndDate placeholder=" ETD to" [formControl]="dateTo">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker" matTooltip="Pick a date from the calendar"
                               matTooltipPosition="above" matTooltipClass="custom-mat-tooltip"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="dateFrom.invalid && dateFrom.touched">Start date is required.</mat-error>
        <mat-error *ngIf="dateTo.invalid && dateTo.touched">End date is required.</mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-2-schedules">
      <mat-form-field *ngIf="stackStatus.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>Stack Status</mat-label>
        <mat-select [formControl]="stackStatus" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="stackStatusMultiFilter" placeholderLabel="Search"
                                   noEntriesFoundLabel="Stack Status Not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{stackStatus.value.length > 0 ? stackStatus.value[0].code : ''}}
            <span *ngIf="stackStatus.value[0]  && stackStatus.value.length > 1" class="example-additional-selection">
              (+{{(stackStatus.value.length || 0) - 1}} {{stackStatus.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(stackStatus)" matTooltip="Clear field input."
                    matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let stack of filteredStackStatus | async" [value]="stack">
            {{stack.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2-schedules">
      <mat-form-field *ngIf="loadPorts.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>Load port</mat-label>
        <mat-select [formControl]="loadPorts" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="loadPortsMultiFilter" placeholderLabel="Search"
                                   noEntriesFoundLabel="Load Port Not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{loadPorts.value.length > 0 ? loadPorts.value[0].code : ''}}
            <span *ngIf="loadPorts.value[0]  && loadPorts.value.length > 1" class="example-additional-selection">
              (+{{(loadPorts.value.length || 0) - 1}} {{loadPorts.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(loadPorts)" matTooltip="Clear field input."
                    matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let loadPort of filteredLoadPorts | async" [value]="loadPort">
            {{loadPort.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2-schedules">
      <mat-form-field *ngIf="countries.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>Destination Country</mat-label>
        <mat-select [formControl]="countries" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="countriesMultiFilter" placeholderLabel="Search"
                                   noEntriesFoundLabel="Country Not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{countries.value.length > 0 ? countries.value[0].code : ''}}
            <span *ngIf="countries.value[0] && countries.value.length > 1" class="example-additional-selection">
              (+{{(countries.value.length || 0) - 1}} {{countries.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(countries)" matTooltip="Clear field input."
                    matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
            {{country.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-2-schedules">
      <mat-form-field *ngIf="dischargePorts.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>Discharge port</mat-label>
        <mat-select [formControl]="dischargePorts" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="dischargePortsMultiFilter" placeholderLabel="Search"
                                   noEntriesFoundLabel="Discharge Port Not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{dischargePorts.value.length > 0 ? dischargePorts.value[0].code : ''}}
            <span *ngIf="dischargePorts.value[0]  && dischargePorts.value.length > 1"
                  class="example-additional-selection">
              (+{{(dischargePorts.value.length || 0) - 1}} {{dischargePorts.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(dischargePorts)" matTooltip="Clear field input."
                    matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let dischargePort of filteredDischargePorts | async" [value]="dischargePort">
            {{dischargePort.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2-schedules">
      <mat-form-field *ngIf="carriers.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
        <mat-label>Carrier</mat-label>
        <mat-select [formControl]="carriers" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="carriersMultiFilter" placeholderLabel="Search"
                                   noEntriesFoundLabel="Carrier Not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{carriers.value.length > 0 ? carriers.value[0].code : ''}}
            <span *ngIf="carriers.value[0]  && carriers.value.length > 1" class="example-additional-selection">
              (+{{(carriers.value.length || 0) - 1}} {{carriers.value.length === 2 ? 'other' : 'others'}})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(carriers)" matTooltip="Clear field input."
                    matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier">
            {{carrier.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-1 search-btn">
      <button pButton pRipple class="runReportButton" (click)="runReportClick()" matTooltip="Search for schedules."
              matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
        Search<mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  </div>

  <p-toast key="key1"></p-toast>
  <p-table #dt
           loadingIcon="pi pi-spinner-dotted pi-spin"
           [loading]="loading"
           styleClass="p-datatable-sm snap-table sticky-element"
           class="snap-table"
           [columns]="selectedBookings"
           [value]="scheduleDataSource.data"
           [paginator]="!isMobile() && !isTablet()"
           [rows]="10"
           [rowHover]="true"
           [rowsPerPageOptions]="[5, 10, 20]"
           [globalFilterFields]="['carrier', 'vesselName', 'voyageNo', 'stackStatus', 'stackStart', 'stackEnd', 'siCutoffDateTime', 'loadPort', 'loadETD', 'dischargePort', 'dischargeETA', 'portCountry', 'serviceName']"
           [exportHeader]="'customExportHeader'"
           [reorderableColumns]="true"
           scrollHeight="600px"
           dataKey="id"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           [showCurrentPageReport]="true"
           responsiveLayout="stack"
           (scroll)="onScroll($event)">
    <ng-template pTemplate="caption">
      <p-panel [toggleable]="false" [collapsed]="panelCollapsed">
        <ng-template pTemplate="header">
          <div style="display:flex; justify-content:space-between">
            <p-iconField iconPosition="left" class="">
              <p-inputIcon styleClass="pi pi-search" />
              <input pInputText
                     type="text"
                     [(ngModel)]="searchValue"
                     (input)="dt.filterGlobal(searchValue, 'contains')"
                     class="p-inputtext p-component-sm"
                     placeholder="Search keyword" />
              <span style="margin-left:-20px;">
                <a (click)="clear(dt)">
                  <i class="fa fa-times" style="color: rgb(149, 149, 153); cursor: pointer;"></i>
                </a>
              </span>
            </p-iconField>

          </div>
        </ng-template>

        <ng-template pTemplate="icons">
          <div style="display:flex; gap:8px; flex-direction:row">
            <button pButton icon="pi pi-external-link"
                    pTooltip="Export schedules to excel"
                    tooltipPosition="top"
                    (click)="exportExcel()"
                    class="table-actions small-button"></button>
            <button pButton
                    pTooltip="Configure table"
                    tooltipPosition="top"
                    class="table-actions mb-2 small-button"
                    [icon]="panelCollapsed ? 'pi pi-cog' : 'pi pi-spin pi-cog'"
                    (click)="handleButtonClick()">
            </button>
          </div>
        </ng-template>
        <div *ngIf="!panelCollapsed" style=" display: flex; justify-content: space-between;" class="flex justify-between justify-content-between flex-column sm:flex-row">
          <p-multiSelect class="w-full md:w-80"
                         styleClass="w-full"
                         display="chip"
                         [options]="bookingColumns"
                         [(ngModel)]="selectedBookings"
                         (onChange)="updateDisplayedColumns()"
                         optionLabel="header"
                         selectedItemsLabel="{0} columns selected"
                         placeholder="Choose Columns" />
          <button pButton pTooltip="Save table" tooltipPosition="bottom" icon="pi pi-save" class="table-save  mb-2" (click)="saveTableConfig($event)"></button>
        </div>
      </p-panel>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr class="counter">
        <th>
          {{ currentRowIndex }} of {{scheduleDataSource.data.length || 0}}
        </th>
      </tr>
      <tr class="headings">
        <th *ngFor="let col of columns"
            pReorderableColumn
            pSortableColumn="{{ col.field }}"
            pTooltip="{{ col.toolTip }}"
            tooltipPosition="top"
            tooltipClass="custom-mat-tooltip"
            [hidden]="!col.visible">
          {{ col.header }}
          <p-sortIcon field="{{ col.field }}"></p-sortIcon>
        </th>
        <th>
          <div *ngIf="columns && columns.length > 0">
            Actions
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-container class="scroll-container">
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData" class="snap-row">
          <td *ngFor="let col of columns" [attr.data-label]="(col.name !== 'actions' && col.name !== 'indicators') ? col.header : null"
              class='has_label_on_mobile' [hidden]="!col.visible">
            <ng-container *ngIf="col.name === 'loadETD'">
              {{ rowData[col.field] | date: 'dd MMM yyyy' }}
            </ng-container>
            <ng-container *ngIf="col.name === 'stackStart'">
              <ng-container *ngIf="rowData[col.name] === '0001-01-01T00:00:00Z' || rowData[col.name] === '0001-01-01T00:00:00'; else showDate">
                <!-- Display a blank if the date is '01 January 0001' -->
              </ng-container>
              <ng-template #showDate>
                {{ rowData[col.field] | date: 'dd MMM yyyy, HH:mm' }} <!-- Display the date normally -->
              </ng-template>
              <!-- {{ rowData[col.field] | date: 'dd MMM yyyy, HH:mm' }} -->
            </ng-container>
            <ng-container *ngIf="col.name === 'stackEnd'">
              <ng-container *ngIf="rowData[col.name] === '0001-01-01T00:00:00Z' || rowData[col.name] === '0001-01-01T00:00:00'; else showDate">
                <!-- Display a blank if the date is '01 January 0001' -->
              </ng-container>
              <ng-template #showDate>
                {{ rowData[col.field] | date: 'dd MMM yyyy, HH:mm' }} <!-- Display the date normally -->
              </ng-template>
            </ng-container>
            <ng-container *ngIf="col.name === 'dischargeETA'">
              {{ rowData[col.name] | date: 'dd MMM yyyy' }}
            </ng-container>
            <ng-container *ngIf="col.name === 'siCutoffDateTime'">
              <ng-container *ngIf="rowData[col.name] === '0001-01-01T00:00:00Z'; else showDate">
                <!-- Display this when siCutoffDateTime is '01 Jan 0001' -->
              </ng-container>
              <ng-template #showDate>
                {{ rowData[col.field] | date: 'dd MMM yyyy, HH:mm' }} <!-- Display the date normally -->
              </ng-template>
            </ng-container>
            <ng-container *ngIf="col.name !== 'loadETD'&& col.name !== 'stackEnd' && col.name !== 'stackStart' && col.name !== 'siCutoffDateTime'&& col.name !== 'dischargeETA'">
              {{ rowData[col.field] | truncateText }}
            </ng-container>
          </td>
          <td>
            <ng-container>
              <div class="action-buttons-wrapper">
                <button *ngIf="!isConsigneeUser" class="add-booking-btn"
                        [disabled]="!authService.hasClaim(ClaimAction.Root2Go_AddBookings) || isTodayGreaterThanETD(rowData['loadETD'])"
                        [matTooltip]="
                      !authService.hasClaim(ClaimAction.Root2Go_AddBookings)
                      ? getPermissionTooltip(ClaimAction.Root2Go_AddBookings).toolTipMessage
                      : isTodayGreaterThanETD(rowData['loadETD'])
                      ? 'Can not add booking: Vessel departed.'
                      : 'Create a Booking for this schedule.'
                      "
                        matTooltipPosition="above" matTooltipClass="custom-mat-tooltip"
                        [ngClass]="{'loading-cell': loadingSearch}"
                        (click)="handleNewBooking(rowData['stackEnd'], rowData['loadETD'], rowData['siCutoffDateTime'], rowData['sailingScheduleHeaderCode'], rowData['loadPortCode'], rowData['dischargePortCode'], rowData['carrierCode'])">
                  <mat-icon svgIcon="plusCircle"></mat-icon>
                </button>
                <button class="detailed-view-btn" matTooltip="View details and add bookings."
                        matTooltipPosition="above" matTooltipClass="custom-mat-tooltip"
                        [ngClass]="{'loading-cell': loadingSearch}"
                        (click)="viewDetails(rowData,detailView.scheduleDetail,detailTabbedViewEnum.detailsTab)">
                  <mat-icon svgIcon="blueArrow"></mat-icon>
                </button>
              </div>
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </ng-container>

      <ng-template pTemplate="loadingbody" let-columns>
        <tr *ngFor="let _ of [0, 1, 2, 3, 4]">
          <td class="status-indicator">
            <p-skeleton shape="circle" size="1.5rem"></p-skeleton>
            <p-skeleton></p-skeleton>
          </td>
          <td *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </td>
          <td class="status-indicator">
            <p-skeleton></p-skeleton>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns *ngIf="!isLoading && scheduleDataSource.filteredData.length === 0">
        <tr>
          <td [attr.colspan]="columns.length + 1" class="message-container centered-text" *ngIf="checkNoFilter()">
            No data available for selected filters. Please review filters and search
            again.
          </td>
        </tr>
        <tr>
          <td [attr.colspan]="columns.length + 1" class="message-container centered-text" style="text-align: center; width: -webkit-fill-available;" *ngIf="scheduleDataSource.data.length === 0 && !checkNoFilter() && !loading">
            Please apply filters to see
            results.
          </td>
        </tr>
      </ng-template>
  </p-table>

  <ng-template #customContent>
    <div class="btm-panel-container">
      <div *ngFor="let category of bookingColumns" class="flex items-center">
        <p-checkbox [inputId]="category.name" name="group" [value]="category" [(ngModel)]="selectedBookings" />
        <label [for]="category.name" class="ml-2"> {{ category.header }} </label>
      </div>
    </div>
    <div class="flex gap-3 mt-1">
      <button pButton label="Save" class="w-full table-save" (click)="saveTableConfig($event)"></button>
    </div>
  </ng-template>
  <div class="paragraph-wrap">
    <h4>Disclaimer</h4>
    <p class="disclaimer-text">
      You use information provided in our Sailing Schedules at your own risk. We do not warrant its accuracy and
      completeness or that it is up-to-date, free from errors or omissions. Any data which is an estimate by its nature
      (ETDs, ETAs etc.) is not and cannot be guaranteed as accurate. Additionally the data provided includes and is
      based upon data received from third parties (including Shipping Lines) and is beyond our control. and cannot be
      verified. Accordingly any reliance placed upon any part of the sailing schedule is at your own risk. Please see <a href="https://www.goglobal.group/terms-and-conditions/">GoGlobal's Terms & Condtions</a> for our full policy on
      information provided and use of our reports and platforms.
    </p>
  </div>
</div>
