import { Component, Input, OnInit } from '@angular/core';
import { DocumentService } from '../service/documents/document.service';
import { IDocument, Document } from '../models/documents/document';
import { CommunicationService } from '../service/communication/communication.service';
import { ColumnSetup } from '../models/column-setup'
import { MatDialog } from '@angular/material/dialog';
import { DocumentRejectComponent } from '../dialog/document-reject/document-reject.component'
import { InformationComponent } from '../dialog/information/information.component';
import { catchError } from 'rxjs/operators';
import { of, Subject, takeUntil } from 'rxjs';
import { saveAs } from 'file-saver';
import { FilterEnum } from '../models/filter-enum';
import { ApiUserService } from '../service/user/api-user.service';

@Component({
  selector: 'app-document-management',
  templateUrl: './document-management.component.html',
  styleUrl: './document-management.component.css'
})

export class DocumentManagementComponent implements OnInit {
  dataSourceShipmentingDocs: IDocument[] = [];
  dataSourcePPECBDocs: IDocument[] = [];
  documentList: IDocument[] = [];

  @Input() grRefNo: string = '';
  @Input() containerNo: string = '';
  private documentParameter: string = '';
  private addendumFileNamePattern = new RegExp('^[A-Z]{3}U\d{7}$');
  private destroy$ = new Subject<void>();
  isConsigneeUser: boolean = false;

  displayedColumns: ColumnSetup[] = [
    { name: 'documentTypeDocument', header: 'Document Type', type: 'string', visible: true },
    { name: 'documentApprovalStatus', header: 'Approval Status', type: 'string', visible: !this.isConsigneeUser },
    { name: 'documentReasonCode', header: 'Reason Code', type: 'string', visible: false },
    { name: 'documentReasonDetail', header: 'Reason Detail', type: 'string', visible: !this.isConsigneeUser },
    { name: 'actions', header: '', type: 'button', visible: true },
  ];

  displayedColumnsWaiting: ColumnSetup[] = [
    { name: 'documentTypeDocument', header: 'Document Type', type: 'string', visible: true },
    { name: 'attachmentFilename', header: 'Document Name', type: 'string', visible: true },
    { name: 'documentApprovalStatus', header: 'Approval Status', type: 'string', visible: !this.isConsigneeUser },
    { name: 'documentReasonCode', header: 'Reason Code', type: 'string', visible: false },
    { name: 'documentReasonDetail', header: 'Reason Detail', type: 'string', visible: !this.isConsigneeUser },
    { name: 'actions', header: '', type: 'button', visible: true },
  ];

  constructor(
    private documentService: DocumentService,
    private communicationService: CommunicationService,
    private dialog: MatDialog,
    private apiUserService: ApiUserService,
  ) { }

  ngOnInit(): void {
    this.communicationService.detailViewParam.subscribe(param => { this.documentParameter = param });
    this.documentParameter = this.grRefNo;

    this.getDocuments(this.documentParameter);

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isConsigneeUser = this.apiUserService.IsConsigneeUser;
          }
      });
    this.isConsigneeUser = this.apiUserService.IsConsigneeUser;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDocuments(documentParam: string): void {
    this.documentService.getDocumentsForGRRef(documentParam, this.containerNo).subscribe(data => {
      this.documentList = data;
      this.dataSourceShipmentingDocs = this.documentList.filter(status => status.documentTypeDocument != 'PPECB Addendum' && status.documentTypeDocument !== 'PPECB Certificate')
      this.dataSourcePPECBDocs = this.documentList.filter(status => status.documentTypeDocument === 'PPECB Addendum' || status.documentTypeDocument === 'PPECB Certificate')
    })
  }

  rejectDocument(row: any): void {
    const dialogRef = this.dialog.open(DocumentRejectComponent, {
      width: '300px',
      height: '350px',
      data: { documentApprovalStatus: 'Rejected', documentReasonCode: '', documentReasonDetail: '' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const rejectionData = {
          documentApprovalStatus: 'Rejected',
          documentReasonCode: result.reasonCode,
          documentReasonDetail: result.reasonDetail,
        };
        this.documentService.updateDocumentStatus(row['id'], JSON.stringify(rejectionData))
          .pipe(
            catchError(error => {
              this.showInformation('Document rejection Failed', error);
              return of(null);
            })
          )
          .subscribe(response => {
            if (response) {
              this.showInformation('Document Status was updated', '')
              this.documentService.getDocumentsForGRRef(row['grReference'], this.containerNo).subscribe(data => {
                this.documentList = data;
                this.dataSourceShipmentingDocs = this.documentList.filter(status => status.documentApprovalStatus === 'Approved')
                this.dataSourcePPECBDocs = this.documentList.filter(status => status.documentApprovalStatus === 'AwaitingApproval')
              })
            }
          });
      }
    });
    this.documentService.updateDocumentList(this.documentList); // Update the shared state
  }

  downloadDocument(row: any): void {
    this.documentService.downloadDocumentWithSystemId(row['documentAttachmentSystemId'])
      .pipe(
        catchError(error => {
          console.error('Error updating document status:', error);
          this.showInformation('Download Failed', error);
          return of(null); // Return a fallback value or an empty observable
        })
      )

      .subscribe(blob => {
        if (blob) {
          saveAs(blob, row['attachmentFilename'] + '.' + row['attachmentType']);
        }
      })

  }

  approveDocument(row: any): void {
    const approved = {
      documentApprovalStatus: 'Approved'
    };

    this.documentService.updateDocumentStatus(row['id'], JSON.stringify(approved))
      .pipe(
        catchError(error => {
          console.error('Error updating document status:', error);
          this.showInformation('Document Approval Failed', error);
          return of(null); // Return a fallback value or an empty observable
        })
      )
      .subscribe(response => {
        if (response) {
          this.showInformation('Document Status updated to Approved', '')
          this.documentService.getDocumentsForGRRef(row['grReference'], this.containerNo).subscribe(data => {
            this.documentList = data;
            this.dataSourceShipmentingDocs = this.documentList.filter(status => status.documentApprovalStatus === 'Approved')
            this.dataSourcePPECBDocs = this.documentList.filter(status => status.documentApprovalStatus === 'AwaitingApproval')

          })
          this.documentService.updateDocumentList(this.documentList); // Notify subscribers

        }
      });
  }

  showDetail(row: any): void {
    alert(row['documentReasonDetail']);
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getExtractedColumns(columns: ColumnSetup[]) {
    return columns
      .filter(col => col.visible)
      .map(col => col.name);
  }

  showInformation(result: any, message: string | null): void {
    const dialogRef = this.dialog.open(InformationComponent, {
      width: '300px',
      height: '250px',
      data: { resultStatus: result, showMessage: message },
    });
  }

  showError(message: string): void {
    console.error(message);
    this.showInformation('Document rejection Failed', message)
  }

  onOpenFilter(openFiler: FilterEnum) {
    this.communicationService.toggleFilter(openFiler);
  }

  getFileName(fileName: string) {
    return this.addendumFileNamePattern.test(fileName) ? fileName : 'File name not specified.';
  }

  getvisibleColumns(): ColumnSetup[] {
    return this.displayedColumns.map(column => {
      if (column.name === 'documentApprovalStatus' || column.name === 'documentReasonDetail') {
        return { ...column, visible: !this.isConsigneeUser };
      }
      return column;
    }).filter(column => column.visible);
  }

  getvisiblePPECBColumns(): ColumnSetup[] {
    return this.displayedColumnsWaiting.map(column => {
      if (column.name === 'documentApprovalStatus' || column.name === 'documentReasonDetail') {
        return { ...column, visible: !this.isConsigneeUser };
      }
      return column;
    }).filter(column => column.visible);
  }
}


