import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { ClaimAction } from '../enums/keycloak-claim-action'
import { AuthService } from '../service/auth-service/auth-service.service';

/**
 * This guard return true if the user is logged in
 * This guard can be further modified to :
 * * * check user roles using keycloakService.isUserInRole() function
 */
export const AuthGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const keycloakService = inject(KeycloakService);
  const router = inject(Router);

  const roles = keycloakService.getUserRoles()

  const requiredRoles = route.data['roles'] as ClaimAction[]; // Get roles from route data

  const isLoggedIn = keycloakService.isLoggedIn();

  let hasRequiredRole = false;

  if (requiredRoles) {
    hasRequiredRole = requiredRoles.some(role => keycloakService.isUserInRole(role));
  }
  else {
    hasRequiredRole = true;
  }  

  if (isLoggedIn && hasRequiredRole) {
    return true; // Allow access
  } else {
    // Redirect if not authorized
    if (authService.hasClaim(ClaimAction.Root2Go_ViewDashboard)) {
      router.navigate(['/dashboard']);
    } else {
      router.navigate(['/shipments']);
    }
    return false; // Prevent access
  }

};
