import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LookupModel } from '../models/lookup-model';
import { LookupService } from '../service/lookup/lookup.service';
import { Observable, Subject, map, startWith, takeUntil } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiUserService } from '../service/user/api-user.service';
import { DataFilter } from '../models/filter-models';
import { FormControl } from '@angular/forms';
import { FilterData } from '../models/list-model';
import { FilterEnum } from '../models/filter-enum';
import { MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';

@Component({
  selector: 'app-header-customer-filter',
  templateUrl: './header-customer-filter.component.html',
  styleUrl: './header-customer-filter.component.css'
})
export class HeaderCustomerFilterComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  isSingleCustUser: boolean = false;
  isConsigneeUser: boolean = false;
  public customersToShow = new FormControl<LookupModel[]>([]);
  public sortedCustomers: LookupModel[] = [];
  public customersSelected: LookupModel[] = [];
  datafilter: DataFilter = new DataFilter();


  customers = new FormControl<LookupModel[]>([]);
  customersMultiFilter = new FormControl<string>('');
  filteredCustomers!: Observable<LookupModel[]>;


  customersData: LookupModel[] = [];
  shipmentCustomer: LookupModel[] = [];
  cd: LookupModel[] = [];


  filterData: FilterData = {};
  FilterEnum = FilterEnum;
  savedFilters: DataFilter[] = [];
  selectedFilterId: number = 0;
  SavedFilterName: string = '';
  currentFilter: DataFilter = new DataFilter();

  constructor(
    private lookupService: LookupService,
    private cdr: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private apiUserService: ApiUserService,
    private messageService: MessageService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'CaretDown',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/CaretDown.svg'));
  }


  ngOnInit(): void {
    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isSingleCustUser = this.apiUserService.IsSingleCustUser;
            this.isConsigneeUser = this.apiUserService.IsConsigneeUser;
          }
      });

    this.isSingleCustUser = this.apiUserService.IsSingleCustUser;
    this.isConsigneeUser = this.apiUserService.IsConsigneeUser;

    this.lookupService.getCustomerFilter()
      .pipe(takeUntil(this.destroy$)).subscribe({
        next: (data) => {
          this.datafilter = data;
          this.cdr.markForCheck();
          

          this.lookupService.getAllCustomers(this.datafilter.filter)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: (data) => {
                this.customersData = data;
                this.customers.setValue(this.customersData.filter(c => c.isSelected));
                this.filteredCustomers = this.customersMultiFilter.valueChanges.pipe(
                  startWith(''),
                  map(searchText => this.filterCustomers(searchText as string ?? ''))
                );
                this.cdr.markForCheck();
                this.sortOptions();
              },
              error: (error) => console.error('Error fetching customer data', error)
            });

        },
        error: (error) => console.error('Error fetching customer data', error)
      });

   
  }

  sortOptions() {
    this.customersData = [...this.customersData].sort((a, b) => {
      const aSelected = this.customersSelected.some(c => c.code === a.code);
      const bSelected = this.customersSelected.some(c => c.code === b.code);
      return (aSelected === bSelected) ? 0 : aSelected ? -1 : 1;
    });
  }

  private filterCustomers(searchText: string): LookupModel[] {
    if (!searchText) {
      return this.customersData;
    }
    return this.customersData.filter(customer =>
      customer.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  processFilterData(key: string, value: string | string[]) {
    if (Array.isArray(value)) {
      this.filterData[key] = value.filter(item => item.trim() !== '');
    } else {
      const dataArray = value
        .split(',')
        .filter(item => item.trim() !== '');
      this.filterData[key] = dataArray;
    }
  }

  clearSavedCustomerSelection(){
    this.customers.setValue([]);
  }

  saveCustomerSelection()
  {
    this.filterData = {};
    if (this.customers.value != null) {
      const customerCodes = this.parseFilterSelections(this.customers.value);
      this.processFilterData('customerCode', customerCodes);
    }
    this.lookupService.saveMainCustomerFilter(this.filterData)
    .subscribe({
        next: () => {
            window.location.reload();
        },
        error: (error) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to save customer selection.' });
        }
    });
  }

  updateCurrentFilter(id: number, name: string, filter: FilterData) {
    this.currentFilter.id = id;
    this.currentFilter.filterName = name;
    this.currentFilter.filter = filter;

    if (filter['customerCode'] != null) {
      const customerFilter = filter['customerCode'] ?? [];
      this.customers.setValue(this.customersData.filter(status => customerFilter.includes(status.code)))
    }
  }

  parseFilterSelections(selections: LookupModel[]): string {
    const codes: string[] = selections.map(selection => selection.code);
    return codes.join(',');
  }

  createFilter() {
    this.filterData = {};
    if (this.customers.value != null) {
      const customerCodes = this.parseFilterSelections(this.customers.value);
      this.processFilterData('customerCode', customerCodes);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
