<div class="app-wrappper">
  <app-header (menuOpenerClicked)="onMenuOpenerClick()" (openAlerts)="onOpenAlerts()"></app-header>
  <app-sidenav></app-sidenav>
  <app-footer></app-footer>
  <div *ngIf="isLoading" class="loading-spinner-overlay">
    <div class="loading-spinner"></div>
  </div>
  <app-bottom-panel [(visible)]="isPanelVisible" [header]="panelHeader" (onClose)="closePanel()">
    <ng-container *ngTemplateOutlet="panelContent"></ng-container>
  </app-bottom-panel>
</div>
