export interface IRouteInformation {
  locations: IRouteLocation[];
  path: IPathPoint[];
  routeEvents: IRouteEvents[];
}

export class RouteInformation implements IRouteInformation {
  locations: RouteLocation[] = [];
  path: PathPoint[] = [];
  routeEvents: RouteEvents[] = [];
}

export interface IRouteLocation {
  locationCode: string;
  name: string;
  countryCode: string;
  country: string;
  latitude?: number;
  longitude?: number;
}

export class RouteLocation implements IRouteLocation {
  locationCode: string = '';
  name: string = '';
  countryCode: string = '';
  country: string = '';
  latitude?: number;
  longitude?: number;
}

export interface IPathPoint {
  order: number;
  latitude?: number;
  longitude?: number;
}

export class PathPoint implements IPathPoint {
  order: number = 0;
  latitude?: number;
  longitude?: number;
}

export interface IRouteEvents {
  orderId: number;
  location: string;
  description: string;
  eventType: string;
  date: Date;
  type: string;
  actual?: boolean;
}

export class RouteEvents implements IRouteEvents {
  orderId: number = 0;
  location: string = '';
  description: string = '';
  eventType: string = '';
  date: Date = new Date;
  type: string = '';
  actual?: boolean | undefined;
}
