import { Injectable } from '@angular/core';
import { Observable, catchError, of, throwError } from 'rxjs';
import { FilterData } from '../../models/list-model';
import { LookupModel } from '../../models/lookup-model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DataFilter } from '../../models/filter-models';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient,) { }
  
  getLookup(url:string,body:{},filterData?: FilterData, ) {
    if (filterData) {
      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        body = '?filter=' + JSON.stringify(filteredData);
        url = url + body;
      }
    }

    let result = this.http.get<LookupModel[]>(url)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
    return result;
  }

  getCustomers(filterData?: FilterData): Observable<LookupModel[]> {
    let url = this.apiUrl + '/Lookup/GetCustomers';
    let body = {};
    if (filterData) {
      return this.getLookup(url, body, filterData);
    } else {
      let result = this.http.get<LookupModel[]>(url)
        .pipe(
          catchError(error => {
            return throwError(() => error);
          })
        );
      return result;
    }
  }

  getAllCustomers(filterData?: FilterData): Observable<LookupModel[]> {
    let url = this.apiUrl + '/Lookup/GetCustomersAll';
    let body = {};
    if (filterData) {
      return this.getLookup(url, body, filterData);
    } else {
      let result = this.http.get<LookupModel[]>(url)
        .pipe(
          catchError(error => {
            return throwError(() => error);
          })
        );
      return result;
    }
  }

  getSelectedCustomersSelected(filterData?: FilterData): Observable<LookupModel[]> {
    let url = this.apiUrl + '/Lookup/GetCustomersAll';
    let body = {};
    if (filterData) {
      return this.getLookup(url, body, filterData);
    } else {
      let result = this.http.get<LookupModel[]>(url)
        .pipe(
          catchError(error => {
            return throwError(() => error);
          })
        );
      return result;
    }
  }

  getCustomerFilter(): Observable<DataFilter> {
    const url = this.apiUrl + '/Lookup/GetCustomersFilter';
    return this.http.get<DataFilter>(url);
  }

  getLoadPorts( filterData?: FilterData): Observable<LookupModel[]>{
    let url = this.apiUrl + '/Lookup/GetLoadPorts';  
    let body = {};
    if (filterData) {
      return this.getLookup(url,body,filterData);
    }else{
      let result = this.http.get<LookupModel[]>(url)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
    return result;
    }
  }

  getDischargePorts( filterData?: FilterData): Observable<LookupModel[]>{
    let url = this.apiUrl + '/Lookup/GetDischargePorts';  
    let body = {};
    if (filterData) {
      return this.getLookup(url,body,filterData);
    }else{
      let result = this.http.get<LookupModel[]>(url)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
    return result;
    }
  }

  getScheduleDischargePorts(scheduleHeaderCode: string, filterData?: FilterData): Observable<LookupModel[]> {
    let url = this.apiUrl + '/Lookup/GetScheduleDischargePorts?filter=' + scheduleHeaderCode;
    let body = {};
    if (filterData) {
      return this.getLookup(url, body, filterData);
    } else {
      let result = this.http.get<LookupModel[]>(url)
        .pipe(
          catchError(error => {
            return throwError(() => error);
          })
        );
      return result;
    }
  }

  getFinalDestinations(dischargePort: string, filterData?: FilterData): Observable<LookupModel[]>{
    let url = this.apiUrl + '/Lookup/GetFinalDestinations?filter=' + dischargePort;  
    let body = {};
    if (filterData) {
      return this.getLookup(url,body,filterData);
    }else{
      let result = this.http.get<LookupModel[]>(url)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
    return result;
    }
  }

  getCarriers(filterData?: FilterData): Observable<LookupModel[]>{
    let url = this.apiUrl + '/Lookup/GetCarriers'; 
    
    let body = {};

    if (filterData) {
      return this.getLookup(url,body,filterData);
    }else{
      let result = this.http.get<LookupModel[]>(url)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
    return result;
    }
  }

  getMarkets(filterData?: FilterData): Observable<LookupModel[]>{
    let url = this.apiUrl + '/Lookup/GetMarkets';  
    
    let body = {};

    if (filterData) {
      return this.getLookup(url,body,filterData);
    }else{
      let result = this.http.get<LookupModel[]>(url)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
    return result;
    }
  }

  getVesselNames(filterData?: FilterData): Observable<LookupModel[]>{
    let url = this.apiUrl + '/Lookup/GetVesselNames';  
    
    let body = {};

    if (filterData) {
      return this.getLookup(url,body,filterData);
    }else{
      let result = this.http.get<LookupModel[]>(url)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
    return result;
    }
  }

  getDocumentTypes(filterData?: FilterData): Observable<LookupModel[]> {
    let url = this.apiUrl + '/Lookup/GetDocumentTypes';

    let body = {};

    if (filterData) {
      return this.getLookup(url, body, filterData);
    } else {
      let result = this.http.get<LookupModel[]>(url)
        .pipe(
          catchError(error => {
            return throwError(() => error);
          })
        );
      return result;
    }
  }

  getConsignees(filterData?: FilterData): Observable<LookupModel[]>{
    let url = this.apiUrl + '/Lookup/GetConsignees';  
    
    let body = {};

    if (filterData) {
      return this.getLookup(url,body,filterData);
    }else{
      let result = this.http.get<LookupModel[]>(url)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
    return result;
    }
  }

  getModesOfTransport(filterData?: FilterData): Observable<LookupModel[]>{
    let url = this.apiUrl + '/Lookup/GetModesOfTransport';  
    
    let body = {};

    if (filterData) {
      return this.getLookup(url,body,filterData);
    }else{
      let result = this.http.get<LookupModel[]>(url)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
    return result;
    }
  }

  getLoadpoints(filterData?: FilterData): Observable<LookupModel[]>{
    let url = this.apiUrl + '/Lookup/GetLoadingPoints';  
    
    let body = {};

    if (filterData) {
      return this.getLookup(url,body,filterData);
    }else{
      let result = this.http.get<LookupModel[]>(url)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
    return result;
    }
  }

  getContainerTypes(filterData?: FilterData): Observable<LookupModel[]> {
    let url = this.apiUrl + '/Lookup/GetContainerTypes';

    let body = {};

    if (filterData) {
      return this.getLookup(url, body, filterData);
    } else {
      let result = this.http.get<LookupModel[]>(url)
        .pipe(
          catchError(error => {
            return throwError(() => error);
          })
        );
      return result;
    }
  }

  getSolasMethods(filterData?: FilterData): Observable<LookupModel[]> {
    let url = this.apiUrl + '/Lookup/GetSolasMethods';

    let body = {};

    if (filterData) {
      return this.getLookup(url, body, filterData);
    } else {
      let result = this.http.get<LookupModel[]>(url)
        .pipe(
          catchError(error => {
            return throwError(() => error);
          })
        );
      return result;
    }
  }

  getCommodities(filterData?: FilterData): Observable<LookupModel[]> {
    let url = this.apiUrl + '/Lookup/GetCommodities';

    let body = {};

    if (filterData) {
      return this.getLookup(url, body, filterData);
    } else {
      let result = this.http.get<LookupModel[]>(url)
        .pipe(
          catchError(error => {
            return throwError(() => error);
          })
        );
      return result;
    }
  }

  getTemperatures(filterData?: FilterData): Observable<LookupModel[]> {
    let url = this.apiUrl + '/Lookup/GetTemperatures';

    let body = {};

    if (filterData) {
      return this.getLookup(url, body, filterData);
    } else {
      let result = this.http.get<LookupModel[]>(url)
        .pipe(
          catchError(error => {
            return throwError(() => error);
          })
        );
      return result;
    }
  }

  getCountries(filterData?: FilterData): Observable<LookupModel[]> {
    let url = this.apiUrl + '/Lookup/GetCountries';

    let body = {};

    if (filterData) {
      return this.getLookup(url, body, filterData);
    } else {
      let result = this.http.get<LookupModel[]>(url)
        .pipe(
          catchError(error => {
            return throwError(() => error);
          })
        );
      return result;
    }
  }

  getVents(filterData?: FilterData): Observable<LookupModel[]> {
    let url = this.apiUrl + '/Lookup/GetVents';

    let body = {};

    if (filterData) {
      return this.getLookup(url, body, filterData);
    } else {
      let result = this.http.get<LookupModel[]>(url)
        .pipe(
          catchError(error => {
            return throwError(() => error);
          })
        );
      return result;
    }
  }

  getAlertTemplateGroups(filterData?: FilterData): Observable<LookupModel[]> {
    let url = this.apiUrl + '/Lookup/GetAlertTemplates';

    let body = {};

    if (filterData) {
      return this.getLookup(url, body, filterData);
    } else {
      let result = this.http.get<LookupModel[]>(url)
        .pipe(
          catchError(error => {
            return throwError(() => error);
          })
        );
      return result;
    }
  }

  getAlertGroups(filterData?: FilterData): Observable<LookupModel[]> {
    let url = this.apiUrl + '/Lookup/GetAlertGroups';

    let body = {};

    if (filterData) {
      return this.getLookup(url, body, filterData);
    } else {
      let result = this.http.get<LookupModel[]>(url)
        .pipe(
          catchError(error => {
            return throwError(() => error);
          })
        );
      return result;
    }
  }

  getTemptaleDescriptions(filterData?: FilterData): Observable<LookupModel[]> {
    let url = this.apiUrl + '/Lookup/GetTemptaleDescriptions';

    let body = {};

    if (filterData) {
      return this.getLookup(url, body, filterData);
    } else {
      let result = this.http.get<LookupModel[]>(url)
        .pipe(
          catchError(error => {
            return throwError(() => error);
          })
        );
      return result;
    }
  }

  getSelectedCustomersFilter(): Observable<DataFilter> {
    const url = this.apiUrl + '/Lookup/GetCustomerFilter';
    return this.http.get<DataFilter>(url);
  }

  saveMainCustomerFilter(filterData?: FilterData): Observable<any> {
    const url = this.apiUrl + '/Lookup/SaveCustomerFilter';
    let body = {};

    if (filterData) {
      const filteredData = Object.entries(filterData).reduce((acc, [key, value]) => {
        if (value && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      }, {} as FilterData);

      if (Object.keys(filteredData).length > 0) {
        body = JSON.stringify(filteredData);
      }
    }

    return this.http.post<string>(url, body, { headers: { 'Content-Type': 'application/json' } })
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

}
