import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface PanelState {
  visible: boolean;
  header: string;
  content: TemplateRef<any> | null;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalPanelService {
  private panelState = new BehaviorSubject<PanelState>({
    visible: false,
    header: '',
    content: null,
  });

  panelState$ = this.panelState.asObservable();

  openPanel(header: string, content: TemplateRef<any>): void {
    this.panelState.next({ visible: true, header, content });
  }

  closePanel(): void {
    this.panelState.next({ visible: false, header: '', content: null });
  }
}
