import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-bottom-panel',
  templateUrl: './bottom-panel.component.html',
  styleUrls: ['./bottom-panel.component.css']
})
export class BottomPanelComponent {
  @Input() visible: boolean = false; // For the current visibility state
  @Output() visibleChange = new EventEmitter<boolean>(); // For two-way binding

  @Input() header: string = ''; // Optional panel header
  @Output() onClose = new EventEmitter<void>(); // Emits when panel is closed

  closePanel(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible); // Notify parent about visibility change
    this.onClose.emit(); // Notify parent that the panel is closed
  }

  handleOutsideClick(): void {
    this.closePanel(); // Close the panel on clicking outside
  }
}
