import { Component, EventEmitter, OnInit, Output, ViewChild, ChangeDetectorRef, TemplateRef, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FilterData } from '../models/list-model';
import { CommunicationService } from '../service/communication/communication.service';
import { ShipmentModel, ShipmentTranferGRRefContainerNo } from '../models/shipment/shipment-data-model';
import { ShipmentService } from '../service/shipment/shipment.service';
import { ColumnSetup } from '../models/column-setup';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { catchError, Observable, of, skip, Subject, takeUntil } from 'rxjs';
import { ApiUserService } from '../service/user/api-user.service';
import { AlertService } from '../service/alerts/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionComponent } from '../dialog/subscription/subscription.component';
import { AlertNotificationModel } from '../models/alerts/alert-notification-model';
import { AlertSubscriptionInputModel } from '../models/alerts/alert-subscription-input-model';
import { AlertSubscriptionModel } from '../models/alerts/alert-subscription-model';
import { AppComponent } from '../app.component';
import { DocumentStatus } from '../enums/document-status.enum';
import { FormControl, FormGroup } from '@angular/forms';
import { TableUtil } from '../service/utils/excel-export-service.service';
import { Table } from 'primeng/table';
import { TableConfigModel } from '../models/table-config';
import { TableConfigEnum } from '../models/table-config-enum';
import { MessageService } from 'primeng/api';
import { CommonService } from '../service/common/common.service';
import FileSaver from 'file-saver';
import { BookingListModel } from '../models/bookings/booking-list-model';
import { GlobalPanelService } from '../service/panel/global-panel-service.service';

interface Column {
  name: string;
  field: string;
  header: string;
  type: string;
  visible: boolean;
  toolTip?: string;
  customExportHeader?: string;
}
interface ExportColumn {
  title: string;
  dataKey: string;
}

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.css'],
})
export class ShipmentListComponent implements OnInit {
  @ViewChild('tbody') tableWrapper!: ElementRef; // Access tbody element
  @ViewChildren('snap-row') cards!: QueryList<ElementRef>; // Query all card elements
  @ViewChild('customContent') customContent!: TemplateRef<any>;
  @ViewChild('dt', { static: true }) table!: ElementRef;
  @ViewChild('dt') dt!: Table;
  @Output() openDetailDrawer = new EventEmitter<string>();
  private destroy$ = new Subject<void>();
  protected filterList = new FormControl();
  loading: boolean = true;
  mobileTableConfig: boolean = false;
  searchValue: string | undefined;
  selectedBookings: Column[] = [];
  bookingColumns: Column[] = [];
  panelCollapsed: boolean = true;
  bookings!: any[];
  cols: Column[] = [];
  exportColumns!: ExportColumn[];
  filterData!: FilterData;
  formGroup: FormGroup | undefined;
  currentRowIndex = 0;
  shipmentData: ShipmentModel[] = [];
  filter!: FilterData;
  detailView = detailView;
  detailTabbedViewEnum = detailTabbedViewEnum;
  displayedColumns: ColumnSetup[] = [];
  isSingleCustUser: boolean = false;
  isSingleConsigneeUser: boolean = false;
  isConsigneeUser: boolean = false;
  extractedColumns: string[] = [];
  filterId: number = 0;
  startDate?: Date = new Date();
  endDate?: Date = new Date();
  templates: AlertSubscriptionModel[] = [];
  _snackBar: any;
  horizontalPosition: any;
  verticalPosition: any;
  shipmentDataSource = new MatTableDataSource<ShipmentModel>();
  originalShipmentDataSource = new MatTableDataSource<ShipmentModel>();
  loadingSearch: boolean = false;
  dataSourceFilter: string = '';
  isLoading = true;
  errorMessage: string | null = null;

  constructor(
    private alertDataService: AlertService,
    private shipmentDataService: ShipmentService,
    private dialog: MatDialog,
    private communicationService: CommunicationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appComponent: AppComponent,
    private cdr: ChangeDetectorRef,
    private apiUserService: ApiUserService,
    private commonService: CommonService,
    private messageService: MessageService,
    private panelService: GlobalPanelService
  ) {
    this.matIconRegistry.addSvgIcon(
      'Subscribe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Subscribe.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ViewDocuments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewDocuments.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ViewAlerts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewAlerts.svg')
    );
    this.matIconRegistry.addSvgIcon('backspace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/backspace.svg'));
    this.matIconRegistry.addSvgIcon(
      'export',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Export-Icon.svg')
    )
  }

  ngOnInit(): void {
    this.loading = true;
    this.getTableConfig()
    this.formGroup = new FormGroup({
      text: new FormControl<string | null>(null)
    });
    this.selectedBookings = this.cols;
    this.bookingColumns = this.cols.filter(
      (col) => col.field !== 'actions'
    );
    this.exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));
    setTimeout(() => {
      this.shipmentDataService.getShipments(this.filterId, this.filterData, this.startDate, this.endDate).subscribe((data) => {
        this.bookings = data;
        this.loading = false;
      });
    }, 1000);
    this.shipmentServiceSetup();
    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isSingleCustUser = this.apiUserService.IsSingleCustUser;
            this.isSingleConsigneeUser = this.apiUserService.IsSingleConsignee;
            this.isConsigneeUser = this.apiUserService.IsConsigneeUser;
          }
      });
    this.isSingleCustUser = this.apiUserService.IsSingleCustUser;
    this.isSingleConsigneeUser = this.apiUserService.IsSingleConsignee;
    this.isConsigneeUser = this.apiUserService.IsConsigneeUser;
  }

  ngAfterViewInit() {
    this.scrollToTop();
    const myElements = document.getElementsByClassName('p-datatable-wrapper');
    if (myElements.length > 0) {
      for (let i = 0; i < myElements.length; i++) {
        const element = myElements[i] as HTMLElement;
        element.addEventListener('scroll', (event: Event) => {
          const rows = element.querySelectorAll('.snap-row');
          if (rows.length > 0) {
            const observerOptions: IntersectionObserverInit = {
              root: element,
              threshold: 0.01,
            };
            const observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  const index = Array.from(rows).findIndex(
                    (row) => row === entry.target
                  );
                  if (index !== -1) {
                    this.currentRowIndex = index
                  }
                }
              });
            }, observerOptions);
            rows.forEach((row) => observer.observe(row));
          }
        });
      }
    } else {
      console.error('No elements with class "p-datatable-wrapper" found!');
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private detectCustUser(): Observable<void> {
    this.apiUserService.userInfo
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (_) => {
          this.isSingleCustUser = this.apiUserService.IsSingleCustUser;
        },
        error: (error) => {
          console.error('Error fetching user info:', error);
        }
      });
    this.isSingleCustUser = this.apiUserService.IsSingleCustUser;
    return of(undefined)
  }

  private shipmentServiceSetup(): void {
    this.shipmentDataService.statusFilterToggle$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (value) => {
        this.applyFilter(value || '')
      }
    });

    this.shipmentDataService.sendFilterTransfer$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (t) => {
        this.isLoading = true;
        this.filter = t.filter;
        this.filterId = t.filterId;
        this.startDate = t.startDate;
        this.endDate = t.endDate;
        this.updateData(this.filterId, this.filter, this.startDate, this.endDate);
      },
      error: (error) => {
        console.error('Send FilterModel Error:', error);
        window.alert('Send FilterModel Error. Please try again later.');
      }
    });
  }

  private triggerCDR() {
    this.cdr.detectChanges();
  }

  private createNotificationModel(row: any): AlertNotificationModel {
    return {
      AlertCode: '',
      CustomerCode: row.customerCode,
      GRReference: String(row.grReference),
      ContainerNo: row.containerNumber,
      TemplateGroup: 'Shipment'
    } as AlertNotificationModel;
  }

  getBackgroundColor(cellValue: string): string {
    switch (cellValue) {
      case 'Arrived':
        return '#36DE6F';
      case 'Active':
        return '#368EDE';
      default:
        return '#DC6868';
    }
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  updateData(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): void {
    this.shipmentDataService.getShipments(filterId, filterData, startDate, endDate).subscribe({
      next: (data) => {
        this.shipmentDataSource.data = data;
        this.originalShipmentDataSource.data = data;
        this.filterList.setValue([]);
        this.filterList.reset();
        this.applyFilter(this.dataSourceFilter);
        this.errorMessage = '';
        this.isLoading = false;
      },
      error: (err) => {
        console.error('Error fetching shipment data', err);
        this.isLoading = false;
        this.errorMessage = 'There is a ' + err.name + ' - Error: Status = ' + err.status + ' - Please contact support';
      }
    });
  }

  viewDetails(row: any, view: detailView, tab: detailTabbedViewEnum): void {
    this.communicationService.toggleDetailView(view, tab);
    let info: ShipmentTranferGRRefContainerNo = new ShipmentTranferGRRefContainerNo;
    info.grReferenceNo = row.grReference;
    info.containerNo = row.containerNumber;
    this.communicationService.getDetailViewGRRefcontainerNo(info);
    this.openDetailDrawer.emit();
    const DETAIL_DRAWER_DELAY_MS = 500;
    setTimeout(() => {
      this.triggerCDR();
    }, DETAIL_DRAWER_DELAY_MS);
  }

  public fetchDocumentStatusClass(incomingDocumentStatus: DocumentStatus): string {
    switch (incomingDocumentStatus) {
      case DocumentStatus.Rejected:
        return 'indicator-btn-documents-rejected';
      case DocumentStatus.AwaitingApproval:
        return 'indicator-btn-documents-awaiting';
      case DocumentStatus.Approved:
        return 'indicator-btn-documents-approved';
      default:
        return 'indicator-btn';
    }
  }

  subscribeToAlert(row: ShipmentModel): void {
    const notificationModel = this.createNotificationModel(row);
    this.showLoading(true);
    this.alertDataService.getAlertSubscriptionTemplates(notificationModel)
    .pipe(
      catchError(error => {
        console.error('Error fetching subscription templates:', error);
        return of([]);
      })
    )
    .subscribe((subscriptionTemplates: AlertSubscriptionModel[]) => {
      this.showLoading(false);
      const subscriptionInput = new AlertSubscriptionInputModel();
      subscriptionInput.notificationModel = notificationModel;
      subscriptionInput.templates = subscriptionTemplates;

      const dialogRef = this.dialog.open(SubscriptionComponent, {
        data: { subscriptionList: subscriptionTemplates }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          subscriptionInput.templates = result;
          this.alertDataService.updateAlertSubscription(subscriptionInput)
          .pipe(
            catchError(error => {
              console.error('Error updating subscriptions:', error);
              return of(null);
            })
          )
          .subscribe(response => {
            if (response) {
              this.updateData(this.filterId, this.filter, this.startDate, this.endDate);
            }
          });
        }
      });
    });
  }

  showLoading(show: boolean): void {
    this.appComponent.showLoading(show);
  }

  openSnackBar(message: string): void {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  getExtractedColumns(columns: ColumnSetup[]): string[] {
    return columns.map(col => col.name);
  }

  applyFilter(filterValue: string): void {
    this.dataSourceFilter = filterValue;
    const filterValueLower = this.dataSourceFilter.trim().toLowerCase();
    if (!filterValue) {
      this.shipmentDataSource.data = this.originalShipmentDataSource.data;
      this.shipmentDataSource.filter = '';
    } else {
      if (filterValueLower === 'active') {
        this.shipmentDataSource.data = this.originalShipmentDataSource.data;
        this.shipmentDataSource.data = this.shipmentDataSource.data.filter(data => data.shipmentStatus === 'Active');
      }
      else if (filterValueLower === 'arrived') {
        this.shipmentDataSource.data = this.originalShipmentDataSource.data;
        this.shipmentDataSource.data = this.shipmentDataSource.data.filter(data => data.shipmentStatus === 'Arrived');
      }
      else {
        this.shipmentDataSource = this.originalShipmentDataSource;
      }
    }
  }

  clearSearchField(control: FormControl): void {
    control.reset(); 
    this.shipmentDataSource.filter = '';
  }

  applySearch(filterValue: string | undefined) {
    const normalizedFilter = (filterValue || '').trim().toLowerCase();
    this.loadingSearch = true;
    setTimeout(() => {
      this.shipmentDataSource.filter = normalizedFilter;
      this.loadingSearch = false;
    }, 200);
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
      const visibleColumns = this.selectedBookings
        .filter(col => col.visible)
        .map(col => ({ title: this.toPascalCase(col.header), dataKey: col.field }));

      const visibleRows = this.shipmentDataSource.filteredData.map(row => {
        const filteredRow: Record<string, any> = {};
        visibleColumns.forEach(col => {
          const key = col.dataKey as keyof ShipmentModel;
          filteredRow[col.title] = row[key];
        });
        return filteredRow;
      });
      const worksheet = xlsx.utils.json_to_sheet(visibleRows);
      const range = xlsx.utils.decode_range(worksheet['!ref']!);
      const headerRow = range.s.r;
      for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
        const cellAddress = xlsx.utils.encode_cell({ r: headerRow, c: colIndex });
        const cell = worksheet[cellAddress];
        if (cell) {
          cell.s = {
            font: { bold: true, color: { rgb: 'FFFFFF' } },
            fill: { fgColor: { rgb: '4F81BD' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          };
        }
      }
      worksheet['!cols'] = visibleColumns.map(col => ({
        wch: Math.max(
          col.title.length,
          ...visibleRows.map(row => (row[col.title] ? row[col.title].toString().length : 0))
        ),
      }));
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array', cellStyles: true });
      this.saveAsExcelFile(excelBuffer, "shipments");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  toPascalCase(text: string): string {
    return text
      .replace(/(\w)(\w*)/g, (_, firstChar, rest) =>
        firstChar.toUpperCase() + rest.toLowerCase()
      )
      .replace(/\s+/g, '');
  }

  saveTableConfig(event: any): void {
    const tableConfig: TableConfigModel = {
      table: TableConfigEnum.ShipmentsOceanTable,
      columnOrder: JSON.stringify(this.selectedBookings),
      columns: JSON.stringify(this.selectedBookings)
    };

    this.commonService.saveTableConfigFilter(tableConfig).subscribe({
      next: (response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Table configuration saved successfully!',
          life: 3000,
        });
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to save table configuration. Please try again.',
          life: 3000,
        });
      }
    });
    this.handleButtonClick()
    this.closePanel()
  }

  getTableConfig() {
    const tableConfig: TableConfigModel = {
      table: TableConfigEnum.ShipmentsOceanTable,
      columnOrder: JSON.stringify(this.selectedBookings),
      columns: JSON.stringify(this.selectedBookings),
    };

    this.commonService.getUserTableConfig(tableConfig).subscribe({
      next: (response) => {
        if (response?.columns && response?.columnOrder) {
          try {
            const parsedColumns = JSON.parse(response.columns) as Column[];
            const parsedColumnOrder = JSON.parse(response.columnOrder) as Array<{ field: string }>;
            if (parsedColumns.length && parsedColumnOrder.length) {
              const orderedFields = parsedColumnOrder.map((order) => order.field);
              const orderedColumns = orderedFields
                .map((field) => {
                  const column = parsedColumns.find((col) => col.field === field);
                  if (!column) {
                    console.warn(`Field "${field}" not found in parsedColumns.`);
                  }
                  return column;
                })
                .filter((col): col is Column => col !== undefined);
              if (orderedColumns.length) {
                this.cols = [...orderedColumns];
                this.selectedBookings = [...this.cols];
                this.bookingColumns = [
                  { name: 'customer', field: 'customer', header: this.isConsigneeUser ? 'Shipper' : 'Customer', type: 'string', visible: !this.isSingleCustUser, toolTip: 'Name of the customer.' },
                  { name: 'clientReference', field: 'clientReference', header: this.isConsigneeUser ? 'Shipper Ref' : 'Client Ref', type: 'string', visible: true },
                  { name: 'grReference', field: 'grReference', header: 'GR Ref No', type: 'string', visible: true, toolTip: 'GoReefers reference number for the booking.' },
                  { name: 'consignee', field: 'consignee', header: 'Consignee', type: 'string', visible: !this.isSingleConsigneeUser, toolTip: 'Name of the shipment receiver or importer.' },
                  { name: 'destination', field: 'destination', header: 'Discharge Port', type: 'string', visible: true, toolTip: 'Port where the vessel discharges some or all of its cargo.' },
                  { name: 'etd', field: 'etd', header: 'ETD', type: 'string', visible: true, toolTip: 'Estimated time of departure.' },
                  { name: 'eta', field: 'eta', header: 'ETA', type: 'string', visible: true, toolTip: 'Estimated time of arrival.' },
                  { name: 'vesselName', field: 'vesselName', header: 'Vessel Name', type: 'string', visible: true },
                  { name: 'containerNumber', field: 'containerNumber', header: 'Container No', type: 'string', visible: true, toolTip: 'Shipment’s container number.' },
                  { name: 'bookingReference', field: 'bookingReference', header: 'Booking Ref', type: 'string', visible: true, toolTip: 'Booking reference number.' },
                ];
                this.exportColumns = this.cols.map((col) => ({
                  title: col.header,
                  dataKey: col.field,
                }));
                return;
              }
            }
          } catch (error) {
            console.error('Error parsing columns or columnOrder:', error);
          }
        }
        this.useDefaultColumns('Invalid or empty configuration. Using default columns.');
      },
      error: (error) => {
        this.useDefaultColumns('Failed to load table configuration. Using default columns.');
      },
    });
  }

  useDefaultColumns(message: string) {
    console.warn(message);
    const defaultColumns = [
      { name: 'customer', field: 'customer', header: this.isConsigneeUser ? 'Shipper' : 'Customer', type: 'string', visible: !this.isSingleCustUser, toolTip: 'Name of the customer.' },
      { name: 'clientReference', field: 'clientReference', header: this.isConsigneeUser ? 'Shipper Ref' : 'Client Ref', type: 'string', visible: true },
      { name: 'grReference', field: 'grReference', header: 'GR Ref No', type: 'string', visible: true, toolTip: 'GoReefers reference number for the booking.' },
      { name: 'consignee', field: 'consignee', header: 'Consignee', type: 'string', visible: !this.isSingleConsigneeUser, toolTip: 'Name of the shipment receiver or importer.' },
      { name: 'destination', field: 'destination', header: 'Discharge Port', type: 'string', visible: true, toolTip: 'Port where the vessel discharges some or all of its cargo.' },
      { name: 'etd', field: 'etd', header: 'ETD', type: 'string', visible: true, toolTip: 'Estimated time of departure.' },
      { name: 'eta', field: 'eta', header: 'ETA', type: 'string', visible: true, toolTip: 'Estimated time of arrival.' },
      { name: 'vesselName', field: 'vesselName', header: 'Vessel Name', type: 'string', visible: true },
      { name: 'containerNumber', field: 'containerNumber', header: 'Container No', type: 'string', visible: true, toolTip: 'Shipment’s container number.' },
      { name: 'bookingReference', field: 'bookingReference', header: 'Booking Ref', type: 'string', visible: true, toolTip: 'Booking reference number.' },
    ];
    this.cols = [...defaultColumns];
    this.selectedBookings = [...this.cols];
    this.bookingColumns = [...defaultColumns];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  rowClass(booking: any) {
    return { 'bground-primary': booking === 'Undefined' };
  }

  rowStyle(booking: any) {
    if (booking.quantity === 0) {
      return { fontWeight: 'bold', fontStyle: 'italic' };
    } else {
      return {};
    }
  }

  getSeverity(status: string): string {
    switch (status) {
      case 'Cancelled':
        return 'var(--danger-color)';
      case 'Confirmed':
        return 'var(--success-color)';
      case 'Awaiting Approval':
        return 'var(--warn-color)';
      case 'Processing':
        return 'var(--neutral-color)';
      case 'renewal':
        return 'var(--theme-neutral-bg)';
      default:
        return 'var(--theme-unknown-bg)';
    }
  }

  updateDisplayedColumns() {
    this.cols = [...this.selectedBookings];
  }

  private scrollToTop() {
    if (this.dt) {
      this.dt.scrollTo({ top: 0 });
    }
  }

  handleButtonClick(): void {
    if (this.isMobile()) {
      this.openPanel()
    } else {
      this.togglePanel();
    }
  }

  togglePanel() {
    this.panelCollapsed = !this.panelCollapsed;
  }

  isMobile(): boolean {
    return window.innerWidth <= 480;
  }

  isTablet(): boolean {
    return window.innerWidth <= 768;
  }

  clear(table: Table) {
    table.clear();
    this.searchValue = ''
  }

  openPanel(): void {
    this.panelService.openPanel('Select Table Columns', this.customContent);
  }

  closePanel(): void {
    this.panelService.closePanel()
  }

  updateCurrentRow(index: number): void {
    this.currentRowIndex = index + 1; 
  }

  onPageChange(event: any): void {
    this.currentRowIndex = event.first;
  }

  updateCurrentIndex(container: Element, rows: NodeListOf<Element>): void {
    let closestIndex = 0;
    let closestDistance = Number.MAX_VALUE;
    rows.forEach((row, index) => {
      const rect = row.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      const distance = Math.abs(rect.top - containerRect.top);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestIndex = index;
      }
    });
    this.currentRowIndex = closestIndex;
  }

  onScroll(event: Event): void {
    const tableWrapper = event.target as HTMLElement;
    const rows = Array.from(tableWrapper.querySelectorAll('.snap-row')) as HTMLElement[];
    let currentRowIndex = 0;
    let closestDistance = Number.MAX_VALUE;
    rows.forEach((row, index) => {
      const rect = row.getBoundingClientRect();
      const distance = Math.abs(rect.top - tableWrapper.getBoundingClientRect().top);
      if (distance < closestDistance) {
        closestDistance = distance;
        currentRowIndex = index;
      }
    });
  }

}
