export enum FilterEnum {
    mainCustomerFilter,
    bookingFilter,
    scheduleFilter,
    shipmentFilter,
    documentFilter,
    alertFilter,
    dashboardTrackingFilter,
    pendingDocumentsFilter,
    roadShipmentFilter
}
