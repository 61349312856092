<p-toast></p-toast>
<ng-container *ngIf="!isSingleCustUser && !isConsigneeUser">
  <div class="col-md-2-shipments">
    <mat-form-field *ngIf="customers.value" [ngClass]="{'custom-mat-underline': currentFilter.id !== 0}">
      <button mat-button class="global-filter-btn"
      matTooltip="Filter all data by customer." matTooltipPosition="below"
    matTooltipClass="custom-mat-tooltip">
        <div class="global-filter-icon-wrap">
          <div class="global-filter-icon"></div>
        </div>
        <span *ngIf="customers.value.length > 0" class="badge">
          {{customers.value.length }}
        </span>
      </button>
      <mat-select [formControl]="customers" multiple #multiSelect>
        <mat-option>
          <ngx-mat-select-search [formControl]="customersMultiFilter" placeholderLabel="Search"
                                 noEntriesFoundLabel="Customer not found"></ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger>
        </mat-select-trigger>

        <!-- Display selected options at the top -->
        <ng-container *ngFor="let customer of customers.value">
          <mat-option [value]="customer">
            {{customer.name}}
          </mat-option>
        </ng-container>

        <!-- Display remaining options -->
        <ng-container *ngFor="let customer of filteredCustomers | async">
          <mat-option *ngIf="!customers.value.includes(customer)" [value]="customer">
            {{customer.name}}
          </mat-option>
        </ng-container>
        <div class="main-filter-action-btns">

        <div>
          <button class="clearAllButton" (click)="clearSavedCustomerSelection()"
           [disabled]="customers.value.length < 1" [ngClass]="{'disabled-button': customers.value.length < 1}">Clear all</button>
        </div>

        <div>
          <button class="runReportButton" (click)="saveCustomerSelection()">Save</button>
        </div>
      </div>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>
