<div class="dv-content-wrap">
    <div class="dv-header-wrap">
        <div class="detail-header">
            <h3>Shipment - {{grRefContainerData.grReferenceNo}}</h3>
        </div>


        <div class="header-action-btns">
            <p-toast />
            <button *ngIf="!isConsigneeUser"
            (click)="showDialog()"
            class="email-button"
            [matTooltip]="'Email All Documents'"
            matTooltipPosition="above"
            matTooltipClass="custom-mat-tooltip">
      <mat-icon svgIcon="group-email"></mat-icon>
      </button>
          </div>
    </div>
    <ng-container *ngIf="!isLoading && shipmentData.grReference; else noDataContainer">
        <div class="dv-summary-list">
            <div class="dv-summary-column">
                <div class="info-item">
                    <span class="info-label">Consignee</span>
                    <span>{{shipmentData.consignee}}</span>
                </div>

                <div class="info-item">
                    <span class="info-label">Load Port</span>
                    <span>{{shipmentData.loadPortName}}</span>
                </div>
            </div>

            <div class="dv-summary-column">
                <div class="info-item">
                    <span class="info-label">Carrier</span>
                    <span>{{shipmentData.carrierName}}</span>
                </div>

                <div class="info-item">
                    <span class="info-label">Vessel</span>
                    <span>{{shipmentData.vesselName}}</span>
                </div>
            </div>
            <div class="dv-summary-column">
                <div class="info-item">
                    <span class="info-label">Discharge Port</span>
                    <span>{{shipmentData.destination}}</span>
                </div>

                <div class="info-item">
                    <span class="info-label">Containers</span>
                    <span>{{ shipmentData.containerNumber.split(',').join(' | ') }}</span>
                </div>
            </div>

        </div>

        <ng-container *ngIf="!isLoading && shipmentData.grReference">
            <app-document-management [grRefNo]="shipmentData.grReference"
                [containerNo]="shipmentData.containerNumber"></app-document-management>
        </ng-container>
    </ng-container>
    <ng-template #noDataContainer>
        <ng-container *ngIf="isLoading" class="no-data-container">
            <div class="no-data-container">
                <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
            </div>
        </ng-container>
        <ng-container *ngIf="!isLoading" class="no-data-container">
            <div class="no-data-container">
                There is currently no information for shipment {{grRefContainerData.grReferenceNo}}.
            </div>
        </ng-container>
    </ng-template>

    
    <p-dialog header="SELECT DOCUMENTS TO EMAIL"
     [(visible)]="isSelectDocumentsDialogvisible" 
     [style]="{ width: '25rem' }"
     headerStyleClass="custom-dialog-header">
     
     <p-listbox [options]="documentList" 
     [(ngModel)]="selectedDocuments" 
     [checkbox]="true"  
     [multiple]="true">
        <ng-template let-item pTemplate="item">
            <div class="document-item">
                <div class="document-type">{{ item.documentTypeDocument }}</div>
                <div class="document-name">{{ item.attachmentFilename || 'No Name Available' }}</div>
            </div>
        </ng-template>
    </p-listbox>



    <mat-form-field>
        <mat-label>Email</mat-label>
        <input  type="email"matInput [formControl]="emailTo">
    </mat-form-field>
    <div mat-dialog-actions class="dialog-button-wrap">
        <button class="dialog-deny-button" mat-button (click)="isSelectDocumentsDialogvisible = false">Cancel</button>
        <button class="dialog-okay-button" 
                mat-button 
                (click)="emailSelectedDocuments()" 
                [disabled]="!emailTo.value || selectedDocuments.length === 0"
                [ngClass]="{'disabled': !emailTo.value || selectedDocuments.length === 0}">Send Documents</button>
      </div>
      <div class="pop-up-disclaimer">
        By using this function, you acknowledge and agree that you are solely responsible for selecting the correct files and entering accurate email address.
      </div>
    </p-dialog>


    <p-dialog header="Emailing documents"
    [(visible)]="visible" 
    [style]="{ width: '25rem' }"
    headerStyleClass="custom-dialog-header">
    <p>
        You may continue with your work while we compile and proccess your documents...
    </p>
    <div mat-dialog-actions class="dialog-button-wrap">
        <button class="dialog-okay-button"
        mat-button (click)="hideDialog()">
        Okay
    </button>
      </div>
   </p-dialog>
</div>
