import { OnInit, Component, ViewChild, Output, EventEmitter, OnDestroy, inject, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BookingService } from '../service/booking/booking.service';
import { FilterData } from '../models/list-model';
import { CommunicationService } from '../service/communication/communication.service';
import { ColumnSetup } from '../models/column-setup';
import { detailTabbedViewEnum, detailView } from '../models/detail-view-enum';
import { Subject, of } from 'rxjs';
import { takeUntil, catchError, skip } from 'rxjs/operators';
import { ApiUserService } from '../service/user/api-user.service';
import { SubscriptionComponent } from '../dialog/subscription/subscription.component';
import { AlertNotificationModel } from '../models/alerts/alert-notification-model';
import { AlertSubscriptionInputModel } from '../models/alerts/alert-subscription-input-model';
import { AlertSubscriptionModel } from '../models/alerts/alert-subscription-model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponent } from '../app.component';
import { AlertService } from '../service/alerts/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { AppBookingListModel } from '../models/bookings/appbooking-list-model';
import { TableUtil } from '../service/utils/excel-export-service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Table } from 'primeng/table';
import { TableConfigModel } from '../models/table-config';
import { TableConfigEnum } from '../models/table-config-enum';
import { MessageService } from 'primeng/api';
import { CommonService } from '../service/common/common.service';
import FileSaver from 'file-saver';
import { GlobalPanelService } from '../service/panel/global-panel-service.service';
interface Column {
  name: string;
  field: string;
  header: string;
  type: string;
  visible: boolean;
  toolTip?: string;
  customExportHeader?: string;
}
interface ExportColumn {
  title: string;
  dataKey: string;
}

@Component({
  selector: 'app-booking-submitted-view',
  templateUrl: './booking-submitted-view.component.html',
  styleUrl: './booking-submitted-view.component.css'
})
export class BookingSubmittedViewComponent implements OnInit, OnDestroy {
  @ViewChild('dt') dt!: Table;
  @ViewChild('customContent') customContent!: TemplateRef<any>;
  @Output() openDetailDrawer = new EventEmitter<string>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<AppBookingListModel>;

  private apiUserService = inject(ApiUserService)
  private destroy$ = new Subject<void>();
  loading: boolean = true;
  mobileTableConfig: boolean = false;
  searchValue: string | undefined;
  selectedBookings: Column[] = [];
  bookingColumns: Column[] = [];
  panelCollapsed: boolean = true;
  bookings!: any[];
  cols: Column[] = [];
  exportColumns!: ExportColumn[];
  filterData!: FilterData;
  formGroup: FormGroup | undefined;
  currentRowIndex = 0;
  isSingleCustUser: boolean = false;
  bookingData: AppBookingListModel[] = [];
  filter!: FilterData;
  detailTabbedViewEnum = detailTabbedViewEnum;
  detailView = detailView;
  filterId: number = 0;
  startDate?: Date = new Date;
  endDate?: Date = new Date;
  displayedColumns: ColumnSetup[] = [];
  extractedColumns: string[] = [];
  appBookingDataSource = new MatTableDataSource<AppBookingListModel>();
  _snackBar: any;
  horizontalPosition: any;
  verticalPosition: any;
  templates: AlertSubscriptionModel[] = [];
  isLoading = true;

  constructor(
    private alertDataService: AlertService,
    private bookingDataService: BookingService,
    private communicationService: CommunicationService,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private appComponent: AppComponent,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private messageService: MessageService,
    private panelService: GlobalPanelService
  ) {
    this.matIconRegistry.addSvgIcon(
      'Subscribe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Subscribe.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewDocuments',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewDocuments.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ViewAlerts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ViewAlerts.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'export',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/Export-Icon.svg')
    )
  }

  ngOnInit(): void {
    this.loading = true;
    this.getTableConfig()

    this.apiUserService.userInfo
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next:
          (_) => {
            this.isSingleCustUser = this.apiUserService.IsSingleCustUser;
          }
      });

    this.formGroup = new FormGroup({
      text: new FormControl<string | null>(null)
    });

    this.selectedBookings = this.cols;

    this.bookingColumns = this.cols.filter(
      (col) => col.field !== 'actions'
    );

    this.exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));

    setTimeout(() => {
      this.bookingDataService.getAppBookings().subscribe((data) => {
        this.bookings = data;
        this.loading = false;
      });
    }, 1000);

    this.isSingleCustUser = this.apiUserService.IsSingleCustUser;
    this.bookingDataService.statusFilterToggle$
    .pipe(
      takeUntil(this.destroy$),
      skip(1)
    )
    .subscribe({
      next:
        value => {
          if (value) {
            this.applyFilter(value);
          } else {
            this.applyFilter('');
          }
        }
    });

    this.bookingDataService.sendFilterTransfer$
    .pipe(
      takeUntil(this.destroy$)
    )
    .subscribe({
      next: (t) => {
        this.filter = t.filter;
        this.filterId = t.filterId;
        this.startDate = t.startDate;
        this.endDate = t.endDate;
        this.updateData(this.filterId, this.filter, this.startDate, this.endDate);
      },
      error: (error) => {
        console.error('Send FilterModel Error', error);
        window.alert('Send FilterModel Error. Please try again later.');
      }
    });
  }

  ngAfterViewInit() {
    const myElements = document.getElementsByClassName('p-datatable-wrapper'); 
    if (myElements.length > 0) {
      for (let i = 0; i < myElements.length; i++) {
        const element = myElements[i] as HTMLElement;
        element.addEventListener('scroll', (event: Event) => {
          const rows = element.querySelectorAll('.snap-row');
          if (rows.length > 0) {
            const observerOptions: IntersectionObserverInit = {
              root: element,
              threshold: 0.1,
            };
            const observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  const index = Array.from(rows).findIndex(
                    (row) => row === entry.target
                  );
                  if (index !== -1) {
                    this.currentRowIndex = index
                  }
                }
              });
            }, observerOptions);
            rows.forEach((row) => observer.observe(row));
          }
        });
      }
    } else {
      console.error('No elements with class "p-datatable-wrapper" found!');
    }
    this.appBookingDataSource.sort = this.sort;
    this.sort.sort({ id: 'appBookingCode', start: 'asc', disableClear: false });
  }

  configureColumns(): void {
    this.displayedColumns = [
      { name: 'customerName', header: 'Customer', type: 'string', visible: !this.isSingleCustUser },
      { name: 'lineCreatedDate', header: 'Date Created', type: 'date', visible: true },
      { name: 'appBookingCode', header: 'Booking Code', type: 'string', visible: true },
      { name: 'appBookingStatus', header: 'Booking Status', type: 'string', visible: true },
      { name: 'reason', header: 'Rejection Reason', type: 'string', visible: true },
      { name: 'loadportName', header: 'Load Port', type: 'string', visible: true },
      { name: 'carrierName', header: 'Carrier', type: 'string', visible: true },
      { name: 'sailDateETD', header: 'Load ETD', type: 'date', visible: true },
      { name: 'vesselName', header: 'Vessel Name', type: 'string', visible: true },
      { name: 'quantity', header: 'Total Containters', type: 'number', visible: true },
    ];
    this.extractedColumns = this.displayedColumns.map(col => col.name);
  }

  subscribeToAlert(row: any): void {
    const notificationModel = this.createNotificationModel(row);
    this.alertDataService.getAlertSubscriptionTemplates(notificationModel)
    .pipe(
      catchError(error => {
        console.error('Error fetching subscription templates:', error);
        return of([]);
      })
    )
    .subscribe((subscriptionTemplates: AlertSubscriptionModel[]) => {

      const subscriptionInput = new AlertSubscriptionInputModel();
      subscriptionInput.notificationModel = notificationModel;
      subscriptionInput.templates = subscriptionTemplates;
      const dialogRef = this.dialog.open(SubscriptionComponent, {
        data: { subscriptionList: subscriptionTemplates }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          subscriptionInput.templates = result;
          this.alertDataService.updateAlertSubscription(subscriptionInput)
          .pipe(
            catchError(error => {
              console.error('Error updating subscriptions:', error);
              return of(null);
            })
          )
          .subscribe(response => {
            if (response) {
              this.updateData(this.filterId, this.filter, this.startDate, this.endDate);
            }
          });
        }
      });
    });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Dismiss', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 8000,
      panelClass: ['snackbar-success']
    });
  }

  getBackgroundColor(cellValue: string): string {
    switch (cellValue) {
      case 'Booking Rejected':
      case 'Amendment Rejected':
      case 'Cancel Pending':
      case 'Cancelled':
        return '#DC6868';
      default:
        return '#C2C2C2';
    }
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

  updateData(filterId: number, filterData: FilterData, startDate?: Date, endDate?: Date): void {
    this.isLoading = true;
    this.bookingDataService.getAppBookings().subscribe(data => {
      this.configureColumns();
      this.appBookingDataSource.sort = this.sort;
      this.appBookingDataSource.paginator = this.paginator;
      this.appBookingDataSource.data = data;
      this.isLoading = false;
    });
  }

  private createNotificationModel(row: any): AlertNotificationModel {
    return {
      AlertCode: '',
      CustomerCode: row.customerCode,
      BookingCode: row.bookingHeaderCode,
      TemplateGroup: 'Booking'
    } as AlertNotificationModel;
  }

  viewDetails(row: any, detailViewToOpen: detailView, tab: detailTabbedViewEnum): void {
    var bookingParameter = row['bookingHeaderCode'];
    this.communicationService.toggleDetailView(detailViewToOpen, tab);
    this.communicationService.getDetailViewParameter(bookingParameter)
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  applyFilter(filterValue: string) {
    if (!filterValue) {
      this.appBookingDataSource.filter = '';
    } else {
      this.appBookingDataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  filterByStatus(status: string) {
    this.appBookingDataSource.filterPredicate = (data: AppBookingListModel, filter: string) => data.appBookingStatus === filter;
    this.appBookingDataSource.filter = status;
  }

  filterByDateRange(startDate: string, endDate: string) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    this.appBookingDataSource.filterPredicate = (data: AppBookingListModel, filter: string) => {
      const date = new Date(data.sailDate);
      return date >= start && date <= end;
    };
    this.appBookingDataSource.filter = `${startDate}-${endDate}`;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  exportTable() {
    TableUtil.exportToExcel(this.appBookingDataSource.data, this.displayedColumns, 'Pending Bookings');
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
      const visibleColumns = this.selectedBookings
        .filter(col => col.visible)
        .map(col => ({ title: this.toPascalCase(col.header), dataKey: col.field }));

      const visibleRows = this.appBookingDataSource.filteredData.map(row => {
        const filteredRow: Record<string, any> = {};
        visibleColumns.forEach(col => {
          const key = col.dataKey as keyof AppBookingListModel;
          filteredRow[col.title] = row[key];
        });
        return filteredRow;
      });

      const worksheet = xlsx.utils.json_to_sheet(visibleRows);
      const range = xlsx.utils.decode_range(worksheet['!ref']!);
      const headerRow = range.s.r;
      for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
        const cellAddress = xlsx.utils.encode_cell({ r: headerRow, c: colIndex });
        const cell = worksheet[cellAddress];
        if (cell) {
          cell.s = {
            font: { bold: true, color: { rgb: 'FFFFFF' } },
            fill: { fgColor: { rgb: '4F81BD' } },
            alignment: { horizontal: 'center', vertical: 'center' },
          };
        }
      }
      worksheet['!cols'] = visibleColumns.map(col => ({
        wch: Math.max(
          col.title.length,
          ...visibleRows.map(row => (row[col.title] ? row[col.title].toString().length : 0))
        ),
      }));
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array', cellStyles: true });
      this.saveAsExcelFile(excelBuffer, "pending_bookings");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  toPascalCase(text: string): string {
    return text
    .replace(/(\w)(\w*)/g, (_, firstChar, rest) =>
      firstChar.toUpperCase() + rest.toLowerCase()
    )
    .replace(/\s+/g, '');
  }

  rowClass(booking: any) {
    return { 'bground-primary': booking === 'Undefined' };
  }

  rowStyle(booking: any) {
    if (booking.quantity === 0) {
      return { fontWeight: 'bold', fontStyle: 'italic' };
    } else {
      return {};
    }
  }

  getSeverity(status: string): string {
    switch (status) {
      case 'Cancelled':
        return 'var(--danger-color)';
      case 'Confirmed':
        return 'var(--success-color)';
      case 'Awaiting Approval':
        return 'var(--warn-color)';
      case 'Processing':
        return 'var(--neutral-color)';
      case 'renewal':
        return 'var(--theme-neutral-bg)';
      default:
        return 'var(--theme-unknown-bg)';
    }
  }

  saveTableConfig(event: any): void {
    const tableConfig: TableConfigModel = {
      table: TableConfigEnum.BookingsPendingTable,
      columnOrder: JSON.stringify(this.selectedBookings),
      columns: JSON.stringify(this.selectedBookings)
    };

    this.commonService.saveTableConfigFilter(tableConfig).subscribe({
      next: (response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Table configuration saved successfully!',
          life: 3000,
        });
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to save table configuration. Please try again.',
          life: 3000,
        });
      }
    });
    this.handleButtonClick()
    this.closePanel()
  }

  getTableConfig() {
    const tableConfig: TableConfigModel = {
      table: TableConfigEnum.BookingsPendingTable,
      columnOrder: JSON.stringify(this.selectedBookings),
      columns: JSON.stringify(this.selectedBookings),
    };

    this.commonService.getUserTableConfig(tableConfig).subscribe({
      next: (response) => {
        if (response?.columns && response?.columnOrder) {
          try {
            const parsedColumns = JSON.parse(response.columns) as Column[];
            const parsedColumnOrder = JSON.parse(response.columnOrder) as Array<{ field: string }>;
            if (parsedColumns.length && parsedColumnOrder.length) {
              const orderedFields = parsedColumnOrder.map((order) => order.field);
              const orderedColumns = orderedFields
                .map((field) => {
                  const column = parsedColumns.find((col) => col.field === field);
                  if (!column) {
                    console.warn(`Field "${field}" not found in parsedColumns.`);
                  }
                  return column;
                })
                .filter((col): col is Column => col !== undefined);

              if (orderedColumns.length) {
                this.cols = [...orderedColumns];
                this.selectedBookings = [...this.cols];
                this.bookingColumns = [
                  { name: 'customerName', field: 'customerName', header: 'Customer', type: 'string', visible: !this.isSingleCustUser },
                  { name: 'appBookingCode', field: 'appBookingCode', header: 'Booking Code', type: 'string', visible: true },
                  { name: 'lineCreatedDate', field: 'lineCreatedDate', header: 'Date Created', type: 'date', visible: true },
                  { name: 'appBookingStatus', field: 'appBookingStatus', header: 'Booking Status', type: 'string', visible: true },
                  { name: 'reason', field: 'reason', header: 'Rejection Reason', type: 'string', visible: true },
                  { name: 'loadportName', field: 'loadportName', header: 'Load Port', type: 'string', visible: true },
                  { name: 'carrierName', field: 'carrierName', header: 'Carrier', type: 'string', visible: true },
                  { name: 'sailDateETD', field: 'sailDateETD', header: 'Load ETD', type: 'date', visible: true },
                  { name: 'vesselName', field: 'vesselName', header: 'Vessel Name', type: 'string', visible: true },
                  { name: 'quantity', field: 'quantity', header: 'Total Containters', type: 'number', visible: true },
                ];
                this.exportColumns = this.cols.map((col) => ({
                  title: col.header,
                  dataKey: col.field,
                }));
                return;
              }
            }
          } catch (error) {
            console.error('Error parsing columns or columnOrder:', error);
          }
        }
        this.useDefaultColumns('Invalid or empty configuration. Using default columns.');
      },
      error: (error) => {
        this.useDefaultColumns('Failed to load table configuration. Using default columns.');
      },
    });
  }

  useDefaultColumns(message: string) {
    console.warn(message);
    const defaultColumns = [
      { name: 'customerName', field: 'customerName', header: 'Customer', type: 'string', visible: !this.isSingleCustUser },
      { name: 'appBookingCode', field: 'appBookingCode', header: 'Booking Code', type: 'string', visible: true },
      { name: 'lineCreatedDate', field: 'lineCreatedDate', header: 'Date Created', type: 'date', visible: true },
      { name: 'appBookingStatus', field: 'appBookingStatus', header: 'Booking Status', type: 'string', visible: true },
      { name: 'reason', field: 'reason', header: 'Rejection Reason', type: 'string', visible: true },
      { name: 'loadportName', field: 'loadportName', header: 'Load Port', type: 'string', visible: true },
      { name: 'carrierName', field: 'carrierName', header: 'Carrier', type: 'string', visible: true },
      { name: 'sailDateETD', field: 'sailDateETD', header: 'Load ETD', type: 'date', visible: true },
      { name: 'vesselName', field: 'vesselName', header: 'Vessel Name', type: 'string', visible: true },
      { name: 'quantity', field: 'quantity', header: 'Total Containters', type: 'number', visible: true },
    ];
    this.cols = [...defaultColumns];
    this.selectedBookings = [...this.cols];
    this.bookingColumns = [...defaultColumns];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  updateDisplayedColumns() {
    this.cols = [...this.selectedBookings];
  }

  handleButtonClick(): void {
    if (this.isMobile()) {
      this.openPanel()
    } else {
      this.togglePanel();
    }
  }

  togglePanel() {
    this.panelCollapsed = !this.panelCollapsed;
  }

  isMobile(): boolean {
    return window.innerWidth <= 490;
  }

  isTablet(): boolean {
    return window.innerWidth <= 768;
  }

  clear(table: Table) {
    table.clear();
    this.searchValue = ''
  }

  mobileConfig() {
    if (this.isMobile()) {
      this.openPanel()
    } else {
      this.togglePanel();
    }
  }

  openPanel(): void {
    this.panelService.openPanel('Select Table Columns', this.customContent);
  }

  closePanel(): void {
    this.panelService.closePanel()
  }

  updateCurrentRow(index: number): void {
    this.currentRowIndex = index + 1;
  }

  onPageChange(event: any): void {
    this.currentRowIndex = event.first;
  }

  updateCurrentIndex(container: Element, rows: NodeListOf<Element>): void {
    let closestIndex = 0;
    let closestDistance = Number.MAX_VALUE;
    rows.forEach((row, index) => {
      const rect = row.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      const distance = Math.abs(rect.top - containerRect.top);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestIndex = index;
      }
    });
    this.currentRowIndex = closestIndex;
  }

  onScroll(event: Event): void {
    const tableWrapper = event.target as HTMLElement;
    const rows = Array.from(tableWrapper.querySelectorAll('.snap-row')) as HTMLElement[];
    let currentRowIndex = 0;
    let closestDistance = Number.MAX_VALUE;
    rows.forEach((row, index) => {
      const rect = row.getBoundingClientRect();
      const distance = Math.abs(rect.top - tableWrapper.getBoundingClientRect().top);
      if (distance < closestDistance) {
        closestDistance = distance;
        currentRowIndex = index;
      }
    });
  }
}
