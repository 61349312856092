import { Component, Input, OnInit } from '@angular/core';
import { detailTabbedViewEnum } from '../models/detail-view-enum';
import { CommunicationService } from '../service/communication/communication.service';
import { AlertService } from '../service/alerts/alert.service';
import { Alert } from '../models/alerts/alerts';
import { ShipmentModel, ShipmentTranferGRRefContainerNo } from '../models/shipment/shipment-data-model';
import { ShipmentService } from '../service/shipment/shipment.service';
import { ChangeDetectorRef } from '@angular/core'; // Add this import
import { DocumentStatus } from '../enums/document-status.enum';
import { TransportMode } from '../enums/transport-mode.enum';
import { ShipmentTrackingHeader, ShipmentTrackingVessel } from '../models/shipment/shipment-tracking-header-model';
import { OceanTrackingViewEnum } from '../enums/ocean-tracking-view-enum';
import { LegendSetup } from '../models/legend';
import { Observable, Subject, catchError, map, of, skip, takeUntil, tap } from 'rxjs';
import { ColumnSetup } from '../models/column-setup';
import { RouteEvents, RouteInformation } from '../models/shipment/route-information-model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-shipment-events',
  templateUrl: './shipment-events.component.html',
  styleUrl: './shipment-events.component.css'
})
export class ShipmentEventsComponent {
  errorMessage: string = '';
  isLoading: boolean = false;
  hasTrackingEvents: boolean = true;
  public containerTrackingHeader: ShipmentTrackingHeader = new ShipmentTrackingHeader;
  public routeHeaderDataSource: RouteInformation = new RouteInformation;
  //protected displayedColumns: ColumnSetup[] = [];
  protected extractedColumns: string[] = [];

  @Input() canLoad: boolean = false;
  @Input() events: RouteEvents[] = [];

  displayedColumns = [
    ['state', '', ''],
    /*['portType', '', ''],*/
    ['location', 'Port Name', ''],
    ['description', 'Description', ''],
    ['date', 'Date', 'Estimated time of arrival.'],
  ];

  constructor(
    private shipmentDataService: ShipmentService) { }

  ngOnInit(): void {
    if (this.canLoad) {
      console.log(this.canLoad)
      this.updateData();
    }
  }


  updateData(): void {
    this.hasTrackingEvents = this.events.length > 0;
  }

  getValueForRowColumn(row: any, columnName: string): any {
    return row[columnName];
  }

  isMinWidth(minWidth: number): boolean {
    return window.innerWidth >= minWidth;
  }

}
