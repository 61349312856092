<div class="container">
  <div class="header-title-wrapper">
    <h1>Document Management</h1>
    <div class="save-wrapper">
      <ng-container *ngIf="selectedTab == documentTabbedViewEnum.approved">
        <div class="saved-views">
          <div *ngFor="let savedFilter of savedFilters">
            <button mat-button class="Saved-filter-btn" [ngClass]="{'selected': datafilter.id === savedFilter.id}"
                    (click)="applyFilter(savedFilter.id)">
              {{ savedFilter.filterName }}
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedTab == documentTabbedViewEnum.pending">
        <div class="saved-views">
          <div *ngFor="let savedFilter of savedFiltersNotApproved">
            <button mat-button class="Saved-filter-btn"
                    [ngClass]="{'selected': datafilterNotApproved.id === savedFilter.id}"
                    (click)="applyFilterNotApproved(savedFilter.id)">
              {{ savedFilter.filterName }}
            </button>
          </div>
        </div>
      </ng-container>
      <div class="action-buttons-wrapper" *ngIf="selectedTab === documentTabbedViewEnum.approved">
        <div class="action-buttons">
          <button mat-button class=" filter-btn" (click)="onOpenFilter(FilterEnum.documentFilter)"
                  matTooltip="Save your current filters." matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
            <div class="filter-icon-wrap">
              <div class="filter-icon"></div>
              Save Filters
            </div>
          </button>
          <button mat-button class="filter-btn" (click)="clearCurrentFilter()" matTooltip="Clear data in input fields."
                  matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
            <div class="filter-icon-wrap">
              <div class="filter-clear-icon"></div>
              Reset Filters
            </div>
          </button>
          <button mat-button class="filter-btn-delete" (click)="deleteFilter()" *ngIf="datafilter.id !== 0">
            <div class="filter-icon-wrap">
              <div class="filter-delete-icon"></div>
              Delete Saved View
            </div>
          </button>
        </div>
      </div>
      <div class="action-buttons-wrapper" *ngIf="selectedTab === documentTabbedViewEnum.pending">
        <div class="action-buttons">
          <button mat-button class=" filter-btn" (click)="onOpenFilter(FilterEnum.pendingDocumentsFilter)"
                  matTooltip="Save your current filters." matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
            <div class="filter-icon-wrap">
              <div class="filter-icon"></div>
              Save Filters
            </div>
          </button>
          <button mat-button class="filter-btn" (click)="clearCurrentFilterNotApproved()"
                  matTooltip="Clear data in input fields." matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
            <div class="filter-icon-wrap">
              <div class="filter-clear-icon"></div>
              Reset Filters
            </div>
          </button>
          <button mat-button class="filter-btn-delete" (click)="deleteFilterNotApproved()"
                  *ngIf="datafilterNotApproved.id !== 0">
            <div class="filter-icon-wrap">
              <div class="filter-delete-icon"></div>
              Delete Saved View
            </div>
          </button>
        </div>
      </div>

    </div>

  </div>

  <div class="filter-btn-wrapper" *ngIf="!isConsigneeUser">
    <div class="col-md-4 booking-tabs">
      <mat-button-toggle-group name="" aria-label="Favorite Color" [hideSingleSelectionIndicator]="true"
                               class="tab-menu" (change)="onTabChange($event)" [(ngModel)]="selectedTab">
        <mat-button-toggle *ngIf="authService.hasClaim(ClaimAction.Root2Go_EditDocuments) && !isConsigneeUser" class="tab-menu-item" matTooltip="View documents with status awaiting approval or rejected."
                           matTooltipPosition="above" matTooltipClass="custom-mat-tooltip" [value]="documentTabbedViewEnum.pending">
          Pending
          Documents
        </mat-button-toggle>
        <mat-button-toggle class="tab-menu-item" matTooltip="View all shipments with approved documents."
                           matTooltipPosition="above" matTooltipClass="custom-mat-tooltip"
                           [value]="documentTabbedViewEnum.approved">{{ isConsigneeUser ? 'Documents' : 'Approved Documents' }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="row" *ngIf="selectedTab == documentTabbedViewEnum.approved">
    <div class="col-md-2-bookings">
      <mat-form-field>
        <mat-label>Enter an ETA date range</mat-label>
        <mat-date-range-input [rangePicker]="picker" matTooltip="Pick a date from the calendar."
                              matTooltipPosition="above" matTooltipClass="custom-mat-tooltip" required>
          <input matStartDate placeholder="ETA from" [formControl]="dateFrom">
          <input matEndDate placeholder="ETA to" [formControl]="dateTo">
        </mat-date-range-input>

        <!-- Clear button for the Start Date -->
        <ng-container *ngIf="dateFrom.value||dateFrom.value && dateTo.value">
          <button class="clear-search" (click)="clearSearchField(dateFrom,dateTo)">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </ng-container>

        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="dateFrom.invalid && dateFrom.touched">Start date is required.</mat-error>
        <mat-error *ngIf="dateTo.invalid && dateTo.touched">End date is required.</mat-error>
      </mat-form-field>
    </div>

    <ng-container *ngIf="!isSingleCustUser">
      <div class="col-md-2-bookings">
        <mat-form-field *ngIf="customers.value">
          <mat-label>{{ isConsigneeUser ? 'Shipper': 'Customer' }}</mat-label>
          <mat-select [formControl]="customers" multiple #multiSelect>
            <mat-option>
              <ngx-mat-select-search [formControl]="customersMultiFilter" placeholderLabel="Search"
                                     noEntriesFoundLabel="Customer not found"></ngx-mat-select-search>
            </mat-option>
            <mat-select-trigger>
              {{customers.value.length > 0 ? customers.value[0].code : ''}}
              <span *ngIf="customers.value[0]  && customers.value.length > 1" class="example-additional-selection">
                (+{{(customers.value.length || 0) - 1}} {{customers.value.length === 2 ? 'other' : 'others'}})
              </span>
              <button class="clear-filter" (click)="clearLookupFilter(customers)">
                <mat-icon svgIcon="backspace"></mat-icon>
              </button>
            </mat-select-trigger>
            <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
              {{customer.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </ng-container>

    <ng-container *ngIf="isConsigneeUser && !isSingleConsignee">
      <div class="col-md-2-bookings">
        <mat-form-field *ngIf="consignees.value">
          <mat-label>All Consignees</mat-label>
          <mat-select [formControl]="consignees" multiple>
            <mat-option>
              <ngx-mat-select-search [formControl]="consigneesMultiFilter" placeholderLabel="Search"
                                     noEntriesFoundLabel="Consignee Not Found"></ngx-mat-select-search>
            </mat-option>
            <mat-select-trigger>
              {{consignees.value.length > 0 ? consignees.value[0].code : ''}}
              <span *ngIf="consignees.value[0]  && consignees.value.length > 1" class="example-additional-selection">
                (+{{(consignees.value.length || 0) - 1}} {{consignees.value.length === 2 ? 'other' : 'others'}})
              </span>
              <button class="clear-filter" (click)="clearLookupFilter(consignees)">
                <mat-icon svgIcon="backspace"></mat-icon>
              </button>
            </mat-select-trigger>
            <mat-option *ngFor="let consignee of filteredConsignees | async" [value]="consignee">
              {{consignee.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

      <div class="col-md-2-bookings">
        <mat-form-field>
          <mat-label>GR Ref No.</mat-label>
          <input matInput [formControl]="grRefNo">
          <button matSuffix *ngIf="grRefNo.value" mat-icon-button (click)="resetTextControl(grRefNo)"
                  aria-label="Clear selection" class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="col-md-2-bookings">
        <mat-form-field>
          <mat-label> {{ isConsigneeUser ? 'Shipper Ref.' : 'Client Ref.' }} </mat-label>
          <input matInput [formControl]="clientRefNo">
          <button matSuffix *ngIf="clientRefNo.value" mat-icon-button (click)="resetTextControl(clientRefNo)"
                  aria-label="Clear selection" class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-form-field>
      </div>


      <div class="col-md-2-bookings">
        <mat-form-field>
          <mat-label>Booking Ref.</mat-label>
          <input matInput [formControl]="bookingRefNo">
          <button matSuffix *ngIf="bookingRefNo.value" mat-icon-button (click)="resetTextControl(bookingRefNo)"
                  aria-label="Clear selection" class="clear-filter-icon">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="col-md-1">

        <button class="runReportButton" (click)="runReportClick()" matTooltip="Search for shipments."
                matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
          Search<mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
  </div>
</div>

<div class="row" *ngIf="selectedTab == documentTabbedViewEnum.pending">
  <div class="col-md-2-bookings">
    <mat-form-field>
      <mat-label>Enter an ETA date range</mat-label>
      <mat-date-range-input [rangePicker]="picker" matTooltip="Pick a date from the calendar."
                            matTooltipPosition="above" matTooltipClass="custom-mat-tooltip" required>
        <input matStartDate placeholder="ETA from" [formControl]="dateFromNotApproved">
        <input matEndDate placeholder="ETA to" [formControl]="dateToNotApproved">
      </mat-date-range-input>

      <!-- Clear button for the Start Date -->
      <ng-container *ngIf="dateFromNotApproved.value||dateFromNotApproved.value && dateToNotApproved.value">
        <button class="clear-search" (click)="clearSearchFieldNotApproved(dateFromNotApproved,dateToNotApproved)">
          <mat-icon svgIcon="backspace"></mat-icon>
        </button>
      </ng-container>

      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error *ngIf="dateFrom.invalid && dateFrom.touched">Start date is required.</mat-error>
      <mat-error *ngIf="dateTo.invalid && dateTo.touched">End date is required.</mat-error>
    </mat-form-field>

  </div>

  <ng-container *ngIf="!isSingleCustUser">
    <div class="col-md-2-bookings">
      <mat-form-field *ngIf="customersNotApproved.value">
        <mat-label>Customer</mat-label>
        <mat-select [formControl]="customersNotApproved" multiple #multiSelect>
          <mat-option>
            <ngx-mat-select-search [formControl]="customersMultiFilterNotApproved" placeholderLabel="Search"
                                   noEntriesFoundLabel="Customer not found"></ngx-mat-select-search>
          </mat-option>
          <mat-select-trigger>
            {{customersNotApproved.value.length > 0 ? customersNotApproved.value[0].code : ''}}
            <span *ngIf="customersNotApproved.value[0]  && customersNotApproved.value.length > 1"
                  class="example-additional-selection">
              (+{{(customersNotApproved.value.length || 0) - 1}} {{
              customersNotApproved.value.length === 2 ? 'other'
              : 'others'
              }})
            </span>
            <button class="clear-filter" (click)="clearLookupFilter(customersNotApproved)">
              <mat-icon svgIcon="backspace"></mat-icon>
            </button>
          </mat-select-trigger>
          <mat-option *ngFor="let customer of filteredCustomersNotApproved | async" [value]="customer">
            {{customer.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <div class="col-md-2-bookings">
    <mat-form-field>
      <mat-label>GR Ref No.</mat-label>
      <input matInput [formControl]="grRefNoNotApproved">
      <button matSuffix *ngIf="grRefNoNotApproved.value" mat-icon-button (click)="resetTextControl(grRefNoNotApproved)"
              aria-label="Clear selection" class="clear-filter-icon">
        <mat-icon svgIcon="backspace"></mat-icon>
      </button>
    </mat-form-field>
  </div>


  <div class="col-md-2-bookings">
    <mat-form-field>
      <mat-label>Client Ref.</mat-label>
      <input matInput [formControl]="clientRefNoNotApproved">
      <button matSuffix *ngIf="clientRefNoNotApproved.value" mat-icon-button
              (click)="resetTextControl(clientRefNoNotApproved)" aria-label="Clear selection" class="clear-filter-icon">
        <mat-icon svgIcon="backspace"></mat-icon>
      </button>
    </mat-form-field>
  </div>


  <div class="col-md-2-bookings">
    <mat-form-field *ngIf="consigneesNotApproved.value">
      <mat-label>All Consignees</mat-label>
      <mat-select [formControl]="consigneesNotApproved" multiple>
        <mat-option>
          <ngx-mat-select-search [formControl]="consigneesMultiFilterNotApproved" placeholderLabel="Search"
                                 noEntriesFoundLabel="Consignee Not Found"></ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger>
          {{consigneesNotApproved.value.length > 0 ? consigneesNotApproved.value[0].code : ''}}
          <span *ngIf="consigneesNotApproved.value[0]  && consigneesNotApproved.value.length > 1"
                class="example-additional-selection">
            (+{{(consigneesNotApproved.value.length || 0) - 1}} {{
            consigneesNotApproved.value.length === 2 ? 'other'
            : 'others'
            }})
          </span>
          <button class="clear-filter" (click)="clearLookupFilter(consigneesNotApproved)">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-select-trigger>
        <mat-option *ngFor="let consignee of filteredConsigneesNotApproved | async" [value]="consignee">
          {{consignee.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>


  <div class="col-md-2-bookings">
    <mat-form-field *ngIf="documentTypeNotApproved.value">
      <mat-label>Document Type</mat-label>
      <mat-select [formControl]="documentTypeNotApproved" multiple #multiSelect>
        <mat-option>
          <ngx-mat-select-search [formControl]="documentTypesMultiFilter" placeholderLabel="Search"
                                 noEntriesFoundLabel="Document Type not found"></ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger>
          {{documentTypeNotApproved.value.length > 0 ? documentTypeNotApproved.value[0].name : ''}}
          <span *ngIf="documentTypeNotApproved.value[0]  && documentTypeNotApproved.value.length > 1"
                class="example-additional-selection">
            (+{{(documentTypeNotApproved.value.length || 0) - 1}} {{
            documentTypeNotApproved.value.length === 2 ?
            'other' : 'others'
            }})
          </span>
          <button class="clear-filter" (click)="clearLookupFilter(documentTypeNotApproved)">
            <mat-icon svgIcon="backspace"></mat-icon>
          </button>
        </mat-select-trigger>
        <mat-option *ngFor="let documentTypes of filteredDocumentTypes | async" [value]="documentTypes">
          {{documentTypes.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>



  <div class="col-md-2-bookings">

    <button class="runReportButton" (click)="runReportClickNotApproved()" matTooltip="Search for shipments."
            matTooltipPosition="above" matTooltipClass="custom-mat-tooltip">
      Search<mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</div>

<div class="parameters-filter-wrap">
  <div class="legend-wrapper">
    <button *ngIf="selectedTab == documentTabbedViewEnum.pending" mat-button class="alert-btns"
            [ngClass]="{'selected': currentFilterStatus === 'Awaiting Approval'}"
            (click)="filterByApprovalStatus('Awaiting Approval')">
      <img src="/assets/Awaiting.svg" alt="" class="icon">
      Awaiting approval
    </button>
    <button *ngIf="selectedTab == documentTabbedViewEnum.pending" mat-button class="alert-btns"
            [ngClass]="{'selected': currentFilterStatus === 'rejected'}" (click)="filterByApprovalStatus('rejected')">
      <img src="/assets/Cancelled.svg" alt="" class="icon">
      Rejected
    </button>
  </div>
</div>


<app-approved-documents *ngIf="selectedTab == documentTabbedViewEnum.approved"></app-approved-documents>
<app-pending-documents *ngIf="selectedTab == documentTabbedViewEnum.pending"></app-pending-documents>

