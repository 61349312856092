<mat-toolbar class="header">
  <div class="toolbar-content" *ngIf="(isDesktop$ | async)">
    <div class="logo-wrapper">
      <div class="root2go-logo">
        <img src="assets/Root2GoLogo.svg" alt="Root2Go Logo" (click)="refreshPage()">
      </div>
      <div class="welcome-banner">
        <div class="welcome-message">
          Welcome back, {{ userInfoModel.userFullName }} | {{ userInfoModel.userOrganisation }}
        </div>
      </div>
    </div>
    <div class="headericons">
      <app-header-customer-filter></app-header-customer-filter>
      <app-header-icons (openAlerts)="onOpenAlerts()"></app-header-icons>
    </div>
  </div>
    <div class="toolbar-content" *ngIf="(isHandset$ | async) || (isTablet$ | async)" >
      <div class="menu-opener" (click)="onMenuOpenerClick()" >
        
      </div>
      <div class="headericons">
        <app-header-customer-filter></app-header-customer-filter>
        <app-header-icons (openAlerts)="onOpenAlerts()"></app-header-icons>
      </div>
    </div>
  </mat-toolbar>
