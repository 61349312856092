export enum TableConfigEnum {
  none,
  SchedulesTable = 1,
  BookingsApprovedTable = 2,
  BookingsPendingTable = 3,
  ShipmentsOceanTable = 4,
  ShipmentsRollingPlanTable = 5,
  DocumentsApprovedTable = 6,
  DocumentsPendingTable = 7,
  AlertsTable = 8,
}
