export interface IUserInfoModel {
  userId: string;
  userFullName: string;
  userOrganisation: string;
  userEmail: string;
  userPhone: string;
  accountUrl: string;
  logoutUrl: string;
  isSingleCustomer: boolean;
  isSingleConsignee: boolean;
  isConsigneeUser: boolean;
  userBookingTemplateLimit: number;
}

export class UserInfoModel implements IUserInfoModel {
  userId: string = '';
  userFullName: string = '';
  userOrganisation: string = '';
  userEmail: string = '';
  userPhone: string = '';
  accountUrl: string = '';
  logoutUrl: string = '';
  isSingleCustomer: boolean = false;
  isSingleConsignee: boolean = false;
  isConsigneeUser: boolean = false;
  userBookingTemplateLimit: number = 0;
}
